import { useEffect, useState } from 'react';

export const useThemeDetector = () => {
	const getCurrentTheme = () => window.matchMedia('(prefers-color-scheme: dark)').matches;
	const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());
	const mediaQueryListener = e => {
		setIsDarkTheme(e.matches);
	};

	useEffect(() => {
		const darkThemeMedia = window.matchMedia('(prefers-color-scheme: dark)');
		darkThemeMedia.addEventListener('change', mediaQueryListener);
		return () => darkThemeMedia.removeEventListener('change', mediaQueryListener);
	}, []);
	return isDarkTheme;
};
