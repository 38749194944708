import { ReactNode, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import {
	Space,
	DatePicker,
	Input,
	Card,
	Button,
	message,
	Form,
	Select,
	Row,
	Col,
	Popconfirm,
	Tooltip,
	Table,
	Alert,
	AutoComplete,
	Layout,
	Breadcrumb,
} from 'antd';
import { AuthRoutes, DocumentViewer, EditableCell, SizedModal } from '../../components';
import {
	Task,
	User,
	initialTaskValue,
	TaskStatus,
	TaskType,
	DocumentAttachment,
	ParentType,
	initialDocumentAttachmentValue,
	initialCommentValue,
	Comment,
} from '../../store/types';
import { taskStatusesSelectors, taskTypesSelectors } from '../../store/selectors/domains';
import { commentsSelectors, tasksSelectors, usersSelectors } from '../../store/selectors';
import { tasks as tasksReducer, activities as activitiesReducer, comments as commentsReducer } from '../../store/reducers';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	ApartmentOutlined,
	DeleteOutlined,
	EditOutlined,
	InboxOutlined,
	PlusOutlined,
	RollbackOutlined,
	SaveOutlined,
	ArrowLeftOutlined,
	ZoomInOutlined,
} from '@ant-design/icons';
import { dateFormat } from '../../assets/formats';
import { baseStyles, colors } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import {
	createUpdateDocumentAttachment,
	createUpdateTask,
	createUpdateTaskComment,
	deleteTaskComment,
	fetchActivity,
	fetchTaskComments,
	fetchTaskDocuments,
} from '../../store/api';
import { RcFile } from 'antd/lib/upload';
import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger';
import locale from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';

const { Option } = Select;
const { Content } = Layout;

const key = 'saving';

const PageTaskDetails = () => {
	const { t } = useTranslation();
	const store = useStore();
	const location = useLocation();
	const params: any = location.state;
	const styles = baseStyles;
	var id: number = parseInt(params.id);
	var activityId: number = parseInt(params.activityId) || 0;
	var caseId: number = parseInt(params.caseId) || 0;
	const navigate = useNavigate();
	const [formTaskDetails] = Form.useForm();
	const [formAttachment] = Form.useForm();
	const [formComment] = Form.useForm();
	const [title, setTitle] = useState<string>('');
	const [changed, setChanged] = useState<boolean>(false);
	const [task, setTask] = useState<Task>(initialTaskValue);
	const [taskStatuses, setTaskStatuses] = useState<TaskStatus[]>([]);
	const [taskTypes, setTaskTypes] = useState<TaskType[]>([]);
	const [user, setUser] = useState<User>();
	const [documentAttachment, setDocumentAttachment] = useState<DocumentAttachment>(initialDocumentAttachmentValue);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [isViewerVisible, setIsViewerVisible] = useState<boolean>(false);
	const [comments, setComments] = useState<Comment[]>([]);
	const [url, setUrl] = useState<string>('');
	const [localUrl, setLocalUrl] = useState<string>('');
	const [isPDF, setIsPDF] = useState<boolean>(true);
	const [users, setUsers] = useState<User[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
	const [editingCommentKey, setEditingCommentKey] = useState<number>(0);
	const [isValidData, setIsValidData] = useState<boolean>(true);
	const [attachments, setAttachments] = useState<DocumentAttachment[]>([]);
	const [searchText, setSearchText] = useState<string>('');
	const [options, setOptions] = useState([]);
	const [span, setSpan] = useState<number>(24);
	const [file, setFile] = useState<RcFile>();
	const [isloadingUsers, setIsLoadingUsers] = useState(true);
	const [isloadingDocuments, setIsLoadingDocuments] = useState(true);
	const [isloadingComments, setIsLoadingComments] = useState(true);

	window.onresize = () => {
		window.innerWidth > 1600 ? setSpan(12) : setSpan(24);
	};

	useEffect(() => {
		window.innerWidth > 1600 ? setSpan(12) : setSpan(24);
		setDocumentAttachment({ ...initialDocumentAttachmentValue, parentId: id, parentType: ParentType.task });
		setTaskStatuses(taskStatusesSelectors.selectAll(store.getState() as any));
		setTaskTypes(taskTypesSelectors.selectAll(store.getState() as any));
		var item: Task = tasksSelectors.selectById(store.getState() as any, id) || initialTaskValue;
		setTask(item);
		var userItems: User[] = usersSelectors.selectAll(store.getState() as any).filter(u => !u.isDeleted);
		setUser(usersSelectors.selectById(store.getState() as any, globalThis.user.id));
		setUsers(userItems);
		setSelectedUsers(userItems.filter(u => item.participantIds.includes(u.id)));
		setIsLoadingUsers(false);
		setTitle(item.description || t('management.newTask'));
		formTaskDetails.setFieldsValue({
			description: item.description || t('management.newTask'),
			creationDate: dayjs(item.creationDate || Date.now()),
			requester: usersSelectors.selectById(store.getState() as any, item.taskOwnerId === 0 ? globalThis.user.id : item.taskOwnerId)?.username,
			taskType: item.taskTypeId || 1,
			taskStatus: item.taskStatusId || 1,
		});
		if (item.expirationDate)
			formTaskDetails.setFieldsValue({
				expirationDate: dayjs(item.completionDate),
			});
		if (item.completionDate)
			formTaskDetails.setFieldsValue({
				completionDate: dayjs(item.completionDate),
			});
		formAttachment.setFieldsValue({
			description: t('management.attachments'),
		});
		fetchTaskComments(id).then(items => {
			setComments(items.filter(c => !c.isDeleted));
			setIsLoadingComments(false);
		});
		fetchTaskDocuments(id).then(items => {
			setAttachments(items.filter(a => !a.isDeleted));
			setIsLoadingDocuments(false);
		});
	}, [formTaskDetails, formAttachment, id, store, t]);

	const handleAddAttachment = () => {
		setTitle(t('management.addAttachment'));
		setIsViewerVisible(false);
		setIsModalVisible(true);
	};

	const handleEditAttachment = item => {
		setDocumentAttachment(item);
		setUrl(item.url);
		setIsViewerVisible(true);
		setTitle(t('management.editAttachment'));
		setIsModalVisible(true);
	};

	const getUploadedFile = (e: { filelist: RcFile[] }) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.filelist;
	};

	const handleSearchUser = (searchText: string) => {
		setSearchText(searchText);
		if (searchText.length >= 3) {
			var seleted = users
				.filter(u => u.username.includes(searchText))
				.map(u => {
					return { value: u.username };
				});
			setOptions(!searchText ? [] : seleted);
		}
	};

	const isEditingComment = (item: Comment) => item.id === editingCommentKey;

	const handleEditComment = (item: Comment) => {
		formComment.setFieldsValue({
			...item,
		});
		setEditingCommentKey(item.id);
	};

	const handleAddComment = () => {
		const newData: Comment = initialCommentValue;
		setComments([...comments, newData]);
	};

	const handleCommentSave = async (item: Comment) => {
		setIsLoadingComments(true);
		try {
			const values = await formComment.validateFields();
			if (values.errorField === undefined) {
				var temp: Comment = {
					...item,
					id: item.id,
					activityId: 0,
					caseId: 0,
					taskId: id,
					description: values['description'],
				};
				message.loading({ content: t('messages.saving'), key, duration: 0 });
				var response = await createUpdateTaskComment(temp, id);
				if (response !== undefined) {
					store.dispatch(commentsReducer.actions.commmentsCreateUpdate(response));
					setComments(commentsSelectors.selectAll(store.getState() as any).filter(c => c.taskId === id && !c.isDeleted));
					message.success({ content: t('messages.dataSaved'), key, duration: 2 });
				} else message.info({ content: t('messages.errorOnSave'), key, duration: 2 });
				setEditingCommentKey(0);
			}
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
			setEditingCommentKey(0);
		}
		setIsLoadingComments(false);
	};

	const handleDeleteComment = async (item: Comment) => {
		setIsLoadingComments(true);
		try {
			var ok = await deleteTaskComment(item);
			if (ok) {
				var temp = { ...item, isDeleted: true };
				store.dispatch(commentsReducer.actions.commmentsCreateUpdate(temp));
				setComments(commentsSelectors.selectAll(store.getState() as any).filter(c => c.activityId === id && !c.isDeleted));
			} else message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		}
		setIsLoadingComments(false);
	};

	const handleCommentCancel = () => {
		setEditingCommentKey(0);
	};

	const handleSelectUser = (seletedData: string) => {
		setSearchText('');
		setChanged(true);
		var user = users.find(u => u.username === seletedData);
		if (user && !task.participantIds.includes(user.id)) {
			setChanged(true);
			setSelectedUsers([...selectedUsers, user]);
		}
		setOptions([]);
	};

	const handleDeleteUser = (item: User) => {
		setChanged(true);
		setSelectedUsers(selectedUsers.filter(u => u.id !== item.id));
	};

	const handleAttachmentSave = async () => {
		setIsModalVisible(false);
		const values = await formAttachment.validateFields();
		if (values.errorField === undefined) {
			var temp: DocumentAttachment = {
				...documentAttachment,
				description: values['description'],
			};
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			var response = await createUpdateDocumentAttachment(temp, file);
			if (response !== undefined) {
				setIsLoadingDocuments(true);
				fetchTaskDocuments(id).then(items => {
					setAttachments(items.filter(a => !a.isDeleted));
					setIsLoadingDocuments(false);
				});
				message.success({ content: t('messages.dataSaved'), key, duration: 2 });
			} else message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
			setUrl('');
			setIsValidData(true);
			setIsPDF(true);
		}
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		setUrl('');
		setLocalUrl('');
	};

	const handleTaskSave = async () => {
		setIsModalVisible(false);
		try {
			const values = await formTaskDetails.validateFields();
			if (values.errorField === undefined) {
				message.loading({ content: t('messages.saving'), key, duration: 0 });
				var temp: Task = {
					...task,
					description: values['description'],
					taskStatusId: values['taskStatus'],
					taskTypeId: values['taskType'],
					completionDate: values['completionDate'],
					expirationDate: values['expirationDate'],
					taskOwnerId: user.id,
					participantIds: selectedUsers.map(u => {
						return u.id;
					}),
				};
				var response = await createUpdateTask(temp, activityId, caseId);
				if (response !== undefined) {
					if (activityId !== 0) {
						var activity = await fetchActivity(activityId);
						store.dispatch(activitiesReducer.actions.activitiesCreateUpdate(activity));
					}
					store.dispatch(tasksReducer.actions.tasksCreateUpdate(response));
					setTask(tasksSelectors.selectById(store.getState() as any, id) || initialTaskValue);
					message.success({ content: t('messages.dataSaved'), key, duration: 2 });
					setChanged(false);
				} else message.info({ content: t('messages.errorOnSave'), key, duration: 2 });
			}
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		}
	};

	const handleValuesChange = (changedValues: any) => {
		setChanged(true);
		if (changedValues['description']) setTitle(changedValues['description']);
		if (changedValues['taskStatus']) {
			var taskStatus = taskStatuses.find(ts => ts.id === changedValues['taskStatus']);
			if (taskStatus.code === 'FIN') formTaskDetails.setFieldValue('completionDate', dayjs(Date.now()));
			else formTaskDetails.setFieldValue('completionDate', '');
		}
	};

	const columnsUser: ColumnProps<User>[] = [
		{
			title: t('management.description'),
			dataIndex: 'username',
			key: 'username',
			width: '50%',
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: User): ReactNode => {
				return (
					<Space size="middle">
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteUser(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const columnsComment = [
		{
			title: t('management.description'),
			dataIndex: 'description',
			key: 'description',
			editable: true,
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: Comment): ReactNode => {
				const editable = isEditingComment(item);
				return editable ? (
					<Space>
						<Button type="primary" shape="round" onClick={() => handleCommentSave(item)} icon={<SaveOutlined />} />
						<Popconfirm title={t('messages.confirmCancel')} onConfirm={handleCommentCancel} okText={t('general.ok')} cancelText={t('general.cancel')}>
							<Button type="primary" shape="round" icon={<RollbackOutlined />} />
						</Popconfirm>
					</Space>
				) : (
					<Space size="middle">
						<Tooltip title={t('general.edit')}>
							<Button
								type="primary"
								shape="round"
								onClick={() => {
									handleEditComment(item);
								}}
								icon={<EditOutlined />}
							/>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteComment(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const columnsDocumentAttachment: ColumnProps<DocumentAttachment>[] = [
		{
			title: t('management.description'),
			dataIndex: 'description',
			key: 'description',
			width: '50%',
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: DocumentAttachment): ReactNode => {
				return (
					<Space size="middle">
						<Tooltip title={t('general.edit')}>
							<Button
								style={styles.button}
								type="primary"
								shape="round"
								onClick={() => {
									handleEditAttachment(item);
								}}
							>
								<EditOutlined />
							</Button>
						</Tooltip>
						<Tooltip title={t('general.view')}>
							<Button
								style={styles.button}
								type="primary"
								shape="round"
								onClick={() => {
									handleEditAttachment(item);
								}}
							>
								<ZoomInOutlined />
							</Button>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleEditAttachment(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const columnsMergedComment = columnsComment.map(col => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (item: Comment) => ({
				item,
				inputType: 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditingComment(item),
			}),
		};
	});

	const draggerProps: DraggerProps = {
		name: 'file',
		multiple: false,
		maxCount: 1,
		showUploadList: false,
		accept: 'application/pdf',
		onRemove: () => {
			setFile(undefined);
		},
		beforeUpload: (file: RcFile): boolean => {
			if (file.type === 'application/pdf') {
				var url = URL.createObjectURL(file);
				setIsPDF(true);
				setFile(file);
				setLocalUrl(url);
				setIsViewerVisible(true);
			} else {
				setIsPDF(false);
			}
			return false;
		},
	};

	return (
		<div>
			<Col>
				<Row>
					<Col>
						<Breadcrumb
							separator=">"
							style={{ height: 31, margin: '16px 24px 0 0' }}
							items={[
								{ title: <ApartmentOutlined twoToneColor={colors.brandColor1} /> },
								{ title: t('management.management') },
								{ title: t('management.tasks') },
								{ title: task?.description },
							]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Space>
							{changed ? (
								<Popconfirm
									title={t('messages.confirmExit')}
									onConfirm={() => {
										navigate(AuthRoutes.tasks + '/index');
									}}
									okText={t('general.ok')}
									cancelText={t('general.cancel')}
								>
									<Tooltip title={t('general.back')}>
										<Button icon={<ArrowLeftOutlined />} type="primary" shape="round">
											{t('general.back')}
										</Button>
									</Tooltip>
								</Popconfirm>
							) : (
								<Tooltip title={t('general.back')}>
									<Button icon={<ArrowLeftOutlined />} type="primary" shape="round" onClick={() => navigate(AuthRoutes.tasks + '/index')}>
										{t('general.back')}
									</Button>
								</Tooltip>
							)}
							<Button type="primary" disabled={!changed} shape="round" icon={<SaveOutlined />} onClick={handleTaskSave}>
								{t('general.save')}
							</Button>
						</Space>
					</Col>
				</Row>
				<Content className="site-layout-background">
					<Row>
						<Col span={span}>
							<Card size="small" title={t('management.details')} style={styles.card}>
								<Form {...baseStyles.formLayout} form={formTaskDetails} name="task-details" onValuesChange={handleValuesChange}>
									<Form.Item label={t('management.description')} name="description" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Input />
									</Form.Item>
									<Form.Item label={t('management.requester')} name="requester">
										<Input disabled />
									</Form.Item>
									<Form.Item label={t('management.creationDate')} name="creationDate" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<DatePicker locale={locale} format={dateFormat} disabled />
									</Form.Item>
									<Form.Item label={t('management.expirationDate')} name="expirationDate">
										<DatePicker locale={locale} format={dateFormat} />
									</Form.Item>
									<Form.Item label={t('management.completionDate')} name="completionDate">
										<DatePicker locale={locale} format={dateFormat} />
									</Form.Item>
									<Form.Item label={t('management.taskType')} name="taskType" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Select>
											{taskTypes.map(p => {
												return (
													<Option key={`taskType-${p.id}`} value={p.id}>
														{p.description}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
									<Form.Item label={t('management.taskStatus')} name="taskStatus" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Select>
											{taskStatuses.map(p => {
												return (
													<Option key={`priority-${p.id}`} value={p.id}>
														{p.description}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
								</Form>
							</Card>
						</Col>
						<Col span={span}>
							<Card size="small" title={t('management.users')} style={styles.card}>
								<div className="body">
									<AutoComplete
										style={{ width: '100%' }}
										value={searchText}
										options={options}
										onSelect={handleSelectUser}
										onSearch={handleSearchUser}
										placeholder={t('management.searchUser')}
									/>
								</div>
								<Table<User>
									rowKey={item => item.id}
									loading={isloadingUsers}
									key={`participants-table`}
									tableLayout="auto"
									size="small"
									dataSource={selectedUsers}
									columns={columnsUser}
									pagination={false}
								/>
							</Card>
						</Col>
						<Col span={span}>
							<Card
								size="small"
								title={t('management.comments')}
								style={styles.card}
								extra={<Button type="primary" shape="round" style={styles.buttonCard} icon={<PlusOutlined />} onClick={handleAddComment}></Button>}
							>
								<Form form={formComment} component={false}>
									<Table<Comment>
										components={{
											body: {
												cell: EditableCell,
											},
										}}
										rowKey={item => 'comment-' + item.id}
										loading={isloadingComments}
										key={`comments-table`}
										tableLayout="auto"
										size="small"
										dataSource={comments}
										columns={columnsMergedComment}
										pagination={false}
									/>
								</Form>
							</Card>
						</Col>
						<Col span={span}>
							<Card
								size="small"
								title={t('management.attachments')}
								style={styles.card}
								extra={<Button type="primary" shape="round" style={styles.buttonCard} icon={<PlusOutlined />} onClick={handleAddAttachment}></Button>}
							>
								<Table<DocumentAttachment>
									rowKey={item => 'attachment' + item.id}
									loading={isloadingDocuments}
									key={`news-table`}
									tableLayout="auto"
									size="small"
									dataSource={attachments}
									columns={columnsDocumentAttachment}
									pagination={false}
								/>
							</Card>
						</Col>
					</Row>
				</Content>
				<SizedModal
					size={url === '' && localUrl === '' ? 'small' : 'medium'}
					open={isModalVisible}
					title={title}
					okButtonProps={{
						shape: 'round',
						type: 'primary',
					}}
					okText={t('general.ok')}
					cancelText={t('general.cancel')}
					cancelButtonProps={{
						shape: 'round',
						type: 'primary',
					}}
					onOk={handleAttachmentSave}
					onCancel={handleCancel}
					destroyOnClose={true}
				>
					<Row gutter={[12, 12]}>
						<Col span={isViewerVisible ? 9 : 24}>
							<Form {...baseStyles.formLayout} form={formAttachment} name="attachment-details">
								<Form.Item label={t('general.description')} name="description" rules={[{ required: true, message: t('messages.enterValue') }]}>
									<Input />
								</Form.Item>
								<Form.Item label={t('general.file')} name="upload" valuePropName="filelist" getValueFromEvent={getUploadedFile}>
									<div>
										<Dragger {...draggerProps}>
											<p className="ant-upload-drag-icon">
												<InboxOutlined />
											</p>
										</Dragger>
									</div>
								</Form.Item>
							</Form>
							{!isValidData ? <Alert message={t('messages.correctErrorsToContinue')} type="error" /> : null}
							{!isPDF ? <Alert message={t('messages.notPDF')} type="error" /> : null}
						</Col>
						{isViewerVisible ? (
							<Col span={15}>
								<DocumentViewer url={localUrl === '' ? url : localUrl} size="medium"></DocumentViewer>
							</Col>
						) : null}
					</Row>
				</SizedModal>
			</Col>
		</div>
	);
};

export default PageTaskDetails;
