export interface Network {
	[key: string]: any;
	id: number;
	name: string;
	description: string;
	avatar: string;
	internalCode: string;
	externalCode: string;
	networkTypeId: number;
	countryId: number;
	isDeleted: boolean;
}

export interface Center {
	[key: string]: any;
	id: number;
	name: string;
	description: string;
	details: string;
	avatar: string;
	internalCode: string;
	externalCode: string;
	centerTypeId: number;
	networkId: number;
	addressId: number;
	services: number[];
	isDeleted: boolean;
	isVisible: boolean;
}

export const initialCenterValue: Center = {
	id: 0,
	name: '',
	description: '',
	details: '',
	avatar: '',
	internalCode: '',
	externalCode: '',
	networkId: 0,
	centerTypeId: 1,
	addressId: 0,
	services: [],
	isDeleted: false,
	isVisible: false,
};

export const initialNetworkValue: Network = {
	id: 0,
	name: '',
	description: '',
	avatar: '',
	internalCode: '',
	externalCode: '',
	networkTypeId: 1,
	countryId: 1,
	isDeleted: false,
};

export interface Infrastructure {
	[key: string]: any;
	id: number;
	description: string;
	internalCode: string;
	externalCode: string;
	infrastructureTypeId: number;
	centerId: number;
	parentId: number;
	isDeleted: boolean;
}

export interface InfrastructureNode {
	key: number;
	title: string;
	parentId: number;
	isDeleted: boolean;
	children?: InfrastructureNode[];
}

export const initialInfrastructureValue: Infrastructure = {
	id: 0,
	description: '',
	internalCode: '',
	externalCode: '',
	infrastructureTypeId: 1,
	centerId: 0,
	parentId: 0,
	isDeleted: false,
};

export const initialInfrastructureNodeValue: InfrastructureNode = {
	key: 0,
	title: '',
	isDeleted: false,
	parentId: 0,
};

export interface Equipment {
	[key: string]: any;
	id: number;
	description: string;
	internalCode: string;
	externalCode: string;
	equipmentTypeId: number;
	equipmentStatusId: number;
	locationId: number;
	isDeleted: boolean;
}

export const initialEquipmentValue: Equipment = {
	id: 0,
	description: '',
	internalCode: '',
	externalCode: '',
	equipmentTypeId: 0,
	equipmentStatusId: 0,
	locationId: 0,
	isDeleted: false,
};

export interface Service {
	[key: string]: any;
	id: number;
	description: string;
	internalCode: string;
	externalCode: string;
	parentId: number;
	isDeleted: boolean;
}

export interface ServiceNode {
	key: number;
	title: string;
	parentId: number;
	isDeleted: boolean;
	isVisible: boolean;
	children?: ServiceNode[];
}

export const initialServiceValue: Service = {
	id: 0,
	description: '',
	internalCode: '',
	externalCode: '',
	parentId: 0,
	isDeleted: false,
};

export const initialServiceNodeValue: ServiceNode = {
	key: 0,
	title: '',
	isDeleted: false,
	isVisible: false,
	parentId: 0,
};
