import { ReactNode, useEffect, useState } from 'react';
import { Button, Col, Row, Space, Table } from 'antd';
import { SizedModal } from '../../components';
import { EditOutlined } from '@ant-design/icons';
import { useStore } from 'react-redux';
import { permissionSelectors } from '../../store/selectors';
import { Permission } from '../../store/types';
import { useTranslation } from 'react-i18next';

const FormConfigurationPermissions = () => {
	const store = useStore();
	const state = store.getState() as any;
	const { t } = useTranslation();
	const [modalTitle, setModalTitle] = useState<string>('');
	const [isModalVisible, changeModalVisibility] = useState(false);
	const [permissions, setPermissions] = useState<Permission[]>([]);
	const [component, setComponent] = useState<ReactNode>();

	useEffect(() => {
		setPermissions(permissionSelectors.selectAll(state));
	}, [state]);

	const handleOk = async () => {
		changeModalVisibility(false);
		setModalTitle('Editar');
		setComponent(null);
	};

	const handleCancel = () => {
		changeModalVisibility(false);
	};

	const columns = [
		{
			title: t('configurations.code'),
			dataIndex: 'code',
			key: 'code',
		},
		{
			title: t('configurations.description'),
			dataIndex: 'description',
			key: 'description',
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: any): ReactNode => {
				return (
					<Space size="middle">
						<Button type="primary" icon={<EditOutlined />} shape="round" onClick={() => {}} />
					</Space>
				);
			},
		},
	];

	return (
		<div>
			<Row style={{ paddingTop: 10 }}>
				<Col flex={1}>
					<Table
						rowKey={(item: { id: any }) => item.id}
						tableLayout="auto"
						size="small"
						dataSource={permissions}
						columns={columns}
						pagination={{ position: ['bottomRight'], showTotal: total => `${t('general.total')} ${total} ${t('general.items')}` }}
						style={{ width: '96vw' }}
					/>
					<SizedModal
						title={modalTitle}
						open={isModalVisible}
						okText={t('general.save')}
						cancelText={t('general.cancel')}
						onOk={handleOk}
						onCancel={handleCancel}
						size="small"
					>
						{component}
					</SizedModal>
				</Col>
			</Row>
		</div>
	);
};
export default FormConfigurationPermissions;
