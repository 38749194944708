import { Tabs, Layout, Breadcrumb, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { MedicineBoxOutlined } from '@ant-design/icons';
import { colors } from '../../assets/styles';
import { PageProfessionalsDentists, PageProfessionalsPhysicians } from '..';

const { Content } = Layout;

const PageViewProfessionals = () => {
	const { t } = useTranslation();

	const tabs = [
		{
			label: t('general.physicians'),
			key: 0,
			children: <PageProfessionalsPhysicians />,
		},
		{
			label: t('general.dentists'),
			key: 1,
			children: <PageProfessionalsDentists />,
		},
	];

	return (
		<div>
			<Col>
				<Row>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Breadcrumb
							separator=">"
							items={[
								{ title: <MedicineBoxOutlined twoToneColor={colors.brandColor1} /> },
								{ title: t('view.viewContent') },
								{ title: t('view.professionals') },
							]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}></Col>
				</Row>
				<Content className="site-layout-background">
					<Tabs
						defaultActiveKey="0"
						style={{ padding: 10 }}
						items={tabs.map(item => {
							return {
								label: item.label,
								key: item.key.toString(),
								children: item.children,
							};
						})}
					></Tabs>
				</Content>
			</Col>
		</div>
	);
};

export default PageViewProfessionals;
