import { useState, Key, ReactNode, useEffect } from 'react';
import { initialDocumentValue, initialEventValue, initialLinkItemValue, initialNewsValue, ParentType, Tag } from '../../store/types';
import { Table, Row, Col, Space, Button, Typography, Tag as TagNode, Input, Card, message, Form, Alert, AutoComplete } from 'antd';
import { useStore } from 'react-redux';
import { documentsSelectors, eventsSelectors, linkItemsSelectors, newsItemsSelectors, tagsSelectors } from '../../store/selectors';
import { ColumnProps } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { tags as tagsReducer } from '../../store/reducers';
import { PlusOutlined } from '@ant-design/icons';
import { SizedModal } from '../../components';
import { createUpdateTag } from '../../store/api';
import { baseStyles, colors } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { includes, objectsAreEquals } from '../../utils/util-custom-validators';

const { Text } = Typography;
const key = 'saving';

const FormAddTag = ({ parentId, parentType, setParentData, setChanged }) => {
	const store = useStore();
	const styles = baseStyles;
	const { t } = useTranslation();
	const [formAddTag] = Form.useForm();
	const [tags, setTags] = useState<Tag[]>([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>();
	const [tagNodes, setTagNodes] = useState<ReactNode[]>([]);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [description, setDescription] = useState<string>('');
	const [parent, setParent] = useState<any>();
	const [originalParent, setOriginalParent] = useState<any>();
	const [isValidData, setIsValidData] = useState<boolean>(true);

	useEffect(() => {
		var temp: any = undefined;
		switch (parentType) {
			case ParentType.newsItem:
				temp = newsItemsSelectors.selectById(store.getState() as any, parentId) || initialNewsValue;
				break;
			case ParentType.documentItem:
				temp = documentsSelectors.selectById(store.getState() as any, parentId) || initialDocumentValue;
				break;
			case ParentType.eventItem:
				temp = eventsSelectors.selectById(store.getState() as any, parentId) || initialEventValue;
				break;
			case ParentType.linkItem:
				temp = linkItemsSelectors.selectById(store.getState() as any, parentId) || initialLinkItemValue;
				break;
		}
		var items = tagsSelectors
			.selectAll(store.getState() as any)
			.filter(t => !t.isDeleted)
			.sort((a: Tag, b: Tag) => a.description.localeCompare(b.description));
		const initialSelectedKeys = items.filter(t => temp.tags.includes(t.description)).map(s => s.id);
		setParent(temp);
		setOriginalParent(temp);
		setSelectedRowKeys(initialSelectedKeys);
		setTags(items);
		setTagNodes(
			items
				.filter(t => initialSelectedKeys.includes(t.id))
				.map(t => {
					return (
						<TagNode style={styles.tag} key={t.id}>
							{t.description}
						</TagNode>
					);
				}),
		);
	}, [styles.tag, store, parentType, parentId]);

	const handleRowSelect = (selectedRowKeys: Key[], selectedRows: Tag[]) => {
		setSelectedRowKeys(selectedRowKeys);
		setParent({ ...parent, tags: selectedRows.map(t => t.description) });
		setParentData({ ...parent, tags: selectedRows.map(t => t.description) });
		setTagNodes(
			selectedRows.map(t => {
				return (
					<TagNode style={styles.tag} key={t.id}>
						{t.description}
					</TagNode>
				);
			}),
		);
		setChanged(!objectsAreEquals(originalParent, { ...parent, tags: selectedRows.map(t => t.description) }));
	};

	const rowSelection: TableRowSelection<Tag> = {
		selectedRowKeys: selectedRowKeys,
		onChange: handleRowSelect,
	};

	const handleSearch = (value: string) => {
		if (!value) setTags(tagsSelectors.selectAll(store.getState() as any).filter(t => !t.isDeleted));
		else setTags(tagsSelectors.selectAll(store.getState() as any).filter(t => !t.isDeleted && includes(t.description, value)));
	};

	const handleAddTag = () => {
		setDescription('');
		setIsModalVisible(true);
	};

	const handleOk = async () => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		formAddTag
			.validateFields()
			.then(async values => {
				var description = values['description'];
				if (tags.filter(t => t.description.toLocaleUpperCase().localeCompare(description.toLocaleUpperCase()) === 0).length > 0) {
					message.error({ content: t('messages.tagExists'), key, duration: 2 });
					return;
				}
				var response = await createUpdateTag({ id: 0, code: values['description'].toUpperCase(), description: values['description'], isDeleted: false });
				if (response !== undefined) {
					store.dispatch(tagsReducer.actions.tagCreateUpdate(response));
					setTags(tagsSelectors.selectAll(store.getState() as any).filter(t => !t.isDeleted));
					message.success({ content: t('messages.dataSaved'), key, duration: 2 });
				} else message.info({ content: t('messages.errorOnSave'), key, duration: 2 });
				setIsModalVisible(false);
			})
			.catch(errors => {
				setIsValidData(false);
			});
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const columns: ColumnProps<Tag>[] = [
		{
			title: t('general.description'),
			dataIndex: 'description',
			key: 'description',
			sorter: {
				compare: (a: Tag, b: Tag) => {
					if (a.description < b.description) return -1;
					if (a.description > b.description) return 1;
					return 0;
				},
				multiple: 2,
			},
		},
	];

	return (
		<div>
			<Card
				size="small"
				title={t('news.newsTags')}
				style={{ ...styles.card, width: '95%' }}
				extra={[<Button type="primary" style={{ backgroundColor: colors.brandColor2 }} shape="round" icon={<PlusOutlined />} onClick={handleAddTag}></Button>]}
			>
				<Col>
					<Row>
						<Col key="col1" flex={3}>
							<Space direction="horizontal">
								<Text strong>{t('general.searchEngine')}</Text>
								<AutoComplete
									popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
									style={styles.search}
									placeholder={t('general.search')}
									allowClear
									onSearch={handleSearch}
								/>
							</Space>
						</Col>
						<Col key="col2" flex={4}></Col>
						<Col key="col3" flex={3}></Col>
					</Row>
					<Row style={{ padding: 5 }}>
						<Text style={{ paddingRight: 5 }}>{t('general.selectedTags')} </Text>
						<>{tagNodes}</>
					</Row>
				</Col>
				<Table<Tag>
					rowKey={item => item.id}
					tableLayout="auto"
					size="small"
					dataSource={tags}
					columns={columns}
					rowSelection={rowSelection}
					pagination={false}
				/>
				<SizedModal
					size="small"
					title={t('messages.editTag')}
					open={isModalVisible}
					okText={t('general.save')}
					cancelText={t('general.cancel')}
					onOk={handleOk}
					onCancel={handleCancel}
				>
					<Form
						{...baseStyles.formLayout}
						form={formAddTag}
						initialValues={{
							description: description,
						}}
						name="address-details"
						title={t('general.description')}
					>
						<Form.Item label={t('general.description')} name="description" rules={[{ required: true, message: t('messages.enterValue') }]}>
							<Input />
						</Form.Item>
					</Form>
					{!isValidData ? <Alert message={t('messages.correctErrorsToContinue')} type="error" /> : null}
				</SizedModal>
			</Card>
		</div>
	);
};

export default FormAddTag;
