import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Select, Input, Slider, Form, Alert, Card, Typography } from 'antd';
import { Professional, initialProfessionalValue, RelationType, ProfessionalType } from '../../store/types';
import { professionalTypeSelectors, relationTypesSelectors } from '../../store/selectors';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { fetchProfessional } from '../../store/api';

const { Option } = Select;
const { Text } = Typography;

const FormProfessionalAccreditationDetails = ({ professionalId, setProfessionalData }) => {
	const [formProfessionalAccreditationDetails] = Form.useForm();
	const { t } = useTranslation();
	const store = useStore();
	const state = store.getState() as any;
	const styles = baseStyles;
	const [isValidData, setIsValidData] = useState<boolean>(true);
	const [professionalTypes, setProfessionalTypes] = useState<ProfessionalType[]>([]);
	const [professional, setProfessional] = useState<Professional>(initialProfessionalValue);
	const [relationTypes, setRelationTypes] = useState<RelationType[]>([]);
	const [sisNumber, setSisNumber] = useState<string>('');
	const [hours, setHours] = useState<number>(0);
	const [professionalType, setProfessionalType] = useState<string>('');
	const [relationType, setRelationType] = useState<string>('');

	useEffect(() => {
		setRelationTypes(relationTypesSelectors.selectAll(state));
		setProfessionalTypes(professionalTypeSelectors.selectAll(state));
		fetchProfessional(professionalId).then(item => {
			setSisNumber(item.sisNumber);
			setProfessionalType(professionalTypes.find(at => at.id === item.professionalTypeId)?.description || '');
			setRelationType(relationTypes.find(c => c.id === item.relationTypeId)?.description || '');
			setHours(item.hours);
			formProfessionalAccreditationDetails.setFieldsValue({
				sisNumber: sisNumber,
				hours: hours,
				relationType: relationType,
				professionalType: professionalType,
			});
		});
	}, [formProfessionalAccreditationDetails, hours, professionalId, professionalType, professionalTypes, relationType, relationTypes, sisNumber, state]);

	const onSliderChange = (value: any) => {
		setProfessional({ ...professional, hours: value });
		setProfessionalData({ ...professional, hours: value });
		setHours(value);
	};

	const handleValuesChange = (changedValues: any) => {
		if (changedValues['professionalType']) {
			var tProfessionalType = professionalTypes.find(c => c.id === changedValues['professionalType']);
			if (tProfessionalType === undefined) {
				setIsValidData(false);
			} else {
				setProfessionalData({ ...professional, professionalTypeId: tProfessionalType.id });
				setIsValidData(true);
			}
		}
		if (changedValues['relationType']) {
			var tRelationType = relationTypes.find(c => c.id === changedValues['relationType']);
			if (tRelationType === undefined) setIsValidData(false);
			else {
				setProfessionalData({ ...professional, relationTypeId: tRelationType.id });
				setIsValidData(true);
			}
		}
		if (changedValues['sisNumber']) {
			setProfessionalData({ ...professional, sisNumber: changedValues['sisNumber'] });
		}
	};

	const marks = {
		1: '1',
		15: '15',
		30: '30',
		45: '45',
	};

	return (
		<Card size="small" title={t('professionals.accreditationDetails')} style={styles.card}>
			<Form
				{...baseStyles.formLayout}
				form={formProfessionalAccreditationDetails}
				initialValues={{
					sisNumber: sisNumber,
					hours: hours,
					relationType: relationType,
					professionalType: professionalType,
				}}
				onValuesChange={handleValuesChange}
				name="accreditation-details"
			>
				<Form.Item label={t('professionals.professionalType')} name="professionalType" rules={[{ required: true, message: `${t('messages.pleaseInput')}!` }]}>
					<Select>
						{professionalTypes.map(pt => {
							return (
								<Option key={`professionalType-${pt.id}`} value={pt.id}>
									{pt.description}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item label={t('professionals.sisNumber')} name="sisNumber" rules={[{ required: false }]}>
					<Input />
				</Form.Item>
				<Form.Item label={t('professionals.exclusiveHours')} name="hours" rules={[{ required: false }]}>
					<Input.Group compact>
						<Slider marks={marks} min={0} max={45} onChange={onSliderChange} value={hours} style={{ margin: '0 20px', width: '250px' }} />
						<Input value={hours} disabled={true} style={{ width: '50px' }} />
					</Input.Group>
				</Form.Item>
				<Form.Item label={t('professionals.relationType')} name="relationType" rules={[{ required: true, message: `${t('messages.pleaseInput')}!` }]}>
					<Select>
						{relationTypes.map(rt => {
							return (
								<Option key={`relationType-${rt.id}`} value={rt.id}>
									{rt.description}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Text strong>{t('general.lastModification') + ': ' + professional.modifiedBy}</Text>
				{!isValidData ? <Alert message={t('messages.correctErrorsToContinue')} type="error" /> : null}
			</Form>
		</Card>
	);
};
export default FormProfessionalAccreditationDetails;
