import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import en_translation from './locales/en/translation.json';
import es_translation from './locales/es/translation.json';

const languageDetectorOptions = {
	order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],
	lookupQuerystring: 'lng',
	lookupCookie: 'i18next',
	lookupLocalStorage: 'i18nextLng',
	lookupSessionStorage: 'i18nextLng',
	lookupFromPathIndex: 0,
	lookupFromSubdomainIndex: 0,
};
const languageDetector = new LanguageDetector(null, languageDetectorOptions);

i18next
	.use(Backend)
	.use(languageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		debug: true,
		interpolation: {
			escapeValue: false,
		},
		resources: {
			en: {
				translation: en_translation,
			},
			es: {
				translation: es_translation,
			},
		},
	});

export default i18next;
