import { ReactNode, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Space, DatePicker, Input, Card, Button, message, Form, Select, Row, Col, Popconfirm, Tooltip, Table, Alert, AutoComplete } from 'antd';
import { DocumentViewer, EditableCell, SizedModal } from '../../components';
import {
	Task,
	User,
	initialTaskValue,
	TaskStatus,
	TaskType,
	DocumentAttachment,
	ParentType,
	initialDocumentAttachmentValue,
	initialCommentValue,
	Comment,
} from '../../store/types';
import { taskStatusesSelectors, taskTypesSelectors } from '../../store/selectors/domains';
import { commentsSelectors, tasksSelectors, usersSelectors } from '../../store/selectors';
import { comments as commentsReducer } from '../../store/reducers';
import { DeleteOutlined, EditOutlined, InboxOutlined, PlusOutlined, RollbackOutlined, SaveOutlined, ZoomInOutlined } from '@ant-design/icons';
import { dateFormat } from '../../assets/formats';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import { createUpdateDocumentAttachment, createUpdateTaskComment, deleteTaskComment, fetchTaskComments, fetchTaskDocuments } from '../../store/api';
import { RcFile } from 'antd/lib/upload';
import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger';
import locale from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';

const { Option } = Select;

const key = 'saving';

const FormTaskDetails = ({ taskId, setSelectedTask }) => {
	const { t } = useTranslation();
	const store = useStore();
	const styles = baseStyles;
	const [formTask] = Form.useForm();
	const [formAttachment] = Form.useForm();
	const [formComment] = Form.useForm();
	const [title, setTitle] = useState<string>('');
	const [task, setTask] = useState<Task>(initialTaskValue);
	const [taskStatuses, setTaskStatuses] = useState<TaskStatus[]>([]);
	const [taskTypes, setTaskTypes] = useState<TaskType[]>([]);
	const [documentAttachment, setDocumentAttachment] = useState<DocumentAttachment>(initialDocumentAttachmentValue);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [isViewerVisible, setIsViewerVisible] = useState<boolean>(false);
	const [comments, setComments] = useState<Comment[]>([]);
	const [url, setUrl] = useState<string>('');
	const [localUrl, setLocalUrl] = useState<string>('');
	const [isPDF, setIsPDF] = useState<boolean>(true);
	const [users, setUsers] = useState<User[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
	const [editingCommentKey, setEditingCommentKey] = useState<number>(0);
	const [isValidData, setIsValidData] = useState<boolean>(true);
	const [attachments, setAttachments] = useState<DocumentAttachment[]>([]);
	const [searchText, setSearchText] = useState<string>('');
	const [options, setOptions] = useState([]);
	const [span, setSpan] = useState<number>(24);
	const [file, setFile] = useState<RcFile>();
	const [isloadingUsers, setIsLoadingUsers] = useState(true);
	const [isloadingDocuments, setIsLoadingDocuments] = useState(true);
	const [isloadingComments, setIsLoadingComments] = useState(true);

	window.onresize = () => {
		window.innerWidth > 1600 ? setSpan(12) : setSpan(24);
	};

	useEffect(() => {
		window.innerWidth > 1600 ? setSpan(12) : setSpan(24);
		setDocumentAttachment({ ...initialDocumentAttachmentValue, parentId: taskId, parentType: ParentType.task });
		setTaskStatuses(taskStatusesSelectors.selectAll(store.getState() as any));
		setTaskTypes(taskTypesSelectors.selectAll(store.getState() as any));
		var userItems: User[] = usersSelectors.selectAll(store.getState() as any).filter(u => !u.isDeleted);
		var item: Task = tasksSelectors.selectById(store.getState() as any, taskId) || {
			...initialTaskValue,
			description: t('management.newTask'),
			creationDate: new Date().toString(),
			taskTypeId: 1,
			taskStatusId: 1,
			taskOwnerId: globalThis.user.id,
		};
		setTask(item);
		setUsers(userItems);
		setSelectedUsers(userItems.filter(u => item.participantIds.includes(u.id)));
		setIsLoadingUsers(false);
		formTask.setFieldsValue({
			description: item.description,
			creationDate: dayjs(item.creationDate),
			requester: usersSelectors.selectById(store.getState() as any, item.taskOwnerId)?.username,
			taskType: item.taskTypeId,
			taskStatus: item.taskStatusId,
		});
		if (item.expirationDate)
			formTask.setFieldsValue({
				expirationDate: dayjs(item.completionDate),
			});
		if (item.completionDate)
			formTask.setFieldsValue({
				completionDate: dayjs(item.completionDate),
			});
		formAttachment.setFieldsValue({
			description: t('management.attachments'),
		});
		if (taskId !== 0) {
			fetchTaskComments(taskId).then(items => {
				setComments(items.filter(c => !c.isDeleted));
				setIsLoadingComments(false);
			});
			fetchTaskDocuments(taskId).then(items => {
				setAttachments(items.filter(a => !a.isDeleted));
				setIsLoadingDocuments(false);
			});
		} else {
			setIsLoadingComments(false);
			setIsLoadingDocuments(false);
		}
	}, [formTask, formAttachment, taskId, store, t]);

	const handleAddAttachment = () => {
		setTitle(t('management.addAttachment'));
		setIsViewerVisible(false);
		setIsModalVisible(true);
	};

	const handleEditAttachment = item => {
		setDocumentAttachment(item);
		setUrl(item.url);
		setIsViewerVisible(true);
		setTitle(t('management.editAttachment'));
		setIsModalVisible(true);
	};

	const getUploadedFile = (e: { filelist: RcFile[] }) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.filelist;
	};

	const handleSearchUser = (searchText: string) => {
		setSearchText(searchText);
		if (searchText.length >= 3) {
			var seleted = users
				.filter(u => u.username.includes(searchText))
				.map(u => {
					return { value: u.username };
				});
			setOptions(!searchText ? [] : seleted);
		}
	};

	const isEditingComment = (item: Comment) => item.id === editingCommentKey;

	const handleEditComment = (item: Comment) => {
		formComment.setFieldsValue({
			...item,
		});
		setEditingCommentKey(item.id);
	};

	const handleAddComment = () => {
		const newData: Comment = initialCommentValue;
		setComments([...comments, newData]);
	};

	const handleCommentSave = async (item: Comment) => {
		setIsLoadingComments(true);
		try {
			const values = await formComment.validateFields();
			if (values.errorField === undefined) {
				var temp: Comment = {
					...item,
					id: item.id,
					activityId: 0,
					caseId: 0,
					taskId: taskId,
					description: values['description'],
				};
				message.loading({ content: t('messages.saving'), key, duration: 0 });
				var response = await createUpdateTaskComment(temp, taskId);
				if (response !== undefined) {
					store.dispatch(commentsReducer.actions.commmentsCreateUpdate(response));
					setComments(commentsSelectors.selectAll(store.getState() as any).filter(c => c.taskId === taskId && !c.isDeleted));
					message.success({ content: t('messages.dataSaved'), key, duration: 2 });
				} else message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
				setEditingCommentKey(0);
			}
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
			setEditingCommentKey(0);
		}
		setIsLoadingComments(false);
	};

	const handleDeleteComment = async (item: Comment) => {
		setIsLoadingComments(true);
		try {
			var ok = await deleteTaskComment(item);
			if (ok) {
				var temp = { ...item, isDeleted: true };
				store.dispatch(commentsReducer.actions.commmentsCreateUpdate(temp));
				setComments(commentsSelectors.selectAll(store.getState() as any).filter(c => c.activityId === taskId && !c.isDeleted));
			} else message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		}
		setIsLoadingComments(false);
	};

	const handleCommentCancel = () => {
		setEditingCommentKey(0);
	};

	const handleSelectUser = (seletedData: string) => {
		setSearchText('');
		var user = users.find(u => u.username === seletedData);
		if (user && !task.participantIds.includes(user.id)) {
			setSelectedUsers([...selectedUsers, user]);
		}
		setOptions([]);
	};

	const handleDeleteUser = (item: User) => {
		setSelectedUsers(selectedUsers.filter(u => u.id !== item.id));
	};

	const handleAttachmentSave = async () => {
		setIsModalVisible(false);
		const values = await formAttachment.validateFields();
		if (values.errorField === undefined) {
			var temp: DocumentAttachment = {
				...documentAttachment,
				description: values['description'],
			};
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			var response = await createUpdateDocumentAttachment(temp, file);
			if (response !== undefined) {
				setIsLoadingDocuments(true);
				fetchTaskDocuments(taskId).then(items => {
					setAttachments(items.filter(a => !a.isDeleted));
					setIsLoadingDocuments(false);
				});
				message.success({ content: t('messages.dataSaved'), key, duration: 2 });
			} else message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
			setUrl('');
			setIsValidData(true);
			setIsPDF(true);
		}
	};

	const handleValuesChange = (changedValues: any) => {
		var item: Task = task;
		if (changedValues['description'] !== undefined) item = { ...task, description: changedValues['description'] };
		if (changedValues['expirationDate'] !== undefined) item = { ...task, expirationDate: changedValues['expirationDate'].toDate() };
		if (changedValues['completionDate'] !== undefined) item = { ...task, completionDate: changedValues['completionDate'].toDate() };
		if (changedValues['taskType'] !== undefined) item = { ...task, taskTypeId: changedValues['taskType'] };
		if (changedValues['taskStatus'] !== undefined) item = { ...task, taskStatusId: changedValues['taskStatus'] };
		setTask(item);
		setSelectedTask(item);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		setUrl('');
		setLocalUrl('');
	};

	const columnsUser: ColumnProps<User>[] = [
		{
			title: t('management.description'),
			dataIndex: 'username',
			key: 'username',
			width: '50%',
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: User): ReactNode => {
				return (
					<Space size="middle">
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteUser(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const columnsComment = [
		{
			title: t('management.description'),
			dataIndex: 'description',
			key: 'description',
			editable: true,
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: Comment): ReactNode => {
				const editable = isEditingComment(item);
				return editable ? (
					<Space>
						<Button type="primary" shape="round" onClick={() => handleCommentSave(item)} icon={<SaveOutlined />} />
						<Popconfirm title={t('messages.confirmCancel')} onConfirm={handleCommentCancel} okText={t('general.ok')} cancelText={t('general.cancel')}>
							<Button type="primary" shape="round" icon={<RollbackOutlined />} />
						</Popconfirm>
					</Space>
				) : (
					<Space size="middle">
						<Tooltip title={t('general.edit')}>
							<Button
								type="primary"
								shape="round"
								onClick={() => {
									handleEditComment(item);
								}}
								icon={<EditOutlined />}
							/>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteComment(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const columnsDocumentAttachment: ColumnProps<DocumentAttachment>[] = [
		{
			title: t('management.description'),
			dataIndex: 'description',
			key: 'description',
			width: '50%',
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: DocumentAttachment): ReactNode => {
				return (
					<Space size="middle">
						<Tooltip title={t('general.edit')}>
							<Button
								style={styles.button}
								type="primary"
								shape="round"
								onClick={() => {
									handleEditAttachment(item);
								}}
							>
								<EditOutlined />
							</Button>
						</Tooltip>
						<Tooltip title={t('general.view')}>
							<Button
								style={styles.button}
								type="primary"
								shape="round"
								onClick={() => {
									handleEditAttachment(item);
								}}
							>
								<ZoomInOutlined />
							</Button>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleEditAttachment(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const columnsMergedComment = columnsComment.map(col => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (item: Comment) => ({
				item,
				inputType: 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditingComment(item),
			}),
		};
	});

	const draggerProps: DraggerProps = {
		name: 'file',
		multiple: false,
		maxCount: 1,
		showUploadList: false,
		accept: 'application/pdf',
		onRemove: () => {
			setFile(undefined);
		},
		beforeUpload: (file: RcFile): boolean => {
			if (file.type === 'application/pdf') {
				var url = URL.createObjectURL(file);
				setIsPDF(true);
				setFile(file);
				setLocalUrl(url);
				setIsViewerVisible(true);
			} else {
				setIsPDF(false);
			}
			return false;
		},
	};

	return (
		<Col>
			<div className="card-container">
				<Col>
					<Row gutter={[16, 16]}>
						<Col span={span}>
							<Card size="small" title={t('management.details')} style={styles.card}>
								<Form {...baseStyles.formLayout} form={formTask} name="task-details" onValuesChange={handleValuesChange}>
									<Form.Item label={t('management.description')} name="description" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Input />
									</Form.Item>
									<Form.Item label={t('management.requester')} name="requester">
										<Input disabled />
									</Form.Item>
									<Form.Item label={t('management.creationDate')} name="creationDate" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<DatePicker locale={locale} format={dateFormat} disabled />
									</Form.Item>
									<Form.Item label={t('management.expirationDate')} name="expirationDate">
										<DatePicker locale={locale} format={dateFormat} />
									</Form.Item>
									<Form.Item label={t('management.completionDate')} name="completionDate">
										<DatePicker locale={locale} format={dateFormat} />
									</Form.Item>
									<Form.Item label={t('management.taskType')} name="taskType" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Select>
											{taskTypes.map(p => {
												return (
													<Option key={`taskType-${p.id}`} value={p.id}>
														{p.description}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
									<Form.Item label={t('management.taskStatus')} name="taskStatus" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Select>
											{taskStatuses.map(p => {
												return (
													<Option key={`priority-${p.id}`} value={p.id}>
														{p.description}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
								</Form>
							</Card>
						</Col>
						<Col span={span}>
							<Card size="small" title={t('management.users')} style={styles.card}>
								<div className="body">
									<AutoComplete
										style={{ width: '100%' }}
										value={searchText}
										options={options}
										onSelect={handleSelectUser}
										onSearch={handleSearchUser}
										placeholder={t('management.searchUser')}
									/>
								</div>
								<Table<User>
									rowKey={item => item.id}
									loading={isloadingUsers}
									key={`participants-table`}
									tableLayout="auto"
									size="small"
									dataSource={selectedUsers}
									columns={columnsUser}
									pagination={false}
								/>
							</Card>
						</Col>
						<Col span={span}>
							<Card
								size="small"
								title={t('management.comments')}
								style={styles.card}
								extra={<Button style={styles.buttonCard} size="small" shape="round" icon={<PlusOutlined />} onClick={handleAddComment}></Button>}
							>
								<Form form={formComment} component={false}>
									<Table<Comment>
										components={{
											body: {
												cell: EditableCell,
											},
										}}
										rowKey={item => 'comment-' + item.id}
										loading={isloadingComments}
										key={`comments-table`}
										tableLayout="auto"
										size="small"
										dataSource={comments}
										columns={columnsMergedComment}
										pagination={false}
									/>
								</Form>
							</Card>
						</Col>
						<Col span={span}>
							<Card
								size="small"
								title={t('management.attachments')}
								style={styles.card}
								extra={<Button style={styles.buttonCard} size="small" shape="round" icon={<PlusOutlined />} onClick={handleAddAttachment}></Button>}
							>
								<Table<DocumentAttachment>
									rowKey={item => 'attachment' + item.id}
									loading={isloadingDocuments}
									key={`news-table`}
									tableLayout="auto"
									size="small"
									dataSource={attachments}
									columns={columnsDocumentAttachment}
									pagination={false}
								/>
							</Card>
						</Col>
					</Row>
				</Col>
			</div>
			<SizedModal
				size={url === '' && localUrl === '' ? 'small' : 'medium'}
				open={isModalVisible}
				title={title}
				okButtonProps={{
					shape: 'round',
					type: 'primary',
				}}
				okText={t('general.ok')}
				cancelText={t('general.cancel')}
				cancelButtonProps={{
					shape: 'round',
					type: 'primary',
				}}
				onOk={handleAttachmentSave}
				onCancel={handleCancel}
				destroyOnClose={true}
			>
				<Row gutter={[12, 12]}>
					<Col span={isViewerVisible ? 9 : 24}>
						<Form {...baseStyles.formLayout} form={formAttachment} name="attachment-details">
							<Form.Item label={t('general.description')} name="description" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('general.file')} name="upload" valuePropName="filelist" getValueFromEvent={getUploadedFile}>
								<div>
									<Dragger {...draggerProps}>
										<p className="ant-upload-drag-icon">
											<InboxOutlined />
										</p>
									</Dragger>
								</div>
							</Form.Item>
						</Form>
						{!isValidData ? <Alert message={t('messages.correctErrorsToContinue')} type="error" /> : null}
						{!isPDF ? <Alert message={t('messages.notPDF')} type="error" /> : null}
					</Col>
					{isViewerVisible ? (
						<Col span={15}>
							<DocumentViewer url={localUrl === '' ? url : localUrl} size="medium"></DocumentViewer>
						</Col>
					) : null}
				</Row>
			</SizedModal>
		</Col>
	);
};

export default FormTaskDetails;
