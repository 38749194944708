import { createContext, useContext, useState } from 'react';
import { useThemeDetector } from './useThemeDetector';

const ThemeContext = createContext({
	userTheme: 'white',
	setUserTheme: (value: string) => {},
});

export const useThemeContext = () => {
	const context = useContext(ThemeContext);
	if (!context) {
		throw new Error('useThemeContext must be used within a UserThemeProvider');
	}
	return context;
};

export const UserThemeProvider = props => {
	const themeDetector = useThemeDetector();
	const setUserTheme = (userTheme: any) => {
		setState({ ...state, userTheme: userTheme });
	};

	const initState = {
		userTheme: themeDetector ? 'black' : 'white',
		setUserTheme: setUserTheme,
	};

	const [state, setState] = useState(initState);

	return <ThemeContext.Provider value={state}>{props.children}</ThemeContext.Provider>;
};
