import { useEffect, useState } from 'react';
import { EventItem, EventType, ParentType, initialEventValue, ProfessionalType } from '../../store/types';
import { Input, DatePicker, Switch, Row, Col, Form, Select, AutoComplete, Card } from 'antd';
import { countriesSelectors, eventsSelectors, eventTypesSelectors, professionalTypeSelectors } from '../../store/selectors';
import { useStore } from 'react-redux';
import { convertHtmlToDraft, convertDraftToHtml } from '../../utils/util-drafts';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { baseStyles } from '../../assets/styles';
import { UploadDragPaste } from '..';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { objectsAreEquals } from '../../utils/util-custom-validators';
import { exportDateTimeFormat } from '../../assets/formats';

const { RangePicker } = DatePicker;
const { Option } = Select;

const FormEvent = ({ eventId, setEventData, setHasErrors, setChanged }) => {
	const { t } = useTranslation();
	const store = useStore();
	const id = eventId;
	const styles = baseStyles;
	const [formEvent] = Form.useForm();
	const [eventTypes, setEventTypes] = useState<EventType[]>([]);
	const [eventItem, setEventItem] = useState<EventItem>(initialEventValue);
	const [originalEventItem, setOriginalEventItem] = useState<EventItem>(initialEventValue);
	const [imageUrl, setImageUrl] = useState<string>('');
	const [editorState, setEditorState] = useState<EditorState>(convertHtmlToDraft(''));
	const [professionalTypes, setProfessionalTypes] = useState<ProfessionalType[]>([]);
	const [countryOptions, setCountryOptions] = useState<{ value: string }[]>([]);

	useEffect(() => {
		var professionalTypeItems = professionalTypeSelectors.selectAll(store.getState() as any);
		setProfessionalTypes([{ id: 0, code: 'All', description: 'Todos', isDeleted: false }, ...professionalTypeItems]);
		var countryItems = countriesSelectors.selectAll(store.getState() as any);
		setCountryOptions(
			countryItems.map(c => {
				return { value: c.description };
			}),
		);
		var eventTypeItems = eventTypesSelectors.selectAll(store.getState() as any);
		setEventTypes(eventTypeItems);
		var item = eventsSelectors.selectById(store.getState() as any, id) || initialEventValue;
		setEventItem(item);
		setOriginalEventItem(item);
		setEditorState(convertHtmlToDraft(item.summary));
		if (item.avatar !== '') setImageUrl(item.avatar);
		formEvent.setFieldsValue({
			professionalType: item.professionalTypeId || 0,
			title: item.title,
			eventType: item.eventTypeId || eventTypeItems[0].id,
			organizer: item.organizer,
			speaker: item.speaker,
			startEndDateTime: [dayjs(item.startDateTime || new Date()), dayjs(item.endDateTime || new Date())],
			isPublished: item.isPublished,
			location: item.location,
			country: countryItems.find(c => c.id === item.countryId)?.description || countryItems.find(c => c.code === globalThis.country)?.description,
			url: item.url,
		});
	}, [formEvent, id, store]);

	const setAvatarUrl = (url: string) => {
		setImageUrl(url);
		setEventItem({ ...eventItem, avatar: url });
		setEventData({ ...eventItem, avatar: url });
	};

	const handleEditorChange = (editorState: EditorState) => {
		var item: EventItem = { ...eventItem, summary: convertDraftToHtml(editorState) };
		setEditorState(editorState);
		setChanged(!objectsAreEquals(originalEventItem, item));
		setEventItem(item);
		setEventData(item);
	};

	const handleValuesChange = (changedValues: any) => {
		var item: EventItem = eventItem;
		if (changedValues['professionalType'] !== undefined) item = { ...eventItem, professionalTypeId: changedValues['professionalType'] };
		if (changedValues['eventType'] !== undefined) item = { ...eventItem, eventTypeId: changedValues['eventType'] };
		if (changedValues['title'] !== undefined) item = { ...eventItem, title: changedValues['title'] };
		if (changedValues['organizer'] !== undefined) item = { ...eventItem, organizer: changedValues['organizer'] };
		if (changedValues['speaker'] !== undefined) item = { ...eventItem, speaker: changedValues['speaker'] };
		if (changedValues['location'] !== undefined) item = { ...eventItem, location: changedValues['location'] };
		if (changedValues['country'] !== undefined) item = { ...eventItem, countryId: changedValues['country'] };
		if (changedValues['startEndDateTime'] !== undefined)
			item = {
				...eventItem,
				startDateTime: changedValues['startEndDateTime'][0].format(exportDateTimeFormat),
				endDateTime: changedValues['startEndDateTime'][1].format(exportDateTimeFormat),
			};
		if (changedValues['isPublished'] !== undefined) item = { ...eventItem, isPublished: changedValues['isPublished'] };
		if (changedValues['url'] !== undefined) item = { ...eventItem, url: changedValues['url'] };
		setChanged(!objectsAreEquals(originalEventItem, item));
		setEventItem(item);
		setEventData(item);
		setTimeout(() => {
			formEvent
				.validateFields()
				.then(() => {
					setHasErrors(false);
				})
				.catch(errors => {
					if (errors.errorFields.length > 0) setHasErrors(true);
				});
		});
	};

	return (
		<div>
			<Row gutter={[16, 16]}>
				<Col lg={24} xl={24} xxl={12}>
					<Card size="small" title={t('training.eventParameters')} style={styles.card}>
						<Form {...baseStyles.formLayout} form={formEvent} name="professional-details" onValuesChange={handleValuesChange}>
							<Form.Item label={t('general.professionalType')} name="professionalType" rules={[{ required: true }]}>
								<Select>
									{professionalTypes.map(p => {
										return (
											<Option key={`professionalType-${p.id}`} value={p.id}>
												{p.description}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
							<Form.Item label={t('training.eventType')} name="eventType" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Select>
									{eventTypes.map(et => {
										return (
											<Option key={`eventType-${et.id}`} value={et.id}>
												{et.description}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
							<Form.Item label={t('training.title')} name="title" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('training.organizer')} name="organizer" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('training.speaker')} name="speaker" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('training.location')} name="location" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('training.country')} name="country" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<AutoComplete
									filterOption={(inputValue: string, option: any) => {
										return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
									}}
									options={countryOptions}
								/>
							</Form.Item>
							<Form.Item label={t('training.startEndDate')} name="startEndDateTime" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<RangePicker showTime={{ format: 'HH:mm' }} format="DD-MM-YYYY HH:mm" />
							</Form.Item>
							<Form.Item label={t('training.isPublished')} name="isPublished" valuePropName="checked">
								<Switch />
							</Form.Item>
							<Form.Item label={t('training.url')} name="url" rules={[{ required: false }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('training.image')} valuePropName="fileList">
								<UploadDragPaste size="large" id={id} parentType={ParentType.eventItem} url={imageUrl} setUrl={setAvatarUrl} />
							</Form.Item>
						</Form>
					</Card>
				</Col>
				<Col lg={24} xl={24} xxl={12}>
					<Card size="small" title={t('training.eventSummary')} style={styles.card}>
						<Editor
							editorStyle={{ height: 500 }}
							editorState={editorState}
							toolbarClassName="toolbarClassName"
							wrapperClassName="wrapperClassName"
							editorClassName="editorClassName"
							onEditorStateChange={handleEditorChange}
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
export default FormEvent;
