import { StoreStateType} from '../mainStore';
import { createSelector } from '@reduxjs/toolkit';

const getLoaded = (state : StoreStateType) => state.dataSourceStatuses.loaded;
const getTotal = (state : StoreStateType) => state.dataSourceStatuses.total;
const getLastDataSourceLoaded = (state : StoreStateType) => state.dataSourceStatuses.lastDataSourceLoaded;

export const selectFetchingProgress = createSelector(
    [getLoaded, getTotal, getLastDataSourceLoaded],
    (loaded, total, lastDataSourcesLoaded) => {
        return {
            lastDataSourceLoaded: lastDataSourcesLoaded,  
            progress: Math.trunc(loaded/total*100)
        }
    }
)
