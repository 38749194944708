import { FunctionComponent, useState, useEffect } from 'react';
import { Typography, Modal, Col, Row } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { useTranslation } from 'react-i18next';

export interface SizedModalProps extends ModalProps {
	titleBackgroundColor?: string;
	size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | undefined;
	justify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between' | undefined;
	resizeToContent?: boolean;
}

const { Text } = Typography;

const SizedModal: FunctionComponent<SizedModalProps> = ({ size, titleBackgroundColor, resizeToContent, justify, children, ...props }) => {
	const [parentWidth, setParentWidth] = useState<number>(0);
	const { t } = useTranslation();
	var proportion: number = 0;
	switch (size) {
		case 'xsmall':
			proportion = 1 / 5;
			break;
		case 'small':
			proportion = 1 / 3;
			break;
		case 'medium':
			proportion = 1 / 2;
			break;
		case 'large':
			proportion = 3 / 4;
			break;
		case 'xlarge':
			proportion = 4 / 5;
			break;
	}

	useEffect(() => {
		var width = window.innerWidth * proportion;
		setParentWidth(width);
	}, [proportion]);

	return (
		<Modal
			title={
				<Col>
					<Row justify={justify}>
						<Text strong>{props.title}</Text>
					</Row>
				</Col>
			}
			forceRender
			getContainer={false}
			width={parentWidth}
			okButtonProps={{
				shape: 'round',
				type: 'primary',
			}}
			okText={t('general.ok')}
			cancelText={t('general.cancel')}
			cancelButtonProps={{
				shape: 'round',
				type: 'primary',
			}}
			{...props}
		>
			<div>{children}</div>
		</Modal>
	);
};

export default SizedModal;
