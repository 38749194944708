import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { DatePicker, Input, Card, Button, message, Form, Select, Row, Col, Typography, Space } from 'antd';
import { Professional, Gender, initialProfessionalValue, Country } from '../../store/types';
import { Editor, EditorState } from 'react-draft-wysiwyg';
import { DownCircleOutlined } from '@ant-design/icons';
import { dateFormat } from '../../assets/formats';
import { baseStyles } from '../../assets/styles';
import { SizedModal } from '..';
import { useTranslation } from 'react-i18next';
import { convertHtmlToDraft, convertDraftToHtml } from '../../utils/util-drafts';
import { isValidRut } from '../../utils/util-custom-validators';
import { getProfessionalData } from '../../store/api/professional';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/es_ES';

const { Option } = Select;
const { Search } = Input;
const { Text } = Typography;

const key = 'saving';

const FormRegistrationIdentification = () => {
	const store = useStore();
	const styles = baseStyles;
	const { t } = useTranslation();
	const [formRegistrationIdentification] = Form.useForm();
	const [modalValue, setModalValue] = useState<string>('');
	const [professional, setProfessional] = useState<Professional>(initialProfessionalValue);
	const [genders, setGenders] = useState<Gender[]>([]);
	const [countries, setCountries] = useState<Country[]>([]);
	const [editorState, setEditorState] = useState<EditorState>();
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [isErrorMessageVisible, setIsErrorMessageVisible] = useState<boolean>(false);

	useEffect(() => {
		setGenders([]);
		setCountries([]);
	}, [formRegistrationIdentification, store]);

	const handleDetailsChange = (editorState: EditorState) => {
		var item = professional;
		setEditorState(editorState);
		item = { ...professional, review: convertDraftToHtml(editorState) };
		setProfessional(item);
	};

	const handleShowModal = async () => {
		setIsModalVisible(true);
		setModalValue(professional.socialId);
	};

	const handleGetDataFromSupersalud = async value => {
		try {
			setIsModalVisible(false);
			message.loading({ content: t('messages.fetchingData'), key, duration: 0 });
			var result = await getProfessionalData(value);
			if (result !== undefined) {
				var name = result.nombre_completo.split(' ');
				formRegistrationIdentification.setFieldsValue({
					names: result.nombre_completo,
					lastName: name[name.length - 2],
					secondLastName: name[name.length - 1],
					gender: result.sexo,
					birthDate: dayjs(result.fecha_nacimiento),
					socialId: result.rut,
				});
				setProfessional({
					...professional,
					names: result.nombre_completo,
					lastName: name[name.length - 2],
					secondLastName: name[name.length - 1],
					gender: result.sexo,
					birthDate: dayjs(result.fecha_nacimiento).toString(),
					socialId: result.rut,
				});

				var review = convertDraftToHtml(editorState);
				review = '';
				result.especialidades.forEach(element => {
					review = review + '<p>' + element + '</p>';
				});
				review = review + '<p>N° de registro ' + result.numero_sis + '</p>';

				setEditorState(convertHtmlToDraft(review));
				message.success({ content: t('messages.dataFetched'), key, duration: 2 });
			} else message.error({ content: t('messages.errorOnTransaction'), key, duration: 2 });
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnTransaction'), key, duration: 2 });
		}
	};

	return (
		<div>
			<Row gutter={[16, 16]}>
				<Col lg={24} xl={12}>
					<Card
						size="small"
						title={t('professionals.details')}
						style={styles.card}
						extra={[
							<Space>
								<Button style={styles.buttonCard} type="primary" icon={<DownCircleOutlined />} shape="round" onClick={handleShowModal}>
									{t('professionals.getDataFromSuperSalud')}
								</Button>
							</Space>,
						]}
					>
						<Form {...baseStyles.formLayout} form={formRegistrationIdentification} name="professional-details">
							<Form.Item label={t('general.names')} name="names" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('general.lastname')} name="lastName" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('general.secondLastname')} name="secondLastName" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('general.birthdate')} name="birthDate" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<DatePicker locale={locale} format={dateFormat} />
							</Form.Item>
							<Form.Item label={t('general.gender')} name="gender" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Select>
									{genders.map(g => {
										return (
											<Option key={`gender-${g.id}`} value={g.id}>
												{g.description}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
							<Form.Item label={t('general.socialId')} name="socialId" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('professionals.countryOfNationality')} name="nationality" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Select
									showSearch
									allowClear
									optionFilterProp="children"
									filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
								>
									{countries.map(item => (
										<Option key={`country-${item.id}`} value={item.id}>
											{item.description}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Form>
					</Card>
				</Col>
				<Col lg={24} xl={12}>
					<Card size="small" title={t('professionals.review')} style={styles.card}>
						<Editor
							editorStyle={{ height: 500 }}
							editorState={editorState}
							toolbarClassName="toolbarClassName"
							wrapperClassName="wrapperClassName"
							editorClassName="editorClassName"
							onEditorStateChange={handleDetailsChange}
						/>
					</Card>
				</Col>
			</Row>
			<SizedModal
				title={t('professionals.getDataFromSuperSalud')}
				open={isModalVisible}
				okText={t('general.get')}
				cancelText={t('general.cancel')}
				onOk={() => {
					handleGetDataFromSupersalud(modalValue);
				}}
				onCancel={() => {
					setIsModalVisible(false);
				}}
				size="xsmall"
			>
				<Row align="middle">
					<Search
						addonBefore={t('general.socialId')}
						onSearch={value => {
							if (!isValidRut(value)) setIsModalVisible(false);
							else handleGetDataFromSupersalud(value);
						}}
						value={modalValue}
						onChange={event => {
							setModalValue(event.target.value);
							if (!isValidRut(event.target.value)) setIsErrorMessageVisible(true);
							else setIsErrorMessageVisible(false);
						}}
					/>
				</Row>
				<Row>
					{isErrorMessageVisible ? (
						<Col>
							<Text type="warning">{t('messages.enterValidValue')}</Text>
						</Col>
					) : null}
				</Row>
			</SizedModal>
		</div>
	);
};
export default FormRegistrationIdentification;
