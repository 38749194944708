import { useCallback, useEffect, useState } from 'react';
import { Center, initialCenterValue, ServiceNode } from '../../store/types';
import { useStore } from 'react-redux';
import { servicesSelectors, centersSelectors } from '../../store/selectors';
import { createServiceTree } from '../../store/api';
import { Row, Col, Tree, Card, Typography, AutoComplete, Space } from 'antd';
import { EntityId } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { baseStyles } from '../../assets/styles';
import debounce from 'lodash.debounce';
import { includes } from '../../utils/util-custom-validators';

const { Text } = Typography;

/**
 * Form to display a table of address
 */
const FormCenterServices = (props: { centerId: number; setCenterData: (data: Center) => void }) => {
	const { t } = useTranslation();
	const store = useStore();
	const state = store.getState() as any;
	const styles = baseStyles;
	const id = props.centerId;
	const [center, setCenter] = useState<Center>(initialCenterValue);
	const [serviceNodes, setServiceNodes] = useState<ServiceNode[]>([]);
	const [checkedKeys, setCheckedKeys] = useState<number[]>([]);

	useEffect(() => {
		var item = centersSelectors.selectById(state, id) || initialCenterValue;
		setCenter(item);
		var serviceItems = servicesSelectors.selectAll(state).filter(s => !s.isDeleted);
		setServiceNodes(createServiceTree(serviceItems));
		setCheckedKeys(item.services);
	}, [id, state]);

	const handleCheck = (checked: any) => {
		var result = checked.map((c: EntityId) => {
			return parseInt(c.toString());
		});
		setCenter({ ...center, services: result });
		props.setCenterData({ ...center, services: result });
		setCheckedKeys(result);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounceSearch = useCallback(
		debounce(value => {
			var items = servicesSelectors.selectAll(state).filter(ns => !ns.isDeleted && includes(ns.description, value)) || [];
			setServiceNodes(createServiceTree(items));
		}, 500),
		[],
	);

	const handleSearch = (value: string) => {
		if (!value) {
			setServiceNodes(createServiceTree(servicesSelectors.selectAll(state).filter(ns => !ns.isDeleted)) || []);
		}
		debounceSearch(value);
	};

	return (
		<Card size="small" title={t('network.services')}>
			<Row>
				<Col key="col1" flex={3}>
					<Space direction="horizontal">
						<Text strong>{t('general.searchEngine')}</Text>
						<AutoComplete
							popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
							style={styles.search}
							placeholder={t('general.search')}
							allowClear
							onSearch={handleSearch}
						/>
					</Space>
				</Col>
				<Col key="col2" flex={4}></Col>
				<Col key="col3" flex={3}></Col>
			</Row>
			<Row style={{ paddingTop: 10 }}>
				<Tree key={`services-tree-${Date.now()}`} treeData={serviceNodes} checkable onCheck={handleCheck} checkedKeys={checkedKeys} showLine showIcon />
			</Row>
		</Card>
	);
};

export default FormCenterServices;
