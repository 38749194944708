export enum ParentType {
    documentItem,
    newsItem,
    eventItem,
    linkItem,
    professional,
    center,
    institution,
    task
}

export interface DocumentAttachment {
    [key: string]: any;
    id: number;
    description: string;
    url: string;
    parentId: number;
    parentType: ParentType;
    isDeleted: boolean;
}

export const initialDocumentAttachmentValue: DocumentAttachment = {
    id: 0,
    description: "",
    url: "",
    parentId: 0,
    parentType: ParentType.documentItem,
    isDeleted: false,
};

export interface ImageAttachment {
    [key: string]: any;
    id: number;
    url: string;
    parentId: number;
    parentType: ParentType;
    isDeleted: boolean;
}

export const initialImageAttachmentValue: ImageAttachment = {
    id: 0,
    url: "",
    parentId: 0,
    parentType: ParentType.documentItem,
    isDeleted: false,
};
