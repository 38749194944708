import { useEffect, useState } from 'react';
import { DatePicker, Input, Form, Select, message } from 'antd';
import { useStore } from 'react-redux';
import { Institution, Accomplishment, initialAccomplishmentValue } from '../../store/types';
import { institutionsSelectors } from '../../store/selectors/domains';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { SizedModal } from '..';
import { accomplishments } from '../../store/reducers';
import { createUpdateAccomplishment } from '../../store/api';
import { accomplishmentSelectors } from '../../store/selectors';
import dayjs from 'dayjs';

const key = 'saving';
const { TextArea } = Input;
const { Option } = Select;

const FormProfessionalAccomplishmentEdit = (props: { professionalId: number; accomplishmentId: number; open: boolean; title: string; onClose: () => void }) => {
	const [formProfessionalAccomplishmentEdit] = Form.useForm();
	const store = useStore();
	const { t } = useTranslation();
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [accomplishment, setAccomplishment] = useState<Accomplishment>(initialAccomplishmentValue);
	const [institutions, setInstitutions] = useState<Institution[]>([]);

	useEffect(() => {
		var item = accomplishmentSelectors.selectById(store.getState() as any, props.accomplishmentId) || {
			...initialAccomplishmentValue,
			professionalId: props.professionalId,
		};
		setInstitutions(
			institutionsSelectors
				.selectAll(store.getState() as any)
				.filter(i => !i.isDeleted)
				.sort((a, b) => a.name.localeCompare(b.name)),
		);
		setAccomplishment(item);
		setIsVisible(props.open);
		formProfessionalAccomplishmentEdit.setFieldsValue({
			description: item.description,
			institution: item.institutionId,
			endDate: dayjs(item.endDate || Date.toString()),
			details: item.details,
		});
	}, [formProfessionalAccomplishmentEdit, props, store]);

	const handleCancel = () => {
		setAccomplishment(initialAccomplishmentValue);
		props.onClose();
	};

	const handleSave = async () => {
		try {
			var values = await formProfessionalAccomplishmentEdit.validateFields();
			var temp: Accomplishment = {
				...accomplishment,
				startDate: values['endDate'].toDate(),
				endDate: values['endDate'].toDate(),
				details: values['details'],
				description: values['description'],
				isActive: values['isActive'],
				institutionId: values['institution'],
			};
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			var result = await createUpdateAccomplishment(temp);
			if (result !== undefined) {
				store.dispatch(accomplishments.actions.accomplishmentCreateUpdate(result));
				message.success({ content: t('messages.dataSaved'), key, duration: 2 });
			} else message.info({ content: t('message.errorOnSave'), key, duration: 2 });
			props.onClose();
		} catch (errors) {}
	};

	return (
		<SizedModal size="small" title={props.title} open={isVisible} onOk={handleSave} onCancel={handleCancel}>
			<Form {...baseStyles.formLayout} form={formProfessionalAccomplishmentEdit} name="accomplishment-details">
				<Form.Item label={t('general.description')} name="description" rules={[{ required: true, message: t('messages.enterValue') }]}>
					<Input placeholder="Ej: Perfeccionamiento en Piso Pélvico" />
				</Form.Item>
				<Form.Item label={t('professionals.institution')} name="institution" rules={[{ required: true, message: t('messages.enterValue') }]}>
					<Select
						showSearch
						allowClear
						optionFilterProp="children"
						filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
					>
						{institutions.map(item => (
							<Option key={`institution-${item.id}`} value={item.id}>
								{item.description}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label={t('professionals.endDate')} name="endDate" rules={[{ required: true, message: t('messages.enterValue') }]}>
					<DatePicker picker="year" />
				</Form.Item>
				<Form.Item label={t('professionals.details')} name="details" rules={[{ required: false }]}>
					<TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
				</Form.Item>
			</Form>
		</SizedModal>
	);
};
export default FormProfessionalAccomplishmentEdit;
