import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { Activity, Task, Comment, Agreement, Case, Person } from '../types';

export const activitiesAdapter = createEntityAdapter({
	selectId: (item: Activity) => item.id,
});

export const activities = createSlice({
	name: 'activities',
	initialState: activitiesAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		activitiesAdded: activitiesAdapter.addOne,
		activitiesLoading: state => {
			if (!state.loading) state.loading = true;
		},
		activitiesReceived: (state, action) => {
			if (state.loading) {
				activitiesAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		activitiesCreateUpdate: activitiesAdapter.upsertOne,
	},
});

export const casesAdapter = createEntityAdapter({
	selectId: (item: Case) => item.id,
});

export const cases = createSlice({
	name: 'cases',
	initialState: casesAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		casesAdded: casesAdapter.addOne,
		casesLoading: state => {
			if (!state.loading) state.loading = true;
		},
		casesReceived: (state, action) => {
			if (state.loading) {
				casesAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		casesCreateUpdate: casesAdapter.upsertOne,
	},
});

export const personsAdapter = createEntityAdapter({
	selectId: (item: Person) => item.id,
});

export const persons = createSlice({
	name: 'persons',
	initialState: personsAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		personsAdded: personsAdapter.addOne,
		personsLoading: state => {
			if (!state.loading) state.loading = true;
		},
		personsReceived: (state, action) => {
			if (state.loading) {
				personsAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		personsCreateUpdate: personsAdapter.upsertOne,
	},
});

export const tasksAdapter = createEntityAdapter({
	selectId: (item: Task) => item.id,
});

export const tasks = createSlice({
	name: 'tasks',
	initialState: tasksAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		taskssAdded: tasksAdapter.addOne,
		tasksLoading: state => {
			if (!state.loading) state.loading = true;
		},
		tasksReceived: (state, action) => {
			if (state.loading) {
				tasksAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		tasksCreateUpdate: tasksAdapter.upsertOne,
	},
});

export const commmentsAdapter = createEntityAdapter({
	selectId: (item: Comment) => item.id,
});

export const comments = createSlice({
	name: 'comments',
	initialState: commmentsAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		commmentsAdded: commmentsAdapter.addOne,
		commmentsLoading: state => {
			if (!state.loading) state.loading = true;
		},
		commmentsReceived: (state, action) => {
			if (state.loading) {
				commmentsAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		commmentsCreateUpdate: commmentsAdapter.upsertOne,
	},
});

export const agreementsAdapter = createEntityAdapter({
	selectId: (item: Agreement) => item.id,
});

export const agreements = createSlice({
	name: 'agreements',
	initialState: agreementsAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		agreementsAdded: agreementsAdapter.addOne,
		agreementsLoading: state => {
			if (!state.loading) state.loading = true;
		},
		agreementsReceived: (state, action) => {
			if (state.loading) {
				agreementsAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		agreementsCreateUpdate: agreementsAdapter.upsertOne,
	},
});
