import dayjs from "dayjs";

//#region Event
export interface EventItem {
  [key: string]: any;
  id: number;
  professionalTypeId: number;
  eventTypeId: number;
  title: string;
  avatar: string;
  organizer: string;
  speaker: string;
  location: string;
  countryId: number;
  summary: string;
  startDateTime: string;
  endDateTime: string;
  url: string;
  isPublished: boolean;
  tags: string[];
  enrolleds: number[],
  isDeleted: boolean;
}

export const initialEventValue: EventItem = {
  id: 0,
  professionalTypeId: 0,
  eventTypeId: 1,
  title: "",
  avatar: "",
  organizer: "",
  speaker: "",
  location: "",
  countryId: 0,
  summary: "",
  startDateTime: dayjs().format('YYYY/MM/DD'),
  endDateTime: dayjs().format('YYYY/MM/DD'),
  url: "",
  isPublished: false,
  tags: [],
  enrolleds: [],
  isDeleted: false,
};

export interface LinkItem {
  [key: string]: any;
  id: number;
  professionalTypeId: number;
  title: string;
  avatar: string;
  summary: string;
  publishDate: string;
  url: string;
  isPublished: boolean;
  tags: string[];
  isDeleted: boolean;
}

export const initialLinkItemValue : LinkItem = {
    id: 0,
    professionalTypeId: 0,
    title: "",
    avatar: "",
    summary: "",
    publishDate: dayjs().format('YYYY/MM/DD'),
    url: "",
    isPublished: false,
    tags: [],
    isDeleted: false,
};
