import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { ActivityType, Priority, TaskStatus, TaskType } from '../types';
import {
	Country,
	Region,
	Province,
	Commune,
	Gender,
	PhoneNumberType,
	EmailType,
	AddressType,
	AccreditationStatus,
	AccreditationDocumentType,
	RelationType,
	ProfessionalType,
	SpecialtyType,
	CenterType,
	InfrastructureType,
	EquipmentType,
	EquipmentStatus,
	CategoryType,
	PrivilegeStatus,
	Tag,
	EventType,
	Institution,
	ProfessionalDocumentType,
	AccreditationDocumentStatus,
	CaseType,
	ProfessionalMilestoneType,
	PrivilegeType,
} from '../types/domains';

//#region Countries
export const countriesAdapter = createEntityAdapter({
	selectId: (item: Country) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const countries = createSlice({
	name: 'countries',
	initialState: countriesAdapter.getInitialState({
		loading: 'idle',
	}),
	reducers: {
		countryAdded: countriesAdapter.addOne,
		countriesLoading: state => {
			if (state.loading === 'idle') state.loading = 'loading';
		},
		countriesReceived: (state, action) => {
			if (state.loading === 'loading') {
				countriesAdapter.setAll(state, action.payload);
				state.loading = 'idle';
			}
		},
	},
});
//#endregion

//#region Regions
export const regionsAdapter = createEntityAdapter({
	selectId: (item: Region) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const regions = createSlice({
	name: 'regions',
	initialState: regionsAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		regionAdded: regionsAdapter.addOne,
		regionsLoading: state => {
			if (!state.loading) state.loading = true;
		},
		regionsReceived: (state, action) => {
			if (state.loading) {
				regionsAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Provinces
export const provincesAdapter = createEntityAdapter({
	selectId: (item: Province) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const provinces = createSlice({
	name: 'provinces',
	initialState: provincesAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		provinceAdded: provincesAdapter.addOne,
		provincesLoading: state => {
			if (!state.loading) state.loading = true;
		},
		provincesReceived: (state, action) => {
			if (state.loading) {
				provincesAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Communes
export const communesAdapter = createEntityAdapter({
	selectId: (item: Commune) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const communes = createSlice({
	name: 'communes',
	initialState: communesAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		communeAdded: communesAdapter.addOne,
		communesLoading: state => {
			if (!state.loading) state.loading = true;
		},
		communesReceived: (state, action) => {
			if (state.loading) {
				communesAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Genders
export const genderAdapter = createEntityAdapter({
	selectId: (item: Gender) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const genders = createSlice({
	name: 'genders',
	initialState: genderAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		genderAdded: genderAdapter.addOne,
		genderLoading: state => {
			if (!state.loading) state.loading = true;
		},
		genderReceived: (state, action) => {
			if (state.loading) {
				genderAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region PhoneNumberType
export const phoneNumberTypeAdapter = createEntityAdapter({
	selectId: (item: PhoneNumberType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const phoneNumberTypes = createSlice({
	name: 'phoneNumberTypes',
	initialState: phoneNumberTypeAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		phoneNumberAdded: phoneNumberTypeAdapter.addOne,
		phoneNumberTypeLoading: state => {
			if (!state.loading) state.loading = true;
		},
		phoneNumberTypeReceived: (state, action) => {
			if (state.loading) {
				phoneNumberTypeAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Email Types
export const emailTypeAdapter = createEntityAdapter({
	selectId: (item: EmailType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const emailTypes = createSlice({
	name: 'emailTypes',
	initialState: emailTypeAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		emailTypeAdded: emailTypeAdapter.addOne,
		emailTypesLoading: state => {
			if (!state.loading) state.loading = true;
		},
		emailTypeReceived: (state, action) => {
			if (state.loading) {
				emailTypeAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Address Types
export const addressTypeAdapter = createEntityAdapter({
	selectId: (item: AddressType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const addressTypes = createSlice({
	name: 'addressTypes',
	initialState: addressTypeAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		addressTypeAdded: addressTypeAdapter.addOne,
		addressTypeLoading: state => {
			if (!state.loading) state.loading = true;
		},
		addressTypeReceived: (state, action) => {
			if (state.loading) {
				addressTypeAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Accreditation Status
export const accreditationStatusAdapter = createEntityAdapter({
	selectId: (item: AccreditationStatus) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const accreditationStatuses = createSlice({
	name: 'accreditationStatuses',
	initialState: accreditationStatusAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		accreditationStatusAdded: accreditationStatusAdapter.addOne,
		accreditationStatusLoading: state => {
			if (!state.loading) state.loading = true;
		},
		accreditationStatusReceived: (state, action) => {
			if (state.loading) {
				accreditationStatusAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Accreditation Document Type
export const accreditationDocumentTypeAdapter = createEntityAdapter({
	selectId: (item: AccreditationDocumentType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const accreditationDocumentTypes = createSlice({
	name: 'accreditationDocumentTypes',
	initialState: accreditationDocumentTypeAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		accreditationDocumentTypeAdded: accreditationDocumentTypeAdapter.addOne,
		accreditationDocumentTypeLoading: state => {
			if (!state.loading) state.loading = true;
		},
		accreditationDocumentTypeReceived: (state, action) => {
			if (state.loading) {
				accreditationDocumentTypeAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Professional Document Type
export const professionalDocumentTypeAdapter = createEntityAdapter({
	selectId: (item: ProfessionalDocumentType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const professionalDocumentTypes = createSlice({
	name: 'professionalDocumentTypes',
	initialState: professionalDocumentTypeAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		professionalDocumentTypeAdded: professionalDocumentTypeAdapter.addOne,
		professionalDocumentTypeLoading: state => {
			if (!state.loading) state.loading = true;
		},
		professionalDocumentTypeReceived: (state, action) => {
			if (state.loading) {
				professionalDocumentTypeAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Professional Document Type
export const professionalMilestoneTypeAdapter = createEntityAdapter({
	selectId: (item: ProfessionalMilestoneType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const professionalMilestoneTypes = createSlice({
	name: 'professionalMilestoneTypes',
	initialState: professionalMilestoneTypeAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		professionalMilestoneTypeAdded: professionalMilestoneTypeAdapter.addOne,
		professionaMilestoneTypeLoading: state => {
			if (!state.loading) state.loading = true;
		},
		professionaMilestoneTypeReceived: (state, action) => {
			if (state.loading) {
				professionalMilestoneTypeAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Professional Document Status
export const accreditationDocumentStatusAdapter = createEntityAdapter({
	selectId: (item: AccreditationDocumentStatus) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const accreditationDocumentStatuses = createSlice({
	name: 'accreditationDocumentStatuses',
	initialState: accreditationDocumentStatusAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		accreditationDocumentStatusAdded: accreditationDocumentStatusAdapter.addOne,
		accreditationDocumentStatusLoading: state => {
			if (!state.loading) state.loading = true;
		},
		accreditationDocumentStatusReceived: (state, action) => {
			if (state.loading) {
				accreditationDocumentStatusAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Relation Type
export const relationTypeAdapter = createEntityAdapter({
	selectId: (item: RelationType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const relationTypes = createSlice({
	name: 'relationTypes',
	initialState: relationTypeAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		relationTypesAdded: relationTypeAdapter.addOne,
		relationTypesLoading: state => {
			if (!state.loading) state.loading = true;
		},
		relationTypesReceived: (state, action) => {
			if (state.loading) {
				relationTypeAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Professional Type
export const professionalTypeAdapter = createEntityAdapter({
	selectId: (item: ProfessionalType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const professionalTypes = createSlice({
	name: 'professionalTypes',
	initialState: professionalTypeAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		professionalTypeAdded: professionalTypeAdapter.addOne,
		professionalTypeLoading: state => {
			if (!state.loading) state.loading = true;
		},
		professionalTypeReceived: (state, action) => {
			if (state.loading) {
				professionalTypeAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Specialty Type
export const specialtyTypeAdapter = createEntityAdapter({
	selectId: (item: SpecialtyType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const specialtyTypes = createSlice({
	name: 'specialties',
	initialState: specialtyTypeAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		specialtyTypeAdded: specialtyTypeAdapter.addOne,
		specialtyTypeLoading: state => {
			if (!state.loading) state.loading = true;
		},
		specialtyTypeReceived: (state, action) => {
			if (state.loading) {
				specialtyTypeAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Category Types
export const categoryTypeAdapter = createEntityAdapter({
	selectId: (item: CategoryType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const categoryTypes = createSlice({
	name: 'categoryTypes',
	initialState: categoryTypeAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		categoryTypeAdded: categoryTypeAdapter.addOne,
		categoryTypeLoading: state => {
			if (!state.loading) state.loading = true;
		},
		categoryTypeReceived: (state, action) => {
			if (state.loading) {
				categoryTypeAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region EventType
export const eventTypeAdapter = createEntityAdapter({
	selectId: (item: EventType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const eventTypes = createSlice({
	name: 'eventTypes',
	initialState: eventTypeAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		eventTypeAdded: eventTypeAdapter.addOne,
		eventTypeLoading: state => {
			if (!state.loading) state.loading = true;
		},
		eventTypeReceived: (state, action) => {
			if (state.loading) {
				eventTypeAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region ServiceType
export const centerTypeAdapter = createEntityAdapter({
	selectId: (item: CenterType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const centerTypes = createSlice({
	name: 'centerTypes',
	initialState: centerTypeAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		centerTypeAdded: centerTypeAdapter.addOne,
		centerTypeLoading: state => {
			if (!state.loading) state.loading = true;
		},
		centerTypeReceived: (state, action) => {
			if (state.loading) {
				centerTypeAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Infrastructure Type
export const infrastructureTypeAdapter = createEntityAdapter({
	selectId: (item: InfrastructureType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const infrastructureTypes = createSlice({
	name: 'infrastructureTypes',
	initialState: infrastructureTypeAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		infrastructureTypeAdded: infrastructureTypeAdapter.addOne,
		infrastructureTypeLoading: state => {
			if (!state.loading) state.loading = true;
		},
		infrastructureTypeReceived: (state, action) => {
			if (state.loading) {
				infrastructureTypeAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Infrastructure Statuses
export const equipmentStatusAdapter = createEntityAdapter({
	selectId: (item: EquipmentStatus) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});
export const equipmentStatuses = createSlice({
	name: 'equipmentStatuses',
	initialState: equipmentStatusAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		equipmentStatusAdded: equipmentStatusAdapter.addOne,
		equipmentStatusLoading: state => {
			if (!state.loading) state.loading = true;
		},
		equipmentStatusReceived: (state, action) => {
			if (state.loading) {
				equipmentStatusAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Privilege Types
export const privilegeTypeAdapter = createEntityAdapter({
	selectId: (item: PrivilegeType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const privilegeTypes = createSlice({
	name: 'privilegeTypes',
	initialState: privilegeTypeAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		privilegeTypeAdded: privilegeTypeAdapter.addOne,
		privilegeTypeLoading: state => {
			if (!state.loading) state.loading = true;
		},
		privilegeTypeReceived: (state, action) => {
			if (state.loading) {
				privilegeTypeAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Privilege Status
export const privilegeStatusAdapter = createEntityAdapter({
	selectId: (item: PrivilegeStatus) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const privilegeStatuses = createSlice({
	name: 'privilegeStatuses',
	initialState: privilegeStatusAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		privilegeStatusAdded: privilegeStatusAdapter.addOne,
		privilegeStatusLoading: state => {
			if (!state.loading) state.loading = true;
		},
		privilegeStatusReceived: (state, action) => {
			if (state.loading) {
				privilegeStatusAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Equipment Type
export const equipmentTypeAdapter = createEntityAdapter({
	selectId: (item: EquipmentType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const equipmentTypes = createSlice({
	name: 'equipmentTypes',
	initialState: equipmentTypeAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		equipmentTypeAdded: equipmentTypeAdapter.addOne,
		equipmentTypeLoading: state => {
			if (!state.loading) state.loading = true;
		},
		equipmentTypeReceived: (state, action) => {
			if (state.loading) {
				equipmentTypeAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
//#endregion

//#region Tags
export const tagAdapter = createEntityAdapter({
	selectId: (item: Tag) => item.id,
});

export const tags = createSlice({
	name: 'tags',
	initialState: tagAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		tagAdded: tagAdapter.addOne,
		tagLoading: state => {
			if (!state.loading) state.loading = true;
		},
		tagReceived: (state, action) => {
			if (state.loading) {
				tagAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		tagCreateUpdate: tagAdapter.upsertOne,
	},
});
//#endregion

//#region Institutions
export const institutionAdapter = createEntityAdapter({
	selectId: (item: Institution) => item.id,
});

export const institutions = createSlice({
	name: 'institutions',
	initialState: institutionAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		institutionAdded: institutionAdapter.addOne,
		institutionLoading: state => {
			if (!state.loading) state.loading = true;
		},
		institutionReceived: (state, action) => {
			if (state.loading) {
				institutionAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		institutionCreateUpdate: institutionAdapter.upsertOne,
	},
});
//#endregion

export const taskStatusesAdapter = createEntityAdapter({
	selectId: (item: TaskStatus) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const taskStatuses = createSlice({
	name: 'taskStatuses',
	initialState: taskStatusesAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		taskStatusesAdded: taskStatusesAdapter.addOne,
		taskStatusesLoading: state => {
			if (!state.loading) state.loading = true;
		},
		taskStatusesReceived: (state, action) => {
			if (state.loading) {
				taskStatusesAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});

export const taskTypesAdapter = createEntityAdapter({
	selectId: (item: TaskType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const taskTypes = createSlice({
	name: 'taskTypes',
	initialState: taskTypesAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		taskTypesAdded: taskTypesAdapter.addOne,
		taskTypesLoading: state => {
			if (!state.loading) state.loading = true;
		},
		taskTypesReceived: (state, action) => {
			if (state.loading) {
				taskTypesAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});

export const activityTypesAdapter = createEntityAdapter({
	selectId: (item: ActivityType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const activityTypes = createSlice({
	name: 'activityTypes',
	initialState: activityTypesAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		activityTypesAdded: activityTypesAdapter.addOne,
		activityTypesLoading: state => {
			if (!state.loading) state.loading = true;
		},
		activityTypesReceived: (state, action) => {
			if (state.loading) {
				activityTypesAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});

export const caseTypesAdapter = createEntityAdapter({
	selectId: (item: CaseType) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const caseTypes = createSlice({
	name: 'caseTypes',
	initialState: caseTypesAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		caseTypesAdded: caseTypesAdapter.addOne,
		caseTypesLoading: state => {
			if (!state.loading) state.loading = true;
		},
		caseTypesReceived: (state, action) => {
			if (state.loading) {
				caseTypesAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});

export const prioritiesAdapter = createEntityAdapter({
	selectId: (item: Priority) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const priorities = createSlice({
	name: 'priorities',
	initialState: prioritiesAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		prioritiesAdded: prioritiesAdapter.addOne,
		prioritiesLoading: state => {
			if (!state.loading) state.loading = true;
		},
		prioritiesReceived: (state, action) => {
			if (state.loading) {
				prioritiesAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
	},
});
