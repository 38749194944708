import { useState, ReactNode, useEffect } from 'react';
import { useStore } from 'react-redux';
import { Table, Button, Space, Tooltip, Typography, Popconfirm, message, Card, Popover } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormProfessionalAccreditationDocumentEdit } from '../../components';
import { accreditationDocumentTypeSelectors, accreditationDocumentsSelectors, professionalsSelectors } from '../../store/selectors';
import {
	AccreditationDocument,
	AccreditationDocumentStatus,
	AccreditationDocumentType,
	initialAccreditationDocumentValue,
	initialProfessionalValue,
	Professional,
} from '../../store/types';
import { EditOutlined, DeleteOutlined, PlusOutlined, ZoomInOutlined, DownloadOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { deleteAccreditationDocument, fetchAccreditacionDocumentsById, urls } from '../../store/api';
import { accreditationDocuments as accreditationDocumentsReducer } from '../../store/reducers';
import { dateFormat } from '../../assets/formats';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { accreditationDocumentStatusSelectors } from '../../store/selectors/domains';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import { isAuthorized } from '../../utils/util-authentication';

const { Text } = Typography;
const key = 'saving';

const FormProfessionalSummary = ({ professionalId, professionalType }) => {
	const store = useStore();
	const styles = baseStyles;
	const { t } = useTranslation();
	const [professional, setProfessional] = useState<Professional>(initialProfessionalValue);
	const [accreditationDocumentTypes, setAccreditationDocumentTypes] = useState<AccreditationDocumentType[]>([]);
	const [accreditationDocumentStatuses, setAccreditationDocumentStatuses] = useState<AccreditationDocumentStatus[]>([]);
	const [accreditationDocuments, setAccreditationDocuments] = useState<AccreditationDocument[]>([]);
	const [accreditationDocument, setAccreditationDocument] = useState<AccreditationDocument>({
		...initialAccreditationDocumentValue,
		professionalId: professionalId,
	});
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [showProperties, setShowProperties] = useState(false);
	const [modalTitle, setModalTitle] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsLoading(true);
		setProfessional(professionalsSelectors.selectById(store.getState() as any, professionalId));
		setAccreditationDocumentTypes(accreditationDocumentTypeSelectors.selectAll(store.getState() as any));
		setAccreditationDocumentStatuses(accreditationDocumentStatusSelectors.selectAll(store.getState() as any));
		fetchAccreditacionDocumentsById(professionalId).then(items => {
			store.dispatch(accreditationDocumentsReducer.actions.accreditationsDocumentLoading());
			store.dispatch(accreditationDocumentsReducer.actions.accreditationsDocumentReceived(items));
			setAccreditationDocuments(items.filter(ad => !ad.isDeleted).sort((a, b) => dayjs(b.documentDate).diff(dayjs(a.documentDate))));
			setIsLoading(false);
		});
	}, [professionalId, store]);

	const handleEditDocument = (item: AccreditationDocument) => {
		setModalTitle(t('professionals.editDocument'));
		setAccreditationDocument(item);
		setIsModalVisible(true);
	};

	const handleAddDocument = () => {
		setModalTitle(t('professionals.addDocument'));
		setAccreditationDocument({ ...initialAccreditationDocumentValue, professionalId: professionalId });
		setIsModalVisible(true);
	};

	const handleDeleteDocument = async (item: AccreditationDocument) => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var response = await deleteAccreditationDocument(item);
		if (response) {
			setIsLoading(true);
			fetchAccreditacionDocumentsById(professionalId).then(items => {
				store.dispatch(accreditationDocumentsReducer.actions.accreditationsDocumentLoading());
				store.dispatch(accreditationDocumentsReducer.actions.accreditationsDocumentReceived(items));
				setAccreditationDocuments(items.filter(ad => !ad.isDeleted).sort((a, b) => dayjs(b.documentDate).diff(dayjs(a.documentDate))));
				setIsLoading(false);
				message.success({ content: t('messages.itemDeleted'), key, duration: 2 });
			});
		} else message.error({ content: t('message.unableToDelete'), key, duration: 2 });
	};

	const handleClose = () => {
		setIsModalVisible(false);
		setAccreditationDocuments(
			accreditationDocumentsSelectors
				.selectAll(store.getState() as any)
				.filter(ad => !ad.isDeleted && ad.professionalId === professionalId)
				.sort((a, b) => dayjs(b.documentDate).diff(dayjs(a.documentDate))),
		);
		setAccreditationDocument(undefined);
	};

	const columns: ColumnProps<AccreditationDocument>[] = [
		{
			dataIndex: 'modifiedBy',
			key: 'modifiedBy',
			width: 20,
			render: (_text: string, item: AccreditationDocument): ReactNode => (
				<Popover
					content={
						<div>
							<Text>{t('general.modifiedBy')} </Text>
							<Text type="warning">{item.modifiedBy}</Text>
						</div>
					}
				>
					<InfoCircleTwoTone />
				</Popover>
			),
		},
		{
			title: t('general.type'),
			dataIndex: 'documentType',
			key: 'documentType',
			width: 200,
			render: (_text: string, item: AccreditationDocument): ReactNode => (
				<Text>{accreditationDocumentTypes?.find(d => d.id === item.documentTypeId)?.description}</Text>
			),
		},
		{
			title: t('general.status'),
			dataIndex: 'documentStatus',
			key: 'documentStatus',
			width: 100,
			render: (_text: string, item: AccreditationDocument): ReactNode => (
				<Text>{accreditationDocumentStatuses?.find(d => d.id === item.documentStatusId)?.description}</Text>
			),
		},
		{
			title: t('professionals.documentUploadDate'),
			dataIndex: 'documentUploadDateTime',
			key: 'documentUploadDateTime',
			width: 100,
			render: (_text: string, item: AccreditationDocument): ReactNode => <Text>{dayjs(item.documentUploadDateTime).format(dateFormat)}</Text>,
		},
		{
			title: t('professionals.expirationDate'),
			dataIndex: 'documentExpirationDate',
			key: 'documentExpirationDate',
			width: 100,
			render: (_text: string, item: AccreditationDocument): ReactNode => (
				<Text>{dayjs(item.documentExpirationDate).year() >= 1980 ? dayjs(item.documentExpirationDate).format(dateFormat) : ''}</Text>
			),
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: AccreditationDocument): ReactNode => {
				return !isAuthorized('Component_AcreditationDocuments') ? null : (
					<Space size="small">
						<Tooltip title={t('general.edit')}>
							<Button
								style={styles.button}
								type="primary"
								size="small"
								shape="round"
								onClick={() => {
									setShowProperties(true);
									handleEditDocument(item);
								}}
							>
								<EditOutlined />
							</Button>
						</Tooltip>
						<Tooltip title={t('general.view')}>
							<Button
								style={styles.button}
								type="primary"
								size="small"
								shape="round"
								onClick={() => {
									setShowProperties(false);
									handleEditDocument(item);
								}}
							>
								<ZoomInOutlined />
							</Button>
						</Tooltip>
						<Tooltip title={t('general.download')}>
							<Button
								style={styles.button}
								type="primary"
								size="small"
								shape="round"
								onClick={() => {
									FileSaver.saveAs(
										`${urls.getDocument}?uri=${item.url}`,
										`${professional.socialId} - ${accreditationDocumentTypes.find(d => d.id === item.documentTypeId).description}.pdf`,
									);
								}}
							>
								<DownloadOutlined />
							</Button>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteDocument(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button size="small" danger shape="round" icon={<DeleteOutlined />} />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<Card
			size="small"
			title={t('professionals.accreditationDocuments')}
			style={styles.card}
			extra={[
				!isAuthorized('Component_AcreditationDocuments') ? null : (
					<Button
						type="primary"
						shape="round"
						icon={<PlusOutlined />}
						style={styles.buttonCard}
						onClick={() => {
							setShowProperties(true);
							handleAddDocument();
						}}
					></Button>
				),
			]}
		>
			<Table<AccreditationDocument>
				key={`professionalAccreditationDocuments`}
				rowKey={item => item.id}
				tableLayout="auto"
				size="small"
				dataSource={accreditationDocuments}
				columns={columns}
				pagination={false}
				loading={isLoading}
			/>
			{accreditationDocument ? (
				<FormProfessionalAccreditationDocumentEdit
					title={modalTitle}
					open={isModalVisible}
					showProperties={showProperties}
					documentId={accreditationDocument.id}
					professionalId={professionalId}
					professionalType={professionalType}
					onClose={handleClose}
				/>
			) : null}
		</Card>
	);
};
export default FormProfessionalSummary;
