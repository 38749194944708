import { ReactNode, useEffect, useState } from 'react';
import { Tree, Space, Button, Row, Col, message, Menu, Card, Typography, AutoComplete } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { infrastructuresSelectors } from '../../store/selectors';
import { infrastructures as infrastructuresReducer } from '../../store/reducers';
import { SizedModal, FormInfrastructureEdit } from '../../components';
import { createInfrastructureTree, createUpdateInfrastructure, deleteInfrastructure } from '../../store/api';
import { initialInfrastructureValue, Infrastructure, InfrastructureNode } from '../../store/types/network';
import { useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { baseStyles } from '../../assets/styles';

const { Text } = Typography;
const key = 'saving';

const FormInfrastructureDetails = (props: { centerId: number }) => {
	const { t } = useTranslation();
	const store = useStore();
	const state = store.getState() as any;
	const styles = baseStyles;
	const id = props.centerId;
	const [infrastructure, setInfrastructure] = useState<Infrastructure>(initialInfrastructureValue);
	const [infrastructureId, setInfrastructureId] = useState<number>(0);
	const [infrastructures, setInfrastructures] = useState<Infrastructure[]>([]);
	const [modalTitle, setModalTitle] = useState<string>('');
	const [isValidData, setIsValidData] = useState<boolean>(true);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [component, setComponent] = useState<ReactNode>();
	const [infrastructureNodes, setInfrastructureNodes] = useState<InfrastructureNode[]>([]);
	const [contextMenuVisible, setContextMenuVisible] = useState<boolean>(false);
	const [positionX, setPositionX] = useState<number>(0);
	const [positionY, setPositionY] = useState<number>(0);

	useEffect(() => {
		var items = infrastructuresSelectors.selectAll(state).filter(i => !i.isDeleted && i.centerId === id);
		setInfrastructures(items);
		setInfrastructureNodes(createInfrastructureTree(items));
	}, [id, state]);

	/**
	 * Add new infrastructure
	 */
	const handleAdd = () => {
		setModalTitle('Agregar Infraestructura');
		setComponent(
			<FormInfrastructureEdit
				key={`editInfrastructure-${Date.now()}`}
				infrastructureId={0}
				setIsValidData={setIsValidData}
				setInfrastructureData={setInfrastructure}
			/>,
		);
		setIsModalVisible(true);
	};

	/**
	 * Edit infrastructure
	 */
	const handleEdit = () => {
		setModalTitle('Editar Infraestructura');
		setComponent(
			<FormInfrastructureEdit
				key={`editInfrastructure-${Date.now()}`}
				infrastructureId={infrastructureId}
				setIsValidData={setIsValidData}
				setInfrastructureData={setInfrastructure}
			/>,
		);
		setIsModalVisible(true);
	};

	/**
	 * Handle right click to show context menu
	 * @param info
	 */
	const handleRightClick = (info: any) => {
		setInfrastructureId(info.node.key);
		setPositionX(info.event.pageX);
		setPositionY(info.event.pageY);
		setContextMenuVisible(true);
	};

	const handleOnDrop = async (info: any) => {
		const dragKey = info.dragNode.props.eventKey;
		const dropKey = info.node.props.eventKey;
		const dropToGap = info.dropToGap;
		var childNode: Infrastructure | undefined = infrastructures.find(s => s.id === dragKey);
		if (!dropToGap) {
			const parentNode = infrastructures.find(s => s.id === dropKey);
			if (childNode !== undefined) {
				childNode = { ...childNode, parentId: parentNode?.id || 0 };
				var result = await createUpdateInfrastructure(childNode);
				if (result !== undefined) {
					store.dispatch(infrastructuresReducer.actions.infrastructureCreateUpdate(result));
					setInfrastructures(infrastructuresSelectors.selectAll(state).filter(c => !c.isDeleted));
					setInfrastructureNodes(createInfrastructureTree(infrastructuresSelectors.selectAll(state).filter(c => !c.isDeleted)));
					message.success({ content: t('messages.dataSaved'), key, duration: 2 });
				} else message.info('Error al editar el dato');
				setComponent(null);
			}
		}
	};

	const handleOk = async () => {
		if (!isValidData) return;
		setIsModalVisible(false);
		var result = await createUpdateInfrastructure({ ...infrastructure, centerId: props.centerId });
		if (result !== undefined) {
			store.dispatch(infrastructuresReducer.actions.infrastructureCreateUpdate(result));
			var infs: Infrastructure[] = infrastructuresSelectors.selectAll(state).filter(i => !i.isDeleted && i.centerId === props.centerId);
			setInfrastructures(infs);
			setInfrastructureNodes(createInfrastructureTree(infs));
		} else message.info('Error al editar el dato');
		setComponent(null);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		setComponent(null);
	};

	const handleDelete = async () => {
		var infrastructure = infrastructures.find(s => s.id === infrastructureId) || initialInfrastructureValue;
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var result = await deleteInfrastructure(infrastructure);
		if (result) {
			infrastructure.isDeleted = true;
			store.dispatch(infrastructuresReducer.actions.infrastructureCreateUpdate(infrastructure));
			var infs: Infrastructure[] = infrastructuresSelectors.selectAll(state).filter(i => !i.isDeleted && i.centerId === props.centerId);
			setInfrastructures(infs);
			setInfrastructureNodes(createInfrastructureTree(infs));
			message.success({ content: t('messages.dataSaved'), key, duration: 2 });
		} else message.info(t('message.unableToDelete'));
	};

	const handleSearch = (value: string) => {
		if (value === '') {
			setInfrastructureNodes(createInfrastructureTree(infrastructuresSelectors.selectAll(state)).filter(ns => !ns.isDeleted));
		} else {
			setInfrastructureNodes(
				createInfrastructureTree(
					infrastructuresSelectors.selectAll(state).filter(ns => !ns.isDeleted && ns.description.toUpperCase().includes(value.toUpperCase())),
				),
			);
			return;
		}
	};

	const menuItems = [
		{
			key: 'edit',
			icon: <EditOutlined />,
			onclick: handleEdit,
			label: t('general.edit'),
		},
		{
			key: 'delete',
			icon: <DeleteOutlined />,
			onclick: handleDelete,
			label: t('general.delete'),
		},
		{
			key: 'close',
			onclick: () => setContextMenuVisible(false),
			label: t('general.close'),
		},
	];

	const contextMenu = <Menu style={{ position: 'absolute', left: `${positionX}px`, top: `${positionY}px` }} items={menuItems} />;

	return (
		<Card size="small" title={t('network.infrastructure')} extra={[<Button type="primary" shape="round" icon={<PlusOutlined />} onClick={handleAdd}></Button>]}>
			<Row>
				<Col key="col1" flex={3}>
					<Space direction="horizontal">
						<Text strong>{t('general.searchEngine')}</Text>
						<AutoComplete
							popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
							style={styles.search}
							placeholder={t('general.search')}
							allowClear
							onSearch={handleSearch}
						/>
					</Space>
				</Col>
				<Col key="col2" flex={4}></Col>
				<Col key="col3" flex={3}></Col>
			</Row>
			<Tree
				key={`infrastructure-tree-${Date.now()}`}
				treeData={infrastructureNodes}
				onRightClick={handleRightClick}
				draggable
				defaultExpandAll
				showLine
				showIcon
				onDrop={handleOnDrop}
			/>
			<SizedModal
				size="medium"
				title={modalTitle}
				open={isModalVisible}
				okText={t('general.save')}
				cancelText={t('general.cancel')}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				{component}
			</SizedModal>
			{contextMenuVisible ? contextMenu : null}
		</Card>
	);
};

export default FormInfrastructureDetails;
