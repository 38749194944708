import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const CheckUnchecked = (props: { isChecked: boolean }) => {
	return (
		<span>
			{props.isChecked ? (
				<CheckCircleOutlined style={{ fontSize: 24, color: 'green' }} />
			) : (
				<CloseCircleOutlined style={{ fontSize: 24, color: 'red' }} />
			)}
		</span>
	);
};
export default CheckUnchecked;
