import { Row, Col } from 'antd';
import { FormProfessionalAccomplishments, FormProfessionalSpecialties } from '../../components';

const FormProfessionalAccomplishmentsData = ({ professionalId, professionalType }) => {
	return (
		<Row gutter={[16, 16]}>
			<Col className="gutter-row" lg={24} xl={24} xxl={12}>
				<FormProfessionalAccomplishments key={`accomplishments-${Date.now()}`} professionalId={professionalId} />
			</Col>
			<Col className="gutter-row" lg={24} xl={24} xxl={12}>
				<FormProfessionalSpecialties key={`specialties-${Date.now()}`} disabled={false} professionalId={professionalId} professionalType={professionalType} />
			</Col>
		</Row>
	);
};
export default FormProfessionalAccomplishmentsData;
