import { ReactNode, useEffect, useState } from 'react';
import { AutoComplete, Button, Col, Row, Space, Table, Typography } from 'antd';
import { AuthRoutes } from '..';
import { EditOutlined, UndoOutlined } from '@ant-design/icons';
import { fetchDomainDefinitions } from '../../store/api';
import { SimpleProfessionalDomain } from '../../store/types';
import { ColumnProps } from 'antd/lib/table';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { includes } from '../../utils/util-custom-validators';

const { Text } = Typography;

const FormConfigurationDomainsProfessional = () => {
	const styles = baseStyles;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [domainDefinitions, setDomainDefinitions] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		setIsLoading(true);
		fetchDomainDefinitions().then(item => {
			setDomainDefinitions(item.filter(d => d.formType === 'descriptionByProfessional').sort((a, b) => a.domain.localeCompare(b.domain)));
			setIsLoading(false);
		});
	}, []);

	const handleEdit = (item: any) => {
		switch (item.formType) {
			case 'generic':
				navigate(AuthRoutes.configuration + '/generic', {
					state: { domain: item.domain, urlFetchData: item.urlFetchData, urlUpdate: item.urlUpdate },
				});
				break;
			case 'description':
				navigate(AuthRoutes.configuration + '/description', {
					state: { domain: item.domain, urlFetchData: item.urlFetchData, urlUpdate: item.urlUpdate },
				});
				break;
			case 'descriptionByProfessional':
				navigate(AuthRoutes.configuration + '/descriptionByProfessional', {
					state: {
						domain: item.domain,
						urlFetchData: item.urlFetchData,
						urlUpdate: item.urlUpdate,
					},
				});
				break;
		}
	};

	const columns: ColumnProps<SimpleProfessionalDomain>[] = [
		{
			title: t('general.domains'),
			dataIndex: 'domain',
			key: 'domain',
		},
		{
			title: t('general.description'),
			dataIndex: 'description',
			key: 'description',
			sorter: {
				compare: (a: SimpleProfessionalDomain, b: SimpleProfessionalDomain) => {
					if (a.description < b.description) return -1;
					if (a.description > b.description) return 1;
					return 0;
				},
				multiple: 2,
			},
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: any): ReactNode => {
				return (
					<Space size="middle">
						<Button
							style={styles.button}
							type="primary"
							icon={<EditOutlined />}
							shape="round"
							onClick={() => {
								handleEdit(item);
							}}
						/>
					</Space>
				);
			},
		},
	];

	const handleRefresh = async () => {
		setIsLoading(true);
		setDomainDefinitions([]);
		var result = await fetchDomainDefinitions();
		setDomainDefinitions(result.filter(d => d.formType === 'descriptionByProfessional'));
		setIsLoading(false);
	};

	const handleSearch = (value: string) => {
		if (value) {
			var searchResult = domainDefinitions.filter(d => includes(d.domain, value) || includes(d.description, value));
			var searchVisualization = searchResult.map(d => {
				return {
					value: d.domain,
					label: <Text strong>{d.description}</Text>,
				};
			});
			setOptions(searchVisualization);
		} else setOptions([]);
	};

	const handleSelect = (_value, option) => {
		var item = domainDefinitions.find(i => i.domain === option.value);
		handleEdit(item);
	};

	return (
		<div>
			<Row>
				<Col key="col1" flex={3}>
					<Space direction="horizontal">
						<Text strong>{t('general.searchEngine')}</Text>
						<AutoComplete
							popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
							style={styles.search}
							placeholder={t('general.search')}
							allowClear
							options={options}
							onSelect={handleSelect}
							onSearch={handleSearch}
						/>
					</Space>
				</Col>
				<Col key="col2" flex={4}></Col>
				<Col key="col3" flex={3}>
					<Row justify="end">
						<Space size="middle">
							<Button type="primary" shape="round" icon={<UndoOutlined />} onClick={() => handleRefresh()}>
								{t('general.refresh')}
							</Button>
						</Space>
					</Row>
				</Col>
			</Row>
			<Row style={{ paddingTop: 10 }}>
				<Col flex={1}>
					<Table
						rowKey={(item: { id: any }) => item.id}
						tableLayout="auto"
						size="small"
						dataSource={domainDefinitions}
						columns={columns}
						pagination={{ position: ['bottomRight'], showTotal: total => `${t('general.total')} ${total} ${t('general.items')}` }}
						loading={isLoading}
						style={{ width: '96vw' }}
					/>
				</Col>
			</Row>
		</div>
	);
};
export default FormConfigurationDomainsProfessional;
