import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export const convertDraftToHtml = (editorState: EditorState): string => {
	const rawContentState = convertToRaw(editorState.getCurrentContent());
	const markup = draftToHtml(rawContentState);
	return markup;
};

export const convertHtmlToDraft = (text: string): EditorState => {
	const blocksFromHtml = htmlToDraft(text);
	const { contentBlocks, entityMap } = blocksFromHtml;
	const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
	const editorState = EditorState.createWithContent(contentState);
	return editorState;
};
