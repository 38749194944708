import { Center, Service, Address, Infrastructure, Equipment } from '../types';
import { parseAddress } from './professional';
import { urls } from './urls';
import { InfrastructureNode, Network, ServiceNode } from '../../store/types/network';

/**
 * Parse centers
 */
export const parseNetwork = (data: any): Network => {
	var n: Network = {
		id: data.id,
		name: data.name,
		description: data.description,
		avatar: data.avatar,
		internalCode: data.internalCode,
		externalCode: data.externalCode,
		networkTypeId: data.networkType ? data.networkType.id : 0,
		countryId: data.country ? data.country.id : 0,
		isDeleted: data.isDeleted,
	};
	return n;
};

export const fetchNetworks = async (): Promise<Network[]> => {
	var result: Network[] = [] as Network[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(`${urls.networks}`, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	if (jsonObject[0] === undefined) return result;
	for (var i = 0; i < jsonObject.length; i++) {
		var n: Network = parseNetwork(jsonObject[i]);
		result.push(n);
	}
	return result;
};

/**
 * Parse centers
 */
export const parseCenter = (data: any): Center => {
	var c: Center = {
		id: data.id,
		name: data.name,
		description: data.description,
		details: data.details,
		avatar: data.avatar,
		internalCode: data.internalCode,
		externalCode: data.externalCode,
		networkId: globalThis.networkId,
		centerTypeId: data.centerType !== null ? data.centerType.id : 0,
		addressId: data.address !== null ? data.address.id : 0,
		services: data.services.length > 0 ? data.services.map((s: any) => s.id) : [],
		isDeleted: data.isDeleted,
		isVisible: data.isVisible,
	};
	return c;
};

export const createUpdateCenter = async (center: Center): Promise<Center | undefined> => {
	var temp: Center;
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(center),
	};
	const request = `${urls.createUpdateCenter}`;
	var response = await fetch(request, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		temp = parseCenter(data);
		return temp;
	} else return undefined;
};

export const deleteCenter = async (center: Center): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	const response = await fetch(`${urls.deleteCenter}?centerId=${center.id}&networkId=${globalThis.networkId}`, requestOptions);
	if (response.status === 200) {
		return true;
	}
	return false;
};

/**
 * Parse centers
 */
export const fetchCenters = async (): Promise<{ centers: Center[]; addresses: Address[] }> => {
	var result = {
		centers: [] as Center[],
		addresses: [] as Address[],
	};
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(`${urls.centers}?networkId=${globalThis.networkId}`, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	if (jsonObject[0] === undefined) return result;
	for (var i = 0; i < jsonObject.length; i++) {
		var c: Center = parseCenter(jsonObject[i]);
		if (jsonObject[i].address !== null) {
			var a: Address = parseAddress(jsonObject[i].address, 0, 0);
			c.addressId = a.id;
			result.addresses.push(a);
		}
		result.centers.push(c);
	}
	return result;
};

/**
 * Parse Infrastructure
 */
export const parseInfrastructure = (data: any): Infrastructure => {
	var c: Infrastructure = {
		id: data.id,
		description: data.description,
		internalCode: data.internalCode,
		externalCode: data.externalCode,
		infrastructureTypeId: data.infrastructureType.id,
		centerId: data.center.id,
		parentId: data.parentInfrastructure !== null ? data.parentInfrastructure.id : 0,
		isDeleted: data.isDeleted,
	};
	return c;
};

/**
 * Fetch Infrastructure
 */
export const fetchInfrastructure = async (): Promise<Infrastructure[]> => {
	var result: Infrastructure[] = [];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(`${urls.infrastructures}?networkId=${globalThis.networkId}`, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	if (jsonObject[0] === undefined) return result;
	for (var i = 0; i < jsonObject.length; i++) {
		var inf: Infrastructure = parseInfrastructure(jsonObject[i]);
		result.push(inf);
	}
	return result;
};

export const createUpdateInfrastructure = async (infrastructure: Infrastructure): Promise<Infrastructure | undefined> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(infrastructure),
	};
	const request = `${urls.createUpdateInfrastructure}?networkId=${globalThis.networkId}`;
	var response = await fetch(request, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		var result = parseInfrastructure(data);
		return result;
	} else return undefined;
};

export const deleteInfrastructure = async (infrastructure: Infrastructure): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	const response = await fetch(`${urls.deleteService}?infrastructureId=${infrastructure.id}`, requestOptions);
	if (response.status === 200) {
		return true;
	}
	return false;
};

/**
 * Parse Equipment
 */
export const parseEquipment = (data: any): Equipment => {
	var c: Equipment = {
		id: data.id,
		description: data.description,
		internalCode: data.internalCode,
		externalCode: data.externalCode,
		equipmentTypeId: data.equipmentType.id,
		equipmentStatusId: data.equipmentStatus.id,
		locationId: data.location !== undefined ? data.location.id : 0,
		isDeleted: data.isDeleted,
	};
	return c;
};

/**
 * Parse Equipment
 */
export const fetchEquipment = async (): Promise<Equipment[]> => {
	var result: Equipment[] = [];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(`${urls.equipments}?networkId=${globalThis.networkId}`, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	if (jsonObject[0] === undefined) return result;
	for (var i = 0; i < jsonObject.length; i++) {
		var eq: Equipment = parseEquipment(jsonObject[i]);
		result.push(eq);
	}
	return result;
};

export const createUpdateEquipment = async (equipment: Equipment): Promise<Equipment | undefined> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(equipment),
	};
	const request = `${urls.createUpdateEquipment}`;
	var response = await fetch(request, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		var result = parseEquipment(data);
		return result;
	} else return undefined;
};

export const parseService = (data: any): Service => {
	var s: Service = {
		id: data.id,
		description: data.description,
		internalCode: data.internalCode,
		externalCode: data.externalCode,
		parentId: data.parentService != null ? data.parentService.id : 0,
		isDeleted: data.isDeleted,
	};
	return s;
};

/**
 * Parse services
 */
export const fetchServices = async (): Promise<Service[]> => {
	var allServices = [] as Service[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.services, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	if (jsonObject[0] === undefined) return allServices;
	for (var i = 0; i < jsonObject.length; i++) {
		var result = parseService(jsonObject[i]);
		allServices.push(result);
	}
	return allServices;
};

export const createUpdateService = async (service: Service): Promise<Service | undefined> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(service),
	};
	const request = `${urls.createUpdateService}`;
	var response = await fetch(request, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		var result = parseService(data);
		return result;
	} else return undefined;
};

export const deleteService = async (service: Service): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	const response = await fetch(`${urls.deleteService}?serviceId=${service.id}`, requestOptions);
	if (response.status === 200) {
		return true;
	}
	return false;
};

export const createUpdateCenterAddress = async (address: Address, centerId: number): Promise<Address | undefined> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(address),
	};
	const request = `${urls.createUpdateCenterAddress}?networkId=${globalThis.networkId}&centerId=${centerId}`;
	var response = await fetch(request, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		var temp = parseAddress(data, 0, 0);
		return temp;
	}
	return undefined;
};

export const createInfrastructureTree = (infrastructures: Infrastructure[]): InfrastructureNode[] => {
	var temp: InfrastructureNode[] = [];
	var result: InfrastructureNode[] = [];
	infrastructures.forEach(infra => {
		var sn: InfrastructureNode = {
			key: infra.id,
			title: infra.description,
			parentId: infra.parentId,
			isDeleted: infra.isDeleted,
		};
		temp.push(sn);
	});
	temp.forEach(node => {
		if (node.parentId === 0) result.push(node);
		else {
			var parent = temp.find(n => n.key === node.parentId);
			if (parent !== undefined) {
				if (parent.children === undefined) parent.children = [];
				parent.children?.push(node);
			}
		}
	});
	return result;
};

export const createServiceTree = (services: Service[], availableServices: number[] = []): ServiceNode[] => {
	var temp: ServiceNode[] = [];
	var items: ServiceNode[] = [];
	services.forEach(service => {
		var sn = {
			key: service.id,
			title: service.description,
			parentId: service.parentId,
			isDeleted: service.isDeleted,
			isVisible: availableServices.includes(service.id),
		};
		temp.push(sn);
	});
	temp.forEach(node => {
		if (node.parentId === 0) items.push(node);
		else {
			var parent = temp.find(n => n.key === node.parentId);
			if (parent !== undefined) {
				if (parent.children === undefined) parent.children = [];
				parent.children?.push(node);
				parent.isVisible = true;
			}
		}
	});
	return items;
};
