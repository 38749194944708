import { useEffect, useState } from 'react';
import { Address, Center, initialAddressValue, initialCenterValue } from '../../store/types';
import { Tabs, Button, message, Layout, Breadcrumb, Col, Row, Space, Popconfirm, Tooltip } from 'antd';
import { useStore } from 'react-redux';
import { centers as centersReducer } from '../../store/reducers';
import { addresses, centers } from '../../store/reducers';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { addressesSelectors, centersSelectors } from '../../store/selectors';
import { AuthRoutes, FormCenterDetails, FormCenterServices, FormInfrastructureDetails } from '../../components';
import { ArrowLeftOutlined, BankOutlined, DeleteOutlined, ExperimentOutlined, GoldTwoTone, ProfileOutlined, SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { createUpdateCenter, createUpdateCenterAddress, deleteCenter } from '../../store/api';
import { colors } from '../../assets/styles';

const key = 'saving';
const { Content } = Layout;

const PageCenterDetails = () => {
	const store = useStore();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const location = useLocation();
	const params: any = location.state;
	const id: number = parseInt(params.id);
	const [changed, setChanged] = useState<boolean>(false);
	const [hasErrors, setHasErrors] = useState<boolean>(false);
	const [center, setCenter] = useState<Center>(initialCenterValue);
	const [address, setAddress] = useState<Address>(initialAddressValue);

	useEffect(() => {
		var item = centersSelectors.selectById(store.getState() as any, id) || initialCenterValue;
		setCenter(centersSelectors.selectById(store.getState() as any, id) || initialCenterValue);
		setAddress(addressesSelectors.selectById(store.getState() as any, item.addressId));
	}, [id, store]);

	const tabs = [
		{
			icon: <ProfileOutlined />,
			label: t('network.details'),
			key: 0,
			children: (
				<FormCenterDetails centerId={center.id} setCenterData={setCenter} setAddressData={setAddress} setHasErrors={setHasErrors} setIsChanged={setChanged} />
			),
		},
		{
			icon: <BankOutlined />,
			label: t('network.infrastructure'),
			key: 2,
			disabled: id === 0 ? true : false,
			children: <FormInfrastructureDetails centerId={id} />,
		},
		{
			icon: <ExperimentOutlined />,
			label: t('network.services'),
			key: 3,
			disabled: id === 0 ? true : false,
			children: <FormCenterServices centerId={id} setCenterData={setCenter} />,
		},
	];

	const handleDelete = async () => {
		var response = await deleteCenter(center);
		if (response) {
			var temp = { ...center, isDeleted: true };
			store.dispatch(centersReducer.actions.centerCreateUpdate(temp));
		} else message.info(t('message.unableToDelete'));
	};

	const handleSave = async () => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		try {
			var addressResult = await createUpdateCenterAddress(address, center.id);
			if (addressResult !== undefined) {
				var centerResult = await createUpdateCenter(center);
				if (centerResult !== undefined) {
					store.dispatch(centers.actions.centerCreateUpdate(centerResult));
					store.dispatch(addresses.actions.addressCreateUpdate(addressResult));
					message.success({ content: t('messages.dataSaved'), key, duration: 2 });
					setChanged(false);
				}
			} else message.error(t('messages.dataNotSaved'));
		} catch (errors) {
			message.error({ content: t('message.errorOnSave'), key, duration: 2 });
		}
	};

	return (
		<div>
			<Col>
				<Row>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Breadcrumb
							separator=">"
							items={[
								{ title: <GoldTwoTone twoToneColor={colors.brandColor1} /> },
								{ title: t('general.network') },
								{ title: <Link to={AuthRoutes.centers + '/index'}>{t('general.centers')}</Link> },
								{ title: center.name },
							]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Space>
							{changed ? (
								<Popconfirm
									title={t('messages.confirmExit')}
									onConfirm={() => {
										navigate(AuthRoutes.centers + '/index');
									}}
									okText={t('general.ok')}
									cancelText={t('general.cancel')}
								>
									<Tooltip title={t('general.back')}>
										<Button icon={<ArrowLeftOutlined />} type="primary" shape="round">
											{t('general.back')}
										</Button>
									</Tooltip>
								</Popconfirm>
							) : (
								<Tooltip title={t('general.back')}>
									<Button icon={<ArrowLeftOutlined />} type="primary" shape="round" onClick={() => navigate(AuthRoutes.centers + '/index')}>
										{t('general.back')}
									</Button>
								</Tooltip>
							)}
							<Button disabled={!changed || hasErrors} type="primary" shape="round" onClick={handleSave} icon={<SaveOutlined />}>
								{t('general.save')}
							</Button>
							<Button style={{ background: 'red' }} type="primary" shape="round" onClick={handleDelete} icon={<DeleteOutlined />}>
								{t('general.delete')}
							</Button>
						</Space>
					</Col>
				</Row>
				<Content className="site-layout-background">
					<Tabs
						type="card"
						defaultActiveKey="0"
						style={{ width: '100%', padding: 10 }}
						items={tabs.map(item => {
							return {
								label: item.label,
								icon: item.icon,
								key: item.key.toString(),
								disabled: item.disabled,
								children: item.children,
							};
						})}
					></Tabs>
				</Content>
			</Col>
		</div>
	);
};
export default PageCenterDetails;
