import {
	Input,
	Breadcrumb,
	Button,
	Col,
	Layout,
	Row,
	Space,
	Typography,
	Spin,
	Anchor,
	Empty,
	notification,
	Tag,
	Descriptions,
	message,
	Alert,
	Affix,
	FloatButton,
	Popover,
	Menu,
	MenuProps,
	DatePicker,
} from 'antd';
import {
	ApartmentOutlined,
	CalendarOutlined,
	ClearOutlined,
	FileTextOutlined,
	FilterOutlined,
	LaptopOutlined,
	LoadingOutlined,
	ToolOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { colors } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { urls } from '../../store/api';
import { dateFormat, dateTimeFormat } from '../../assets/formats';
import { To, useNavigate } from 'react-router-dom';
import { uuid } from '../../utils/util-generics';
import { IconClinic, IconStethoscope, NodeEncounter, NodeNote, SizedModal } from '../../components';
import { PDFViewer } from '@react-pdf/renderer';
import { isValidRut } from '../../utils/util-custom-validators';
import COMPINDocument from '../../utils/util-generate-compin-pdf';
import EHRDocument from '../../utils/util-generate-ehr-pdf';
import dayjs from 'dayjs';

const { Text } = Typography;
const { Content } = Layout;
const { Search } = Input;
const { RangePicker } = DatePicker;

interface FilterType {
	type: string;
	key: string;
	value: string;
}

const PageEHRDetails = () => {
	const toolsRef = useRef(null);
	const ehrRef = useRef(null);
	const menuHeight = 120;
	const patientDataRef = useRef(null);
	const [messageApi, messageContextHolder] = message.useMessage();
	const [patientDataViewHeight, setPatientDataViewHeight] = useState(0);
	const [contentsHeight, setContentsHeight] = useState(window.innerHeight - toolsRef.current?.offsetHeight - menuHeight || 0);
	const [openModal, setOpenModal] = useState(false);
	const [patientData, setPatientData] = useState<any>(undefined);
	const [filteredPatientData, setFilteredPatientData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedEncounter, setSelectedEncounter] = useState<any>(undefined);
	const [selectedPatient, setSelectedPatient] = useState<any>(undefined);
	const [pdfDocument, setPDFDocument] = useState<any>();
	const [filters, setFilters] = useState<FilterType[]>([]);
	const [filterTags, setFilterTags] = useState([]);
	const [professionalOptions, setProfessionalOptions] = useState([]);
	const [specialtyOptions, setSpecialtyOptions] = useState([]);
	const [centerOptions, setCenterOptions] = useState([]);
	const [systemOptions, setSystemOptions] = useState([]);
	const [typeOptions, setTypeOptions] = useState([]);
	const [selectedStartDate, setSelectedStartDate] = useState<string>('');
	const [selectedEndDate, setSelectedEndDate] = useState<string>('');
	const [minDate, setMinDate] = useState<string>('');
	const [maxDate, setMaxDate] = useState<string>('');
	const [anchorNodes, setAnchorNodes] = useState([]);
	const [noteNodes, setNoteNodes] = useState([]);
	const [notificacionApi, notificationContextHolder] = notification.useNotification();
	const { t } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		const handleResize = () => {
			if (toolsRef.current) {
				setContentsHeight(window.innerHeight - toolsRef.current.offsetHeight - patientDataViewHeight - menuHeight);
			}
			if (patientDataRef.current) {
				setPatientDataViewHeight(patientDataRef.current.offsetHeight);
				setContentsHeight(window.innerHeight - toolsRef.current.offsetHeight - patientDataRef.current.offsetHeight - menuHeight);
			}
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [patientDataViewHeight, isLoading, professionalOptions]);

	const createNodes = (data: any[]) => {
		var profesionals = [];
		var specialties = [];
		var centers = [];
		var systems = [];
		var types = [];
		var years = [];
		var tempNoteNodes = [];
		var tempAnchorNodes = [];
		var anchorNode: any = {};
		var guid = '';
		data.forEach(encounter => {
			if (dayjs(encounter.fecha) < dayjs(minDate)) setMinDate(encounter.fecha);
			if (dayjs(encounter.fecha) > dayjs(maxDate)) setMaxDate(encounter.fecha);
			if (!profesionals.includes(encounter.profesional?.nombre_completo)) profesionals.push(encounter.profesional?.nombre_completo);
			if (!specialties.includes(encounter.profesional?.especialidad)) specialties.push(encounter.profesional?.especialidad);
			if (!centers.includes(encounter.establecimiento?.descripcion)) centers.push(encounter.establecimiento?.descripcion);
			if (!systems.includes(encounter.sistema)) systems.push(encounter.sistema);
			if (!types.includes(encounter.tipo)) types.push(encounter.tipo);
			if (!years.includes(dayjs(encounter.fecha).year)) years.push(dayjs(encounter.fecha).year);
			anchorNode = tempAnchorNodes.find(a => a.key === dayjs(encounter.fecha).format(dateFormat));
			if (anchorNode) {
				guid = uuid();
				encounter.guid = guid;
				anchorNode.children.push({
					key: guid,
					href: '#' + guid,
					title: (
						<NodeEncounter
							date={encounter.fecha}
							professional_name={encounter.profesional?.nombre_completo}
							professional_specialty={encounter.profesional.especialidad ? encounter.profesional.especialidad : 'Sin especialidad'}
							system={encounter.sistema}
							scope={encounter.ambito}
							type={encounter.tipo}
							location={encounter.establecimiento?.descripcion}
						/>
					),
				});
			} else {
				guid = uuid();
				encounter.guid = guid;
				anchorNode = {
					key: dayjs(encounter.fecha).format(dateFormat),
					href: '#' + dayjs(encounter.fecha).format(dateFormat),
					title: (
						<Space>
							<CalendarOutlined />
							<Text strong>{dayjs(encounter.fecha).format(dateFormat)}</Text>
						</Space>
					),
					children: [
						{
							key: guid,
							href: '#' + guid,
							title: (
								<NodeEncounter
									date={encounter.fecha}
									professional_name={encounter.profesional?.nombre_completo}
									professional_specialty={encounter.profesional.especialidad ? encounter.profesional.especialidad : 'Sin especialidad'}
									system={encounter.sistema}
									scope={encounter.ambito}
									type={encounter.tipo}
									location={encounter.establecimiento?.descripcion}
								/>
							),
						},
					],
				};
				tempNoteNodes.push(<NodeNote encounter={encounter} guid={guid} />);
				tempAnchorNodes.push(anchorNode);
			}
		});
		profesionals.sort((a, b) => a?.localeCompare(b));
		specialties.sort((a, b) => a?.localeCompare(b));
		centers.sort((a, b) => a?.localeCompare(b));
		systems.sort((a, b) => a?.localeCompare(b));
		types.sort((a, b) => a?.localeCompare(b));
		setProfessionalOptions(
			profesionals.map((value, index) => {
				return {
					key: 'filters-professionals-' + index,
					value: value,
					label: value,
				};
			}),
		);
		setSpecialtyOptions(
			specialties.map((value, index) => {
				return {
					key: 'filters-specialties-' + index,
					value: value,
					label: value,
				};
			}),
		);
		setCenterOptions(
			centers.map((value, index) => {
				return {
					key: 'filters-centers-' + index,
					value: value,
					label: value,
				};
			}),
		);
		setSystemOptions(
			systems.map((value, index) => {
				return {
					key: 'filters-systems-' + index,
					value: value,
					label: value,
				};
			}),
		);
		setTypeOptions(
			types.map((value, index) => {
				return {
					value: 'filters-types-' + index,
					label: value,
				};
			}),
		);
		setAnchorNodes(tempAnchorNodes);
		setNoteNodes(tempNoteNodes);
	};

	const getPatientData = async (rut: string) => {
		setIsLoading(true);
		setFilters([]);
		if (toolsRef.current) {
			setContentsHeight(window.innerHeight - toolsRef.current.offsetHeight - menuHeight);
		}
		const request = urls.getEHRData + '?rut=' + rut;
		const requestOptions = {
			method: 'GET',
		};
		var response = await fetch(request, requestOptions);
		if (response.status === 200) {
			var data = await response.json();
			setPatientData(data);
			setSelectedPatient({
				nombre: data.nombre,
				apellido_paterno: data.apellido_paterno,
				apellido_materno: data.apellido_materno,
				numero_identificacion: data.numero_identificacion,
				fecha_nacimiento: data.fecha_nacimiento,
				sexo: data.sexo,
				direccion: data.direccion,
				telefono: data.telefono,
			});
			setFilteredPatientData(data.episodios);
			createNodes(data.episodios);
		} else {
			setPatientData(undefined);
			setSelectedPatient(undefined);
			setFilteredPatientData(undefined);
		}
		setIsLoading(false);
	};

	const filterNotes = (filters, startDate, endDate) => {
		setIsLoading(true);
		var tempData = patientData.episodios;
		if (patientData !== undefined) {
			if (startDate !== '' && endDate !== '') {
				var tempStartDate = dayjs(startDate, 'DD/MM/YYYY');
				var tempEndDate = dayjs(endDate, 'DD/MM/YYYY') || dayjs();
				tempData = tempData.filter(e => dayjs(e.fecha) >= tempStartDate && dayjs(e.fecha) <= tempEndDate);
			}
			filters.forEach(f => {
				var filterType = f.type;
				var filterValue = f.value;
				switch (filterType) {
					case 'professionals':
						tempData = tempData.filter(e => e.profesional?.nombre_completo === filterValue);
						break;
					case 'specialties':
						tempData = tempData.filter(e => e.profesional?.especialidad === filterValue);
						break;
					case 'centers':
						tempData = tempData.filter(e => e.establecimiento?.descripcion === filterValue);
						break;
					case 'systems':
						tempData = tempData.filter(e => e.sistema === filterValue);
						break;
					case 'types':
						tempData = tempData.filter(e => e.tipo === filterValue);
						break;
				}
			});
		}
		setFilteredPatientData(tempData);
		createNodes(tempData);
		setIsLoading(false);
	};

	const onAnchorClick = (_e: any, link: { href: To }) => {
		var guid = link.href.toString().substring(1);
		var encounter = patientData.episodios.find(episode => episode.guid === guid);
		setSelectedEncounter(encounter);
		navigate(link.href);
		notificacionApi.info({
			message: t('messages.selectedEpisode'),
			style: { width: 500 },
			description: (
				<Space direction="vertical">
					<Text>{dayjs(encounter?.fecha).format(dateTimeFormat) + ' - ' + encounter?.profesional?.nombre_completo} </Text>
					<Row>
						<Tag color="success" icon={<IconStethoscope />}>
							{encounter?.profesional?.especialidad}
						</Tag>
						<Tag style={{ fontSize: 14 }} icon={<IconClinic />}>
							{encounter?.establecimiento.descripcion}
						</Tag>
					</Row>
				</Space>
			),
			placement: 'bottomLeft',
		});
	};

	const onSearchPatient = (value: string) => {
		if (isValidRut(value)) getPatientData(value);
		else if (value !== '')
			messageApi.open({
				type: 'error',
				content: t('messages.invalidRut'),
			});
		else setSelectedPatient(undefined);
	};

	const onRangePickerSelect = (_dates, dateStrings, _info) => {
		if (dateStrings[0] === '' && dateStrings[1] === '') {
			setSelectedStartDate(minDate);
			setSelectedEndDate('');
			filterNotes(filters, '', '');
		} else if (dateStrings[0] === '' || dateStrings[1] === '') return;
		else {
			var tempStartDate = dayjs(dateStrings[0], 'DD/MM/YYYY');
			var tempEndDate = dayjs(dateStrings[1], 'DD/MM/YYYY') || dayjs();
			setSelectedStartDate(dateStrings[0]);
			setSelectedEndDate(dateStrings[1]);
			filterNotes(filters, tempStartDate, tempEndDate);
		}
	};

	const onFilterTagClose = e => {
		var tempFilters = filters.filter(f => f.key !== e) || [];
		setFilters(tempFilters);
		setFilterTags(
			tempFilters.map(item => (
				<Tag style={{ backgroundColor: colors.brandColor1 }} onClose={onFilterTagClose} closable>
					{item.value}
				</Tag>
			)),
		);
		filterNotes(tempFilters, selectedStartDate, selectedEndDate);
	};

	const clearAllFilters = () => {
		var tempFilters = [];
		setFilters(tempFilters);
		setFilterTags(null);
		filterNotes(tempFilters, selectedStartDate, selectedEndDate);
	};
	const onMenuClick: MenuProps['onClick'] = e => {
		var menuType = e.key.split('-')[0];
		var menuOption = e.key.split('-')[1];
		if (menuType === 'tools') {
			if (menuOption === 'getEHRfile') {
				setPDFDocument(<EHRDocument patient={selectedPatient} selectedEncounters={filteredPatientData} />);
				setOpenModal(true);
			} else if (menuOption === 'getCOMPINfile') {
				setPDFDocument(<COMPINDocument patient={selectedPatient} encounter={selectedEncounter} />);
				setOpenModal(true);
			}
		}
		if (menuType === 'filters') {
			var filterValue: FilterType;
			var tempFilters = [];
			if (menuOption === 'clear') clearAllFilters();
			if (menuOption === 'professionals') {
				filterValue = {
					type: 'professionals',
					key: e.key,
					value: professionalOptions.find(item => item.key === e.key).value,
				};
				tempFilters = [...filters, filterValue];
			}
			if (menuOption === 'specialties') {
				filterValue = {
					type: 'specialties',
					key: e.key,
					value: specialtyOptions.find(item => item.key === e.key).value,
				};
				tempFilters = [...filters, filterValue];
			}
			if (menuOption === 'centers') {
				filterValue = {
					type: 'centers',
					key: e.key,
					value: centerOptions.find(item => item.key === e.key).value,
				};
				tempFilters = [...filters, filterValue];
			}
			if (menuOption === 'systems') {
				filterValue = {
					type: 'systems',
					key: e.key,
					value: systemOptions.find(item => item.key === e.key).value,
				};
				tempFilters = [...filters, filterValue];
			}
			if (menuOption === 'types') {
				filterValue = {
					type: 'types',
					key: e.key,
					value: typeOptions.find(item => item.key === e.key).value,
				};
				tempFilters = [...filters, filterValue];
			}
			setFilters(tempFilters);
			setFilterTags(
				tempFilters.map(item => (
					<Tag style={{ backgroundColor: colors.brandColor1 }} onClose={onFilterTagClose} closable>
						{item.value}
					</Tag>
				)),
			);
			filterNotes(tempFilters, selectedStartDate, selectedEndDate);
		}
	};

	return (
		<Content className="site-layout-background">
			<Col ref={toolsRef}>
				<Row>
					<Breadcrumb
						separator=">"
						style={{ height: 31, margin: '16px 24px 0 0' }}
						items={[
							{ title: <ApartmentOutlined twoToneColor={colors.brandColor1} /> },
							{ title: t('management.management') },
							{ title: t('management.ehrTools') },
						]}
					/>
				</Row>
				<Row style={{ paddingBottom: 10, height: '100%' }}>
					<Col>
						<Space direction="horizontal">
							<Text strong>{t('management.patientId')}</Text>
							<Search style={{ width: 200 }} placeholder={t('management.searchPatient')} onSearch={onSearchPatient} allowClear />
							<Text strong>{t('management.dateRange')}</Text>
							<RangePicker
								allowClear
								disabled={patientData === undefined ? true : false}
								picker="month"
								showTime={false}
								format={dateFormat}
								minDate={dayjs(minDate)}
								maxDate={dayjs(maxDate)}
								onCalendarChange={onRangePickerSelect}
							/>
							{filters.length > 0 ? <Text strong>{t('management.activeFilters')}</Text> : null}
							{filterTags}
						</Space>
					</Col>
					<Col flex={1}>
						<Row justify="end">
							<Space direction="horizontal">
								{filters.length > 0 ? (
									<Button shape="round" type="primary" icon={<ClearOutlined />} onClick={() => clearAllFilters()}>
										{t('management.clearFilters')}
									</Button>
								) : null}
							</Space>
						</Row>
					</Col>
				</Row>
				<Row style={{ height: contentsHeight }}>
					<Col style={{ width: '100%', height: '100%' }}>
						<Spin tip={t('general.loading')} indicator={<LoadingOutlined spin />} size="large" spinning={isLoading}>
							<Row ref={patientDataRef} style={{ padding: 10 }}>
								{patientData ? (
									<Descriptions
										column={5}
										items={[
											{
												key: '1',
												label: t('management.name'),
												children: <Text ellipsis>{patientData.apellido_paterno + ' ' + patientData.apellido_materno + ', ' + patientData.nombre}</Text>,
											},
											{
												key: '2',
												label: t('management.patientId'),
												children: patientData.numero_identificacion,
											},
											{
												key: '3',
												label: t('management.birthDate'),
												children: dayjs(patientData.fecha_nacimiento).format(dateFormat),
											},
											{
												key: '4',
												label: t('general.age'),
												children: dayjs().diff(patientData.fecha_nacimiento, 'years') + ' ' + t('general.years'),
											},
											{
												key: '5',
												label: t('management.sex'),
												children: patientData.sexo,
											},
										]}
									/>
								) : null}
							</Row>
							<Row style={{ height: contentsHeight }}>
								{patientData ? (
									<>
										<Col span={6} style={{ height: contentsHeight, overflowY: 'scroll' }}>
											<Affix style={{ position: 'absolute' }}>
												<Space direction="horizontal">
													<Alert message={filteredPatientData.length + ' ' + t('management.encounters')} type="success" style={{ margin: 5 }} />
												</Space>
											</Affix>
											<Anchor style={{ paddingTop: 50 }} affix={false} direction="vertical" items={anchorNodes} onClick={onAnchorClick} />
										</Col>
										<Col ref={ehrRef} span={18} style={{ height: contentsHeight, paddingTop: 20, paddingLeft: 20, overflowY: 'scroll' }}>
											{noteNodes}
										</Col>
										<FloatButton.Group shape="circle">
											<Popover
												placement="right"
												title={t('general.filters')}
												content={
													<Menu
														selectable={false}
														onClick={onMenuClick}
														style={{ width: 256 }}
														mode="vertical"
														forceSubMenuRender
														items={[
															{ key: 'filters-clear', icon: <ClearOutlined />, label: t('management.clearFilters') },
															{ key: 'filters-centers', icon: <IconClinic />, label: t('management.center'), children: centerOptions },
															{ key: 'filters-systems', icon: <LaptopOutlined />, label: t('management.system'), children: systemOptions },
															{ key: 'filters-types', icon: <FileTextOutlined />, label: t('management.type'), children: typeOptions },
															{ key: 'filters-specialties', icon: <IconStethoscope />, label: t('management.specialty'), children: specialtyOptions },
															{ key: 'filters-professionals', icon: <UserOutlined />, label: t('management.professionals'), children: professionalOptions },
														]}
													/>
												}
											>
												<FloatButton type="primary" icon={<FilterOutlined />} />
											</Popover>
											<Popover
												placement="rightBottom"
												title={t('management.ehrTools')}
												content={
													<Menu
														selectable={false}
														onClick={onMenuClick}
														style={{ width: 256 }}
														mode="vertical"
														items={[
															{ key: 'tools-getEHRfile', label: t('management.getEHRfile'), disabled: selectedPatient === undefined ? true : false },
															{ key: 'tools-getCOMPINfile', label: t('management.getCOMPINfile'), disabled: selectedEncounter === undefined ? true : false },
														]}
													/>
												}
											>
												<FloatButton type="primary" icon={<ToolOutlined />} />
											</Popover>
											<FloatButton.BackTop type="primary" tooltip={t('messages.backToTop')} target={() => ehrRef.current} visibilityHeight={0} />
										</FloatButton.Group>
									</>
								) : (
									<Col span={24}>
										<Empty imageStyle={{ height: 60 }} description={<Text style={{ color: 'gray' }}>{t('messages.searchPatient')}</Text>} />
									</Col>
								)}
							</Row>
							{notificationContextHolder}
							{messageContextHolder}
						</Spin>
					</Col>
				</Row>
			</Col>
			<SizedModal
				size="large"
				centered
				onCancel={() => setOpenModal(false)}
				open={openModal}
				footer={
					<Button shape="round" type="primary" onClick={() => setOpenModal(false)}>
						{t('general.close')}
					</Button>
				}
			>
				<Row justify="center">
					<PDFViewer showToolbar style={{ height: '75vh', width: '50vw', minWidth: 1024 }}>
						{pdfDocument}
					</PDFViewer>
				</Row>
			</SizedModal>
		</Content>
	);
};
export default PageEHRDetails;
