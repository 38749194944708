import { useCallback, useEffect, useState } from 'react';
import { AutoComplete, Button, Col, Form, message, Popconfirm, Row, Space, Table, Tooltip, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, FilterFilled, PlusOutlined, RollbackOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons';
import { createUpdateTranslation } from '../../store/api';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { Translation } from '../../store/types/systems';
import { includes } from '../../utils/util-custom-validators';
import { fetchTranslations } from '../../store/api/systems';
import { EditableCell } from '..';
import debounce from 'lodash.debounce';

const key = 'saving';
const { Text } = Typography;

const FormConfigurationTranslations = () => {
	const styles = baseStyles;
	const { t } = useTranslation();
	const [translations, setTranslations] = useState<Translation[]>([]);
	const [domains, setDomains] = useState<string[]>([]);
	const [seletectedTranslations, setSelectedTranslations] = useState<Translation[]>([]);
	const [formConfigurationTranslations] = Form.useForm();
	const [editingKey, setEditingKey] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(true);

	const getTranslations = useCallback(async () => {
		setLoading(true);
		var items = await fetchTranslations();
		var domainItems = [];
		items.forEach(i => {
			if (!domainItems.includes(i.domain)) domainItems.push(i.domain);
		});
		setDomains(domainItems);
		setTranslations(items);
		setSelectedTranslations(items);
		setLoading(false);
	}, []);

	useEffect(() => {
		if (translations.length === 0) {
			getTranslations();
		}
	}, [getTranslations, translations]);

	const isEditing = (item: Translation) => item.id === editingKey;

	const handleEdit = (item: Translation) => {
		formConfigurationTranslations.setFieldsValue({
			...item,
		});
		setEditingKey(item.id);
	};

	const handleDelete = (item: Translation) => {
		formConfigurationTranslations.setFieldsValue({
			...item,
		});
		setEditingKey(item.id);
	};

	const handleCancel = () => {
		setEditingKey(0);
	};

	const handleRefresh = async () => {
		getTranslations();
	};

	const handleSave = async (item: Translation) => {
		try {
			const row = await formConfigurationTranslations.validateFields();
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			const newData = [...translations];
			const index = newData.findIndex(sd => sd.id === item.id);
			if (index > -1) {
				var translation = newData[index];
				translation.externalId = row.externalId;
				translation.internalId = row.internalId;
				translation.domain = row.domain;
				translation.networkId = globalThis.networkId;
				var response = await createUpdateTranslation(translation);
				if (response !== undefined) {
					newData.splice(index, 1, { ...response, ...row });
					message.success({ content: t('messages.dataSaved'), key, duration: 2 });
					setTranslations(newData);
				} else message.error({ content: t('messages.dataNotSaved'), key, duration: 2 });
				setEditingKey(0);
			} else {
				setTranslations(newData);
				setEditingKey(0);
			}
		} catch (errInfo) {
			message.error({ content: t('messages.errorOnTransaction'), key, duration: 2 });
		}
	};

	const handleAdd = () => {
		const newData: Translation = {
			id: 0,
			internalId: '',
			externalId: '',
			domain: '',
			networkId: globalThis.networkId,
		};
		setTranslations([...translations, newData]);
	};

	const columns = [
		{
			title: t('configurations.externalId'),
			dataIndex: 'externalId',
			key: 'externalId',
			editable: true,
		},
		{
			title: t('configurations.internalId'),
			dataIndex: 'internalId',
			key: 'internalId',
			editable: true,
			sorter: {
				compare: (a: Translation, b: Translation) => {
					if (a.internalId < b.internalId) return -1;
					if (a.internalId > b.internalId) return 1;
					return 0;
				},
				multiple: 2,
			},
		},
		{
			title: t('configurations.domains'),
			dataIndex: 'domain',
			key: 'domain',
			editable: true,
			filterIcon: <FilterFilled style={styles.filter}></FilterFilled>,
			filters: domains.map(t => {
				return {
					text: t,
					value: t,
				};
			}),
			onFilter: (value, item) => item.domain === value,
		},
		{
			title: t('general.actions'),
			dataIndex: 'operation',
			width: 100,
			render: (_text: string, item: Translation) => {
				const editable = isEditing(item);
				return editable ? (
					<Space>
						<Button type="primary" shape="round" onClick={() => handleSave(item)} icon={<SaveOutlined />} />
						<Popconfirm title={t('messages.confirmCancel')} onConfirm={handleCancel}>
							<Button type="primary" shape="round" icon={<RollbackOutlined />} />
						</Popconfirm>
					</Space>
				) : (
					<Space direction="horizontal">
						<Button
							style={styles.button}
							type="primary"
							disabled={editingKey !== 0}
							icon={<EditOutlined />}
							shape="round"
							onClick={() => {
								handleEdit(item);
							}}
						/>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDelete(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const mergedColumns = columns.map(col => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (item: any) => ({
				item,
				inputType: 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(item),
			}),
		};
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounceSearch = useCallback(
		debounce(value => {
			var items = translations.filter(ni => includes(ni.externalId, value) || includes(ni.internalId, value) || includes(ni.domain, value));
			setSelectedTranslations(items);
		}, 500),
		[translations],
	);

	const handleSearch = (value: string) => {
		if (!value) {
			setSelectedTranslations(translations);
		}
		debounceSearch(value);
	};

	return (
		<Form form={formConfigurationTranslations} component={false}>
			<Row>
				<Col key="col1" flex={3}>
					<Space direction="horizontal">
						<Text strong>{t('general.searchEngine')}</Text>
						<AutoComplete
							popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
							style={styles.search}
							placeholder={t('general.search')}
							allowClear
							onSearch={handleSearch}
						/>
					</Space>
				</Col>
				<Col key="col2" flex={4}></Col>
				<Col key="col3" flex={3}>
					<Row justify="end">
						<Space size="middle">
							<Button type="primary" shape="round" icon={<PlusOutlined />} onClick={() => handleAdd()}>
								{t('general.add')}
							</Button>
							<Button type="primary" shape="round" icon={<UndoOutlined />} onClick={() => handleRefresh()}>
								{t('general.refresh')}
							</Button>
						</Space>
					</Row>
				</Col>
			</Row>
			<Row style={{ paddingTop: 10 }}>
				<Col flex={1}>
					<Table<Translation>
						key={`translations-table`}
						components={{
							body: {
								cell: EditableCell,
							},
						}}
						loading={loading}
						rowKey={item => item.id}
						tableLayout="auto"
						size="small"
						bordered
						dataSource={seletectedTranslations}
						columns={mergedColumns}
						pagination={{ position: ['bottomRight'], showTotal: total => `${t('general.total')} ${total} ${t('general.items')}` }}
						style={{ width: '96vw' }}
					/>
				</Col>
			</Row>
		</Form>
	);
};
export default FormConfigurationTranslations;
