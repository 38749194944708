import { useState, ReactNode, useEffect } from 'react';
import { ProfessionalMilestone, initialProfessionalMilestoneValue, ProfessionalMilestoneType } from '../../store/types';
import { useStore } from 'react-redux';
import { Table, Space, Tooltip, Button, Typography, message, Card, Popconfirm, Popover } from 'antd';
import { professionalMilestones as professionalMilestonesReducer } from '../../store/reducers';
import { professionalMilestoneSelectors, professionalMilestoneTypeSelectors } from '../../store/selectors';
import { ColumnProps } from 'antd/lib/table';
import { DeleteOutlined, EditOutlined, InfoCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { FormProfessionalMilestoneEdit } from '..';
import { deleteProfessionalMilestone, fetchProfessionalMilestonesById } from '../../store/api';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { dateFormat } from '../../assets/formats';
import { isAuthorized } from '../../utils/util-authentication';

const { Text } = Typography;
const key = 'saving';
/**
 * Form to display a table of specialties
 */
const FormProfessionalMilestones = ({ professionalId, professionalType, disabled = false }) => {
	const store = useStore();
	const styles = baseStyles;
	const { t } = useTranslation();
	const [professionalMilestones, setProfessionalMilestones] = useState<ProfessionalMilestone[]>([]);
	const [professionalMilestoneTypes, setProfessionalMilestoneTypes] = useState<ProfessionalMilestoneType[]>([]);
	const [professionalMilestone, setProfessionalMilestone] = useState<ProfessionalMilestone>({
		...initialProfessionalMilestoneValue,
		professionalId: professionalId,
	});
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [modalTitle, setModalTitle] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsLoading(true);
		fetchProfessionalMilestonesById(professionalId).then(items => {
			store.dispatch(professionalMilestonesReducer.actions.professionalMilestonesLoading());
			store.dispatch(professionalMilestonesReducer.actions.professionalMilestonesReceived(items));
			setProfessionalMilestones(items.filter(s => !s.isDeleted).sort((a, b) => dayjs(b.startDateTime).diff(dayjs(a.startDateTime))));
			setIsLoading(false);
		});
		setProfessionalMilestoneTypes(professionalMilestoneTypeSelectors.selectAll(store.getState() as any).filter(s => !s.isDeleted));
	}, [professionalId, store]);

	const handleEdit = (item: ProfessionalMilestone) => {
		setProfessionalMilestone(item);
		setModalTitle(t('professionals.editProfessionalMilestone'));
		setIsModalVisible(true);
	};

	const handleAddMilestone = () => {
		setProfessionalMilestone({ ...initialProfessionalMilestoneValue, professionalId: professionalId });
		setModalTitle(t('professionals.addProfessionalMilestone'));
		setIsModalVisible(true);
	};

	const handleDelete = async (item: ProfessionalMilestone) => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var result = await deleteProfessionalMilestone(item);
		if (result) {
			setIsLoading(true);
			fetchProfessionalMilestonesById(professionalId).then(items => {
				store.dispatch(professionalMilestonesReducer.actions.professionalMilestonesLoading());
				store.dispatch(professionalMilestonesReducer.actions.professionalMilestonesReceived(items));
				setProfessionalMilestones(items.filter(s => !s.isDeleted));
				setIsLoading(false);
				message.success({ content: t('messages.itemDeleted'), key, duration: 2 });
			});
		} else message.info({ content: t('message.unableToDelete'), key, duration: 2 });
	};

	const handleClose = () => {
		setIsModalVisible(false);
		var items = professionalMilestoneSelectors.selectAll(store.getState() as any).filter(s => s.professionalId === professionalId && !s.isDeleted);
		setProfessionalMilestones(items);
	};

	const columns: ColumnProps<ProfessionalMilestone>[] = [
		{
			dataIndex: 'modifiedBy',
			key: 'modifiedBy',
			width: 20,
			render: (_text: string, item: ProfessionalMilestone): ReactNode => (
				<Popover
					content={
						<div>
							<Text>{t('general.modifiedBy')} </Text>
							<Text type="warning">{item.modifiedBy}</Text>
						</div>
					}
				>
					<InfoCircleTwoTone />
				</Popover>
			),
		},
		{
			title: t('professionals.professionalMilestoneType'),
			dataIndex: 'professionalMilestoneType',
			key: 'professionalMilestoneType',
			width: 250,
			render: (_text: string, item: ProfessionalMilestone): ReactNode => (
				<Text>{professionalMilestoneTypes.find(i => i.id === item.professionalMilestoneTypeId)?.description}</Text>
			),
		},
		{
			title: t('professionals.startDate'),
			dataIndex: 'startDate',
			key: 'startDate',
			width: 150,
			render: (_text: string, item: ProfessionalMilestone): ReactNode => <Text>{dayjs(item.startDateTime).format(dateFormat)}</Text>,
		},
		{
			title: t('professionals.endDate'),
			dataIndex: 'endDate',
			key: 'endDate',
			width: 150,
			render: (_text: string, item: ProfessionalMilestone): ReactNode => <Text>{dayjs(item.endDateTime).format(dateFormat)}</Text>,
		},
		{
			title: t('professionals.comments'),
			dataIndex: 'comments',
			key: 'comments',
			render: (_text: string, item: ProfessionalMilestone): ReactNode => <Text>{item.comments}</Text>,
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: ProfessionalMilestone): ReactNode => {
				return disabled || !isAuthorized('Component_Milestones') ? null : (
					<Space size="small">
						<Tooltip title={t('general.edit')}>
							<Button
								style={styles.button}
								type="primary"
								size="small"
								shape="round"
								onClick={() => {
									handleEdit(item);
								}}
								icon={<EditOutlined />}
							/>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDelete(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button size="small" danger shape="round" icon={<DeleteOutlined />} />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<Card
			size="small"
			title={t('professionals.professionalMilestones')}
			style={styles.card}
			extra={[
				disabled || !isAuthorized('Component_Milestones') ? null : (
					<Button type="primary" shape="round" icon={<PlusOutlined />} style={styles.buttonCard} onClick={handleAddMilestone}></Button>
				),
			]}
		>
			<Table<ProfessionalMilestone>
				key={`specialties-${Date.now()}`}
				rowKey={item => item.id}
				tableLayout="auto"
				size="small"
				dataSource={professionalMilestones}
				columns={columns}
				pagination={false}
				loading={isLoading}
			/>
			<FormProfessionalMilestoneEdit
				title={modalTitle}
				open={isModalVisible}
				professionalTypeId={professionalType}
				professionalId={professionalId}
				professionalMilestoneId={professionalMilestone.id}
				onClose={handleClose}
			/>
		</Card>
	);
};
export default FormProfessionalMilestones;
