import { useEffect, useState } from 'react';
import { Select, Upload, DatePicker, Form, Col, Row, message, Alert, Button, Space } from 'antd';
import { useStore } from 'react-redux';
import { accreditationDocumentsSelectors, accreditationDocumentTypeSelectors, professionalsSelectors } from '../../store/selectors';
import {
	AccreditationDocument,
	AccreditationDocumentStatus,
	AccreditationDocumentType,
	initialAccreditationDocumentValue,
	initialProfessionalValue,
	Professional,
} from '../../store/types';
import { DownCircleOutlined, InboxOutlined } from '@ant-design/icons';
import { dateFormat } from '../../assets/formats';
import { DraggerProps, RcFile } from 'antd/lib/upload';
import { baseStyles } from '../../assets/styles';
import { DocumentViewer, SizedModal } from '../../components';
import { useTranslation } from 'react-i18next';
import { uploadAccreditationDocument } from '../../store/api';
import { accreditationDocumentStatusSelectors } from '../../store/selectors/domains';
import { accreditationDocuments } from '../../store/reducers';
import dayjs from 'dayjs';
import { getProfessionalCertificate } from '../../store/api/professional';
import { base64toBlob } from '../../utils/util-generics';

const { Dragger } = Upload;
const { Option } = Select;
const key = 'saving';

const FormProfessionalAccreditationDocumentEdit = ({ professionalId, documentId, professionalType, open, showProperties, title, onClose }) => {
	const store = useStore();
	const styles = baseStyles;
	const [formProfessionalAccreditationDocumentEdit] = Form.useForm();
	const { t } = useTranslation();
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [professional, setProfessional] = useState<Professional>(initialProfessionalValue);
	const [accreditationDocumentTypes, setAccreditationDocumentTypes] = useState<AccreditationDocumentType[]>([]);
	const [accreditationDocumentStatuses, setAccreditationDocumentStatuses] = useState<AccreditationDocumentStatus[]>([]);
	const [accreditationDocument, setAccreditationDocument] = useState<AccreditationDocument>(initialAccreditationDocumentValue);
	const [isValidData, setIsValidData] = useState<boolean>(true);
	const [isPDF, setIsPDF] = useState<boolean>(true);
	const [url, setUrl] = useState<string>('');
	const [file, setFile] = useState<RcFile>(undefined);
	const [modified, setModified] = useState<boolean>(false);

	useEffect(() => {
		var item: AccreditationDocument = accreditationDocumentsSelectors.selectById(store.getState() as any, documentId) || {
			...initialAccreditationDocumentValue,
			professionalId: professionalId,
		};
		setProfessional(professionalsSelectors.selectById(store.getState() as any, professionalId));
		var accreditationDocumentTypeItems = accreditationDocumentTypeSelectors
			.selectAll(store.getState() as any)
			.filter(ad => ad.professionalTypeId === professionalType || ad.professionalTypeId === undefined);
		setAccreditationDocumentTypes(accreditationDocumentTypeItems);
		var accreditationDocumentStatusItems = accreditationDocumentStatusSelectors.selectAll(store.getState() as any).filter(ads => !ads.isDeleted);
		setAccreditationDocumentStatuses(accreditationDocumentStatusItems);
		setAccreditationDocument(item);
		setIsVisible(open);
		setUrl(item.url);
		setIsPDF(true);
		formProfessionalAccreditationDocumentEdit.setFieldsValue({
			documentType: item.documentTypeId !== 0 ? item.documentTypeId : accreditationDocumentTypeItems[0].id,
			documentDate: dayjs(item.documentDate || Date.now()),
			documentUploadDateTime: dayjs(item.documentUploadDateTime || Date.now()),
			documentExpirationDate: dayjs(item.documentExpirationDate || Date.now()),
			documentStatus: item.documentStatusId !== 0 ? item.documentStatusId : accreditationDocumentStatusItems[0].id,
		});
	}, [documentId, formProfessionalAccreditationDocumentEdit, open, professionalId, professionalType, store]);

	const draggerProps: DraggerProps = {
		name: 'file',
		multiple: false,
		maxCount: 1,
		style: {
			width: 300,
		},
		showUploadList: false,
		accept: 'application/pdf',
		onRemove: () => {
			setFile(undefined);
		},
		beforeUpload: (file: RcFile): boolean => {
			if (file.type === 'application/pdf') {
				setModified(true);
				var url = URL.createObjectURL(file);
				setIsPDF(true);
				setFile(file);
				setUrl(url);
			} else {
				setIsPDF(false);
			}
			return false;
		},
	};

	const getUploadedFile = (e: { filelist: RcFile[] }) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.filelist;
	};

	const handleValuesChange = (changedValues: any) => {
		setModified(true);
		if (changedValues['documentType']) {
			var tAccreditationDocumentType = accreditationDocumentTypes.find(a => a.id === changedValues['documentType']);
			if (tAccreditationDocumentType === undefined) {
				setIsValidData(false);
			} else {
				setIsValidData(true);
			}
		}
		if (changedValues['documentStatus']) {
			var tDocumentStatus = accreditationDocumentStatuses.find(a => a.id === changedValues['documentStatus']);
			if (tDocumentStatus === undefined) {
				setIsValidData(false);
			} else {
				setIsValidData(true);
			}
		}
	};

	const handleGetDocumentFromSuperSalud = async () => {
		try {
			var socialId = professional.socialId;
			message.loading({ content: t('messages.fetchingData'), key, duration: 0 });
			var result = await getProfessionalCertificate(socialId);
			if (result !== undefined) {
				var file = base64toBlob(result, 'application/pdf') as RcFile;
				formProfessionalAccreditationDocumentEdit.setFieldsValue({
					documentType: accreditationDocumentTypes.find(d => d.code === 'CRN').id,
					documentStatus: accreditationDocumentStatuses.find(s => s.code === 'VA').id,
					documentDate: dayjs(Date.now()),
					documentUploadDateTime: dayjs(Date.now()),
				});
				setFile(file);
				var url = URL.createObjectURL(file);
				setUrl(url);
			}
			message.loading({ content: t('messages.dataFetched'), key, duration: 2 });
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnTransaction'), key, duration: 2 });
		}
	};

	const handleSave = async () => {
		if (!isValidData && modified) {
			return;
		}
		const values = await formProfessionalAccreditationDocumentEdit.validateFields();
		if (values.errorField === undefined) {
			var temp: AccreditationDocument = {
				...accreditationDocument,
				documentDate: values['documentUploadDateTime'].toDate(),
				documentTypeId: values['documentType'],
				documentStatusId: values['documentStatus'],
				documentUploadDateTime: values['documentUploadDateTime'].toDate(),
				documentExpirationDate: values['documentExpirationDate'].toDate(),
				modified: modified,
			};
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			var response = await uploadAccreditationDocument(temp, file);
			if (response !== undefined) {
				store.dispatch(accreditationDocuments.actions.accreditationDocumentCreateUpdate(response));
				message.success({ content: t('messages.dataSaved'), key, duration: 2 });
			} else message.info({ content: t('messages.errorOnSave'), key, duration: 2 });
			setIsPDF(true);
		}
		onClose();
	};

	const handleCancel = () => {
		onClose();
	};

	return (
		<SizedModal size="medium" title={title} open={isVisible} onOk={handleSave} onCancel={handleCancel}>
			{showProperties ? (
				<Row gutter={[12, 12]}>
					<Col span={9}>
						<Form
							{...baseStyles.formLayout}
							form={formProfessionalAccreditationDocumentEdit}
							onValuesChange={handleValuesChange}
							name="accreditationDocument-details"
						>
							<Form.Item label={t('general.type')} name="documentType" rules={[{ required: true, message: t('messages.enterType') }]}>
								<Select>
									{accreditationDocumentTypes
										.filter(adt => !adt.isDeleted)
										.map(adt => {
											return (
												<Option key={`accreditationDocumentType-${adt.id}`} value={adt.id}>
													{adt.description}
												</Option>
											);
										})}
								</Select>
							</Form.Item>
							<Form.Item label={t('general.status')} name="documentStatus" rules={[{ required: true, message: t('messages.enterType') }]}>
								<Select>
									{accreditationDocumentStatuses.map(ads => {
										return (
											<Option key={`accreditationDocumentStatus-${ads.id}`} value={ads.id}>
												{ads.description}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
							<Form.Item label={t('professionals.documentUploadDate')} name="documentUploadDateTime">
								<DatePicker style={styles.datePicker} disabled format={dateFormat} />
							</Form.Item>
							<Form.Item
								label={t('professionals.expirationDate')}
								name="documentExpirationDate"
								rules={[{ required: true, message: t('messages.enterValue') }]}
							>
								<DatePicker style={styles.datePicker} format={dateFormat} placeholder={t('messages.enterValue')} />
							</Form.Item>
							<Form.Item label={t('general.file')} name="upload" valuePropName="filelist" getValueFromEvent={getUploadedFile}>
								<Space direction="vertical">
									<Dragger {...draggerProps}>
										<p className="ant-upload-drag-icon">
											<InboxOutlined />
										</p>
									</Dragger>
									<Button type="primary" icon={<DownCircleOutlined />} shape="round" onClick={handleGetDocumentFromSuperSalud} hidden>
										{t('professionals.getDocumentFromSuperSalud')}
									</Button>
								</Space>
							</Form.Item>
							{!isValidData ? <Alert message={t('messages.correctErrorsToContinue')} type="error" /> : null}
							{!isPDF ? <Alert message={t('messages.notPDF')} type="error" /> : null}
						</Form>
					</Col>
					<Col span={15}>
						<DocumentViewer url={url} size="medium"></DocumentViewer>
					</Col>
				</Row>
			) : (
				<DocumentViewer url={url} size="large"></DocumentViewer>
			)}
		</SizedModal>
	);
};
export default FormProfessionalAccreditationDocumentEdit;
