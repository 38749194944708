import { useState, ReactNode, useEffect } from 'react';
import { Table, Tooltip, Button, Space, Typography, message, Popconfirm, Card } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { emails as emailsReducer } from '../../store/reducers';
import { useStore } from 'react-redux';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { emailsSelectors, emailTypesSelectors } from '../../store/selectors';
import { Email, EmailType, initialEmailValue } from '../../store/types';
import { deleteEmail, fetchProfessionalEmailsById } from '../../store/api';
import { useTranslation } from 'react-i18next';
import { baseStyles } from '../../assets/styles';
import FormProfessionalEmailEdit from './form-professional-email-edit';

const key = 'saving';
const { Text } = Typography;
/**
 * Form to display a table of emails
 */
const FormProfessionalEmails = ({ professionalId }) => {
	const store = useStore();
	const styles = baseStyles;
	const id = professionalId;
	const { t } = useTranslation();
	const [emailTypes, setEmailTypes] = useState<EmailType[]>([]);
	const [emails, setEmails] = useState<Email[]>([]);
	const [email, setEmail] = useState<Email>(initialEmailValue);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setEmailTypes(emailTypesSelectors.selectAll(store.getState() as any));
		setIsLoading(true);
		fetchProfessionalEmailsById(professionalId).then(items => {
			store.dispatch(emailsReducer.actions.emailsLoading());
			store.dispatch(emailsReducer.actions.emailsReceived(items));
			setEmails(items.filter(s => !s.isDeleted));
			setIsLoading(false);
		});
	}, [professionalId, store]);

	const handleEdit = (item: Email) => {
		setModalTitle(t('professionals.editEmail'));
		setEmail(item);
		setIsModalVisible(true);
	};

	const handleAdd = () => {
		setModalTitle(t('professionals.editEmail'));
		setEmail({ ...initialEmailValue, professionalId: id });
		setIsModalVisible(true);
	};

	const handleDelete = async (item: Email) => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var response = await deleteEmail(item);
		if (response) {
			setIsLoading(true);
			fetchProfessionalEmailsById(professionalId).then(items => {
				store.dispatch(emailsReducer.actions.emailsLoading());
				store.dispatch(emailsReducer.actions.emailsReceived(items));
				setEmails(items.filter(s => !s.isDeleted));
				setIsLoading(false);
				message.success({ content: t('messages.itemDeleted'), key, duration: 2 });
			});
		} else message.info({ content: t('message.unableToDelete'), key, duration: 2 });
	};

	const handleClose = () => {
		setIsModalVisible(false);
		setEmails(emailsSelectors.selectAll(store.getState() as any).filter(e => e.professionalId === id && !e.isDeleted));
	};

	const columns: ColumnProps<Email>[] = [
		{
			title: t('professionals.emailUrl'),
			dataIndex: 'url',
			key: 'url',
		},
		{
			title: t('professionals.emailType'),
			dataIndex: 'emailType',
			key: 'emailType',
			ellipsis: true,
			width: 120,
			render: (_text: string, item: Email): ReactNode => <Text>{emailTypes?.find(e => e.id === item?.emailTypeId)?.description}</Text>,
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: Email): ReactNode => {
				return (
					<Space size="small">
						<Tooltip title={t('general.edit')}>
							<Button type="primary" shape="round" onClick={() => handleEdit(item)}>
								<EditOutlined />
							</Button>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDelete(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button danger shape="round">
									<DeleteOutlined />
								</Button>
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<Card
			size="small"
			title={t('professionals.emails')}
			style={styles.card}
			extra={<Button type="primary" shape="round" icon={<PlusOutlined />} style={styles.buttonCard} onClick={handleAdd}></Button>}
		>
			<Table<Email> rowKey={item => item.id} tableLayout="auto" size="small" dataSource={emails} columns={columns} pagination={false} loading={isLoading} />
			<FormProfessionalEmailEdit title={modalTitle} open={isModalVisible} emailId={email.id} professionalId={id} onClose={handleClose} />
		</Card>
	);
};
export default FormProfessionalEmails;
