import { ReactNode, useState } from 'react';
import { Table, Popconfirm, Space, Tooltip, Button, Col, Row, message, Input, Form, Alert, Card } from 'antd';
import { DocumentAttachment, initialDocumentAttachmentValue, ParentType } from '../../store/types';
import { ColumnProps } from 'antd/lib/table';
import { DeleteOutlined, PlusOutlined, InboxOutlined, EditOutlined, ZoomInOutlined } from '@ant-design/icons';
import { useStore } from 'react-redux';
import { SizedModal, DocumentViewer } from '../../components';
import { RcFile } from 'antd/lib/upload';
import { createUpdateDocumentAttachment, deleteDocumentAttachment } from '../../store/api';
import { documentAttachments } from '../../store/reducers';
import { documentsAttachmentSelectors } from '../../store/selectors';
import { baseStyles, colors } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger';

const key = 'saving';

const FormAttachments = (props: { parentId: number; parentType: ParentType }) => {
	const store = useStore();
	const state = store.getState() as any;
	const { t } = useTranslation();
	const styles = baseStyles;
	const [formAttachments] = Form.useForm();
	const [isValidData, setIsValidData] = useState<boolean>(true);
	const [attachment, setAttachment] = useState<DocumentAttachment>(initialDocumentAttachmentValue);
	const [attachments, setAttachments] = useState<DocumentAttachment[]>([]);
	const [description, setDescription] = useState<string>('');
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [isViewerVisible, setIsViewerVisible] = useState<boolean>(false);
	const [title, setTitle] = useState<string>('');
	const [isPDF, setIsPDF] = useState<boolean>(true);
	const [url, setUrl] = useState<string>('');
	const [file, setFile] = useState<RcFile>();
	const [modified, setModified] = useState<boolean>(false);
	const [localUrl, setLocalUrl] = useState<string>('');

	useEffect(() => {
		setAttachments(
			documentsAttachmentSelectors.selectAll(state).filter(a => !a.isDeleted && a.parentId === props.parentId && a.parentType === props.parentType),
		);
		url === '' && localUrl === '' ? setIsViewerVisible(false) : setIsViewerVisible(true);
	}, [state, props, description, formAttachments, url, localUrl]);

	const handleAddAttachment = () => {
		var temp: DocumentAttachment = { ...initialDocumentAttachmentValue, parentId: props.parentId, parentType: props.parentType };
		setAttachment(temp);
		setTitle(t('general.addAttachment'));
		setDescription(temp.description);
		formAttachments.setFieldsValue({
			description: temp.description,
		});
		setUrl(temp.url);
		setIsViewerVisible(false);
		setIsModalVisible(true);
	};

	const handleEditDocument = (item: DocumentAttachment) => {
		setAttachment(item);
		setTitle(t('general.editAttachment'));
		setDescription(item.description);
		formAttachments.setFieldsValue({
			description: item.description,
		});
		setIsViewerVisible(false);
		setIsModalVisible(true);
	};

	const handleDeleteAttachment = async (item: DocumentAttachment) => {
		var response = await deleteDocumentAttachment(item);
		if (response) {
			item = { ...item, isDeleted: true };
			store.dispatch(documentAttachments.actions.attachmentCreateUpdate(item));
			setAttachments(
				documentsAttachmentSelectors.selectAll(state).filter(a => !a.isDeleted && a.parentId === props.parentId && a.parentType === props.parentType),
			);
		}
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		setUrl('');
		setLocalUrl('');
		setFile(undefined);
		setIsValidData(true);
		setIsPDF(true);
	};

	const draggerProps: DraggerProps = {
		name: 'file',
		multiple: false,
		maxCount: 1,
		showUploadList: false,
		accept: 'application/pdf',
		onRemove: () => {
			setFile(undefined);
		},
		beforeUpload: (file: RcFile): boolean => {
			if (file.type === 'application/pdf') {
				setModified(true);
				var url = URL.createObjectURL(file);
				setIsPDF(true);
				setFile(file);
				setLocalUrl(url);
			} else {
				setIsPDF(false);
			}
			return false;
		},
	};

	const columns: ColumnProps<DocumentAttachment>[] = [
		{
			title: t('general.description'),
			dataIndex: 'description',
			key: 'description',
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			render: (_text: string, item: DocumentAttachment): ReactNode => {
				return (
					<Space size="small">
						<Tooltip title={t('general.view')}>
							<Button
								style={styles.button}
								type="primary"
								shape="round"
								onClick={() => {
									handleEditDocument(item);
								}}
							>
								<EditOutlined />
							</Button>
						</Tooltip>
						<Tooltip title={t('general.view')}>
							<Button
								style={styles.button}
								type="primary"
								shape="round"
								onClick={() => {
									window.open(item.url, '_blank');
								}}
							>
								<ZoomInOutlined />
							</Button>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteAttachment(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button danger shape="round" icon={<DeleteOutlined />} />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const handleSave = async () => {
		if (modified) {
			formAttachments
				.validateFields()
				.then(async values => {
					setIsModalVisible(false);
					message.loading({ content: t('messages.saving'), key, duration: 0 });
					var temp: DocumentAttachment = {
						...attachment,
						description: values['description'],
						parentId: props.parentId,
						parentType: props.parentType,
					};
					var response = await createUpdateDocumentAttachment(temp, file);
					if (response !== undefined) {
						store.dispatch(documentAttachments.actions.attachmentCreateUpdate(response));
						setAttachments(
							documentsAttachmentSelectors.selectAll(state).filter(a => !a.isDeleted && a.parentId === props.parentId && a.parentType === props.parentType),
						);
						message.success({ content: t('messages.dataSaved'), key, duration: 2 });
					} else message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
				})
				.catch(errors => {
					setIsValidData(false);
				});
		} else setIsModalVisible(false);
	};

	return (
		<div>
			<Card
				size="small"
				title={t('news.attachmentFiles')}
				style={{ ...styles.card, width: '95%' }}
				extra={[
					<Button type="primary" style={{ backgroundColor: colors.brandColor2 }} shape="round" icon={<PlusOutlined />} onClick={handleAddAttachment}></Button>,
				]}
			>
				<Table<DocumentAttachment>
					rowKey={record => record.id}
					tableLayout="auto"
					size="middle"
					dataSource={attachments}
					columns={columns}
					pagination={false}
				/>
			</Card>
			<SizedModal
				size={url === '' && localUrl === '' ? 'small' : 'medium'}
				open={isModalVisible}
				title={title}
				okButtonProps={{
					shape: 'round',
					type: 'primary',
				}}
				okText={t('general.ok')}
				cancelText={t('general.cancel')}
				cancelButtonProps={{
					shape: 'round',
					type: 'primary',
				}}
				onOk={handleSave}
				onCancel={handleCancel}
				destroyOnClose={true}
			>
				<Row gutter={[12, 12]}>
					<Col span={isViewerVisible ? 9 : 24}>
						<Form
							{...baseStyles.formLayout}
							form={formAttachments}
							initialValues={{
								description: description,
							}}
							name="attachment-details"
						>
							<Form.Item label={t('general.description')} name="description" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('general.file')}>
								<div>
									<Dragger {...draggerProps}>
										<p className="ant-upload-drag-icon">
											<InboxOutlined />
										</p>
									</Dragger>
								</div>
							</Form.Item>
						</Form>
						{!isValidData ? <Alert message={t('messages.correctErrorsToContinue')} type="error" /> : null}
						{!isPDF ? <Alert message={t('messages.notPDF')} type="error" /> : null}
					</Col>
					{isViewerVisible ? (
						<Col span={15}>
							<DocumentViewer url={localUrl === '' ? url : localUrl} size="medium"></DocumentViewer>
						</Col>
					) : null}
				</Row>
			</SizedModal>
		</div>
	);
};

export default FormAttachments;
