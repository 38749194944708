import { useEffect, useState } from 'react';
import { AutoComplete, Avatar, Breadcrumb, Card, Col, FloatButton, Layout, Row, Select, Space, Tag, Typography } from 'antd';
import { useStore } from 'react-redux';
import { linkItemsSelectors, tagsSelectors } from '../../store/selectors';
import { useTranslation } from 'react-i18next';
import { LinkItem, Tag as TagItem } from '../../store/types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingOutlined, MedicineBoxOutlined, UserOutlined } from '@ant-design/icons';
import { baseStyles, colors } from '../../assets/styles';
import { arrayIncludes, includes } from '../../utils/util-custom-validators';

const { Text, Title } = Typography;
const { Option } = Select;
const { Content } = Layout;

const PageViewLinks = () => {
	const store = useStore();
	const state = store.getState() as any;
	const { t } = useTranslation();
	const extraItems = 50;
	const cardWidth = 300;
	const cardHeight = 350;
	const styles = baseStyles;
	const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);
	const [linkItems, setLinkItems] = useState<LinkItem[]>([]);
	const [partialLinkItems, setPartialLinkItems] = useState<LinkItem[]>([]);
	const [tags, setTags] = useState<TagItem[]>([]);
	const [index, setIndex] = useState<number>(50);
	const [hasMore, setHasMore] = useState<boolean>(true);

	window.onresize = () => {
		setInnerWidth(window.innerWidth);
	};

	useEffect(() => {
		var selectedTags = [];
		var items = linkItemsSelectors.selectAll(state).filter(ni => !ni.isDeleted && ni.isPublished);
		items.forEach(i => (selectedTags = selectedTags.concat(i.tags)));
		selectedTags = selectedTags.filter((item, pos, self) => {
			return self.indexOf(item) === pos;
		});
		setTags(
			tagsSelectors
				.selectAll(state)
				.filter(t => selectedTags.includes(t.description))
				.sort((a, b) => a.description.localeCompare(b.description)),
		);
		setLinkItems(items);
		setPartialLinkItems(items.slice(0, items.length > index ? index - 1 : items.length));
		setHasMore(items.length > index ? true : false);
	}, [index, state]);

	const handleNext = () => {
		var step = extraItems;
		if (index + extraItems > linkItems.length) {
			step = linkItems.length - index;
			setHasMore(false);
		}
		setPartialLinkItems(partialLinkItems.concat(linkItems.slice(index, step)));
		setIndex(index + step);
	};

	const handleSelect = option => {
		var selected = linkItemsSelectors.selectById(state, option);
		window.open(selected.url, '_blank', 'noopener,noreferrer');
	};

	const handleSearch = (value: string) => {
		if (value && value.length > 3) {
			setHasMore(false);
			setPartialLinkItems(linkItemsSelectors.selectAll(state).filter(d => includes(d.title, value)));
		} else {
			setIndex(50);
			setPartialLinkItems(linkItems.slice(0, linkItems.length > index ? index - 1 : linkItems.length));
			setHasMore(linkItems.length > index ? true : false);
		}
	};

	const handleClear = () => {
		setIndex(50);
		setPartialLinkItems(linkItems.slice(0, linkItems.length > index ? index - 1 : linkItems.length));
		setHasMore(linkItems.length > index ? true : false);
	};

	const handleChangeTag = value => {
		var items = [];
		if (value) {
			items = linkItemsSelectors
				.selectAll(state)
				.filter(ni => arrayIncludes(ni.tags, value) && !ni.isDeleted && ni.isPublished)
				.sort((a, b) => a.title.localeCompare(b.title));
			setPartialLinkItems(items);
			setHasMore(false);
		} else {
			items = linkItemsSelectors
				.selectAll(state)
				.filter(ni => !ni.isDeleted && ni.isPublished)
				.sort((a, b) => a.title.localeCompare(b.title));
			setPartialLinkItems(items.slice(0, items.length > index ? index - 1 : items.length));
			setHasMore(true);
		}
		setLinkItems(items);
	};

	return (
		<div>
			<Col>
				<Row>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Breadcrumb
							separator=">"
							items={[{ title: <MedicineBoxOutlined twoToneColor={colors.brandColor1} /> }, { title: t('view.viewContent') }, { title: t('view.links') }]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}></Col>
				</Row>
				<Content className="site-layout-background">
					<Row>
						<Col key="col1" flex={3}>
							<Space direction="horizontal">
								<Text strong>{t('general.searchEngine')}</Text>
								<AutoComplete
									popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
									style={styles.search}
									placeholder={t('general.search')}
									allowClear
									onClear={handleClear}
									onSelect={handleSelect}
									onSearch={handleSearch}
								/>
								<Text strong>{t('general.filters')}</Text>
								<Select style={{ width: 250 }} onChange={handleChangeTag} placeholder={t('messages.selectTagToFilter')} allowClear>
									{tags.map(pt => {
										return (
											<Option key={`tags-${pt.id}`} value={pt.description}>
												{pt.description}
											</Option>
										);
									})}
								</Select>
							</Space>
						</Col>
						<Col key="col2" flex={4}></Col>
						<Col key="col3" flex={3}></Col>
					</Row>
					{linkItems.length > 0 ? (
						<InfiniteScroll
							key={`infiniteScroll`}
							dataLength={partialLinkItems.length}
							next={handleNext}
							hasMore={hasMore}
							loader={<LoadingOutlined />}
							style={{ overflowX: 'hidden', paddingTop: 10 }}
						>
							<Row gutter={[24, 24]}>
								<FloatButton.BackTop />
								{partialLinkItems.map((item, index) => {
									return (
										<Col key={`col-${index}`} span={innerWidth / cardWidth}>
											<Card
												id={`card-${index}`}
												style={{ width: cardWidth, height: cardHeight }}
												actions={[
													<Text
														onClick={() => {
															handleSelect(item.id);
														}}
													>
														{t('general.view')}
													</Text>,
												]}
											>
												<Space direction="vertical" style={{ width: '100%', justifyContent: 'center' }}>
													<Row justify="center" style={{ paddingTop: 20 }}>
														{item.avatar !== '' ? (
															<Avatar key={`avatar-pic-${index}`} size={120} src={`${item.avatar}?${Date.now()}`} />
														) : (
															<Avatar key={`avatar-icon-${index}`} size={120} icon={<UserOutlined />} />
														)}
													</Row>
													<Row justify="center">
														<Title
															level={4}
															style={{ textAlign: 'center' }}
															ellipsis={{
																rows: 3,
																expandable: false,
																tooltip: true,
															}}
														>{`${item.title}`}</Title>
													</Row>
													<Row justify="center">
														<Col>
															{item.tags.map(t => (
																<Tag style={styles.tag} key={t}>
																	{t.toUpperCase()}
																</Tag>
															))}
														</Col>
													</Row>
												</Space>
											</Card>
										</Col>
									);
								})}
							</Row>
						</InfiniteScroll>
					) : null}
				</Content>
			</Col>
		</div>
	);
};

export default PageViewLinks;
