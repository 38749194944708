import { useEffect, useState } from 'react';
import { DatePicker, Form, Input, message, Select } from 'antd';
import { useStore } from 'react-redux';
import { ProfessionalMilestoneType, initialProfessionalMilestoneValue, ProfessionalMilestone } from '../../store/types';
import { professionalMilestoneTypeSelectors } from '../../store/selectors/domains';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { professionalMilestones } from '../../store/reducers';
import { SizedModal } from '..';
import { createUpdateProfessionalMilestone } from '../../store/api';
import { professionalMilestoneSelectors } from '../../store/selectors';
import dayjs from 'dayjs';

const key = 'saving';
const { Option } = Select;
const { TextArea } = Input;

const { RangePicker } = DatePicker;
/**
 * Form to display a form for an specialty
 */
const FormProfessionalMilestoneEdit = (props: {
	professionalId: number;
	professionalMilestoneId: number;
	professionalTypeId: number;
	open: boolean;
	title: string;
	onClose: () => void;
}) => {
	const store = useStore();
	const [formProfessionalMilestoneEdit] = Form.useForm();
	const { t } = useTranslation();
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [professionalMilestoneTypes, setProfessionalMilestoneTypes] = useState<ProfessionalMilestoneType[]>([]);
	const [professionalMilestone, setProfessionalMilestone] = useState<ProfessionalMilestone>(initialProfessionalMilestoneValue);

	useEffect(() => {
		var professionalMilestoneTypeItems = professionalMilestoneTypeSelectors.selectAll(store.getState() as any).filter(mt => !mt.isDeleted);
		setProfessionalMilestoneTypes(professionalMilestoneTypeItems);
		var item = professionalMilestoneSelectors.selectById(store.getState() as any, props.professionalMilestoneId) || {
			...initialProfessionalMilestoneValue,
			professionalId: props.professionalId,
		};
		setProfessionalMilestone(item);
		setIsVisible(props.open);
		formProfessionalMilestoneEdit.setFieldsValue({
			professionalMilestoneType: item.professionalMilestoneTypeId,
			startEndDateTime: [dayjs(item.startDateTime), dayjs(item.endDateTime)],
			isActive: item.isActive,
			comments: item.comments,
		});
	}, [formProfessionalMilestoneEdit, props, store]);

	const handleCancel = () => {
		props.onClose();
	};

	const handleOk = async () => {
		var values = await formProfessionalMilestoneEdit.validateFields();
		if (values.errorField === undefined) {
			var temp: ProfessionalMilestone = {
				...professionalMilestone,
				startDateTime: values['startEndDateTime'][0].toDate(),
				endDateTime: values['startEndDateTime'][1].toDate(),
				comments: values['comments'],
				professionalMilestoneTypeId: values['professionalMilestoneType'] || 0,
			};
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			var result = await createUpdateProfessionalMilestone(temp, props.professionalId);
			if (result !== undefined) {
				store.dispatch(professionalMilestones.actions.professionalMilestoneCreateUpdate(result));
				message.success({ content: t('messages.dataSaved'), key, duration: 1 });
			} else message.info({ content: t('messages.errorOnSave'), key, duration: 1 });
		}
		props.onClose();
	};

	return (
		<SizedModal size="small" title={props.title} open={isVisible} onOk={handleOk} onCancel={handleCancel}>
			<Form {...baseStyles.formLayout} form={formProfessionalMilestoneEdit} name="professionalMilestoneType-details">
				<Form.Item
					label={t('professionals.professionalMilestoneType')}
					name="professionalMilestoneType"
					rules={[{ required: true, message: t('messages.enterType') }]}
				>
					<Select>
						{professionalMilestoneTypes.map(p => {
							return (
								<Option key={`professionalMilestoneTypes-${p.id}`} value={p.id}>
									{p.description}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item label={t('professionals.startEndDate')} name="startEndDateTime" rules={[{ required: true, message: 'Debe ingresar un Nombre' }]}>
					<RangePicker format="DD-MM-YYYY" />
				</Form.Item>
				<Form.Item label={t('professionals.comments')} name="comments" rules={[{ required: false }]}>
					<TextArea />
				</Form.Item>
			</Form>
		</SizedModal>
	);
};
export default FormProfessionalMilestoneEdit;
