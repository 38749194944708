import { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Row, Space } from 'antd';
import { professionalTypeSelectors } from '../../store/selectors/domains';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'react-redux';
import { AuthRoutes, FormProfessionalList } from '../../components';
import { useTranslation } from 'react-i18next';
import { IdcardTwoTone, PlusOutlined } from '@ant-design/icons';
import { colors } from '../../assets/styles';

const route = (professionalTypeCode: string): string => {
	var route = '';
	switch (professionalTypeCode) {
		case 'ME':
			route = AuthRoutes.physicians;
			break;
		case 'EN':
			route = AuthRoutes.nurses;
			break;
		case 'KS':
			route = AuthRoutes.kinesiologists;
			break;
		case 'PS':
			route = AuthRoutes.psychologists;
			break;
		case 'OD':
			route = AuthRoutes.dentists;
			break;
		case 'NU':
			route = AuthRoutes.nutritionists;
			break;
		case 'TM':
			route = AuthRoutes.medicalTechnologists;
			break;
	}
	return route;
};

/// Render the tables for nurses
const PageProfessionalsIndex = () => {
	const store = useStore();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const location = useLocation() as any;
	const [professionalTypeCode, setProfessionalTypeCode] = useState<string>('');
	const [professionalType, setProfessionalType] = useState<string>('');
	const [professionalTypeId, setProfessionalTypeId] = useState<number>(0);

	useEffect(() => {
		setProfessionalTypeCode(location.state.professionalCode);
		var item = professionalTypeSelectors.selectAll(store.getState() as any).find(p => p.code === location.state.professionalCode);
		setProfessionalTypeId(item.id);
		setProfessionalType(item.description);
	}, [location, store]);

	return (
		<div>
			<Row>
				<Col>
					<Breadcrumb
						separator=">"
						style={{ height: 31, margin: '16px 24px 0 0' }}
						items={[{ title: <IdcardTwoTone twoToneColor={colors.brandColor1} /> }, { title: t('general.professionals') }, { title: professionalType }]}
					/>
				</Col>
				<Col flex={1}></Col>
				<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
					<Space>
						<Button
							type="primary"
							icon={<PlusOutlined />}
							onClick={() => {
								navigate(route(professionalTypeCode) + `/details`, {
									state: { id: 0, professionalTypeId: professionalTypeId, disabled: false, route: location.pathname },
								});
							}}
							shape="round"
						>
							{t('general.add')}
						</Button>
					</Space>
				</Col>
			</Row>
			<Row>
				<FormProfessionalList professionalTypeCode={professionalTypeCode} />
			</Row>
		</div>
	);
};

export default PageProfessionalsIndex;
