import { Typography, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const FormConfirmExitWithoutSaving = ({ open, onOk, onCancel }) => {
	const { t } = useTranslation();

	return (
		<Modal
			getContainer={false}
			title={t('messages.confirmExit')}
			okButtonProps={{
				shape: 'round',
				type: 'primary',
			}}
			open={open}
			onOk={onOk}
			onCancel={onCancel}
			okText={t('general.ok')}
			cancelText={t('general.cancel')}
			cancelButtonProps={{
				shape: 'round',
				type: 'primary',
			}}
		>
			<Text>{t('messages.exitWithoutSaving')}</Text>
		</Modal>
	);
};

export default FormConfirmExitWithoutSaving;
