import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { Center, Network, Service, Equipment, Infrastructure } from '../types';

export const networkAdapter = createEntityAdapter({
	selectId: (item: Network) => item.id,
});

export const networks = createSlice({
	name: 'networks',
	initialState: networkAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		networkAdded: networkAdapter.addOne,
		networkLoading: state => {
			if (!state.loading) state.loading = true;
		},
		networkReceived: (state, action) => {
			if (state.loading) {
				networkAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		networkCreateUpdate: networkAdapter.upsertOne,
	},
});

export const centerAdapter = createEntityAdapter({
	selectId: (item: Center) => item.id,
});

export const centers = createSlice({
	name: 'centers',
	initialState: centerAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		centerAdded: centerAdapter.addOne,
		centerLoading: state => {
			if (!state.loading) state.loading = true;
		},
		centerReceived: (state, action) => {
			if (state.loading) {
				centerAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		centerCreateUpdate: centerAdapter.upsertOne,
	},
});

export const infrastructureAdapter = createEntityAdapter({
	selectId: (item: Infrastructure) => item.id,
});

export const infrastructures = createSlice({
	name: 'infrastructures',
	initialState: infrastructureAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		infrastructureAdded: infrastructureAdapter.addOne,
		infrastructureLoading: state => {
			if (!state.loading) state.loading = true;
		},
		infrastructureReceived: (state, action) => {
			if (state.loading) {
				infrastructureAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		infrastructureCreateUpdate: infrastructureAdapter.upsertOne,
	},
});

export const equipmentAdapter = createEntityAdapter({
	selectId: (item: Equipment) => item.id,
});

export const equipments = createSlice({
	name: 'equipments',
	initialState: equipmentAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		equipmentAdded: equipmentAdapter.addOne,
		equipmentLoading: state => {
			if (!state.loading) state.loading = true;
		},
		equipmentReceived: (state, action) => {
			if (state.loading) {
				equipmentAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		equipmentCreateUpdate: equipmentAdapter.upsertOne,
	},
});

export const serviceAdapter = createEntityAdapter({
	selectId: (item: Service) => item.id,
});

export const services = createSlice({
	name: 'services',
	initialState: serviceAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		serviceAdded: serviceAdapter.addOne,
		serviceLoading: state => {
			if (!state.loading) state.loading = true;
		},
		serviceReceived: (state, action) => {
			if (state.loading) {
				serviceAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		serviceCreateUpdate: serviceAdapter.upsertOne,
	},
});
