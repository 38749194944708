import { Breadcrumb, Col, Layout, Row, Tabs } from 'antd';
import { FormConfigurationPermissions, FormConfigurationProfiles, FormConfigurationUserIndex } from '../../components';
import { useTranslation } from 'react-i18next';
import { BarsOutlined, ControlTwoTone, TeamOutlined, UnlockOutlined } from '@ant-design/icons';
import { colors } from '../../assets/styles';

const { Content } = Layout;

const PageConfigurationUsers = () => {
	const { t } = useTranslation();

	const tabs = [
		{
			icon: <TeamOutlined />,
			label: t('configurations.users'),
			key: 0,
			children: <FormConfigurationUserIndex />,
		},
		{
			icon: <BarsOutlined />,
			label: t('configurations.profiles'),
			key: 1,
			children: <FormConfigurationProfiles />,
		},
		{
			icon: <UnlockOutlined />,
			label: t('configurations.permissions'),
			key: 2,
			children: <FormConfigurationPermissions />,
		},
	];

	return (
		<div>
			<Col>
				<Row>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Breadcrumb
							separator=">"
							items={[
								{ title: <ControlTwoTone twoToneColor={colors.brandColor1} /> },
								{
									title: t('general.configuration'),
								},
								{ title: t('general.users') },
							]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col></Col>
				</Row>
				<Content className="site-layout-background">
					<Tabs
						type="card"
						defaultActiveKey="0"
						style={{ width: '100%', padding: 10 }}
						items={tabs.map(item => {
							return {
								label: item.label,
								icon: item.icon,
								key: item.key.toString(),
								children: item.children,
							};
						})}
					></Tabs>
				</Content>
			</Col>
		</div>
	);
};

export default PageConfigurationUsers;
