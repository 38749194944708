export interface User {
    [key: string]: any;
    id: number;
    username: string;
    externalId: string;
    familyName?: string;
    givenName?: string;
    imageUrl?: string;
    name?: string;
    accessToken?: string;
    professionalId: number;
    networkId: number;
    expoPushToken?: string;
    profileIds?: number[];
    permissionIds?: number[];
    centerIds?: number[];
    mobileLogins: number;
    webLogins: number;
    isDeleted: boolean;
}

export const initialUserValue: User = {
    id: 0,
    username: "",
    externalId: "",
    familyName: "",
    givenName: "",
    imageUrl: "",
    name: "",
    accessToken: "",
    professionalId: 0,
    networkId: 0,
    profileIds: [] as number[],
    permissionIds: [] as number[],
    centerIds: [] as number[],
    mobileLogins: 0,
    webLogins: 0,
    isDeleted: false,
};

export interface UserAuthenticationStatus {
    isAuthenticated: boolean;
    isAllowed: boolean;
    restrictedPath: string;
    authenticationPath: string;
    accessToken?: string;
    profile?: User;
}

export interface Permission {
    [key: string]: any;
    id: number;
    code: string;
    description: string;
    isDeleted: boolean;
}

export interface Profile {
    id: number;
    code: string;
    description: string;
    permissionIds: number[];
    isDeleted: boolean;
}

export const initialProfileValue: Profile = {
    id: 0,
    code: "",
    description: "",
    permissionIds: [] as number[],
    isDeleted: false,
};
