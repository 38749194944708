import { useState, ReactNode, useEffect } from 'react';
import { News, ProfessionalType } from '../../store/types';
import { AuthRoutes, CheckUncheck } from '../../components';
import { Row, Col, Space, Button, Table, Tag, message, Popconfirm, Tooltip, Typography, Avatar, AutoComplete, Layout, Breadcrumb, FloatButton } from 'antd';
import { DeleteOutlined, EditOutlined, ReadOutlined, PlusOutlined, FilterFilled, BookTwoTone } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { useStore } from 'react-redux';
import { news as newsItemsReducer } from '../../store/reducers';
import { newsItemsSelectors, professionalTypeSelectors } from '../../store/selectors';
import { Link, useNavigate } from 'react-router-dom';
import { deleteNewsItem } from '../../store/api';
import { baseStyles, colors } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { includes } from '../../utils/util-custom-validators';
import dayjs from 'dayjs';

const { Text } = Typography;
const { Content } = Layout;

const PageNewsIndex = () => {
	const navigate = useNavigate();
	const store = useStore();
	const state = store.getState() as any;
	const styles = baseStyles;
	const { t } = useTranslation();
	const [newsItems, setNewsItems] = useState<News[]>([]);
	const [professionalTypes, setProfessionalTypes] = useState<ProfessionalType[]>([]);
	const [isloading, setIsLoading] = useState(true);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		setTimeout(() => {
			var items = newsItemsSelectors
				.selectAll(state)
				.filter(ni => !ni.isDeleted)
				.sort((a, b) => dayjs(a.publishDate).diff(dayjs(b.publishDate)))
				.reverse();
			setNewsItems(items);
			setProfessionalTypes(professionalTypeSelectors.selectAll(state));
			setIsLoading(false);
		}, 500);
	}, [state, newsItems.length]);

	const handleSelect = (_value, option) => {
		navigate(AuthRoutes.news + `/details`, { state: { id: option.value } });
	};

	const handleSearch = (value: string) => {
		if (value) {
			var searchResult = newsItemsSelectors
				.selectAll(state)
				.filter(ni => (includes(ni.title, value) || includes(ni.summary, value) || includes(ni.details, value)) && !ni.isDeleted);
			var searchVisualization = searchResult.map(n => {
				return {
					value: n.id,
					label: (
						<div>
							<Row>
								<Col flex={1}>
									<Text strong>{n.title}</Text>
								</Col>
								<Col flex={1}>
									<Row justify="end">
										<Text>
											({t('news.newsDate')} : {dayjs(n.newsItemDate).format('DD-MM-YYYY')}, {t('news.publishDate')} :{' '}
											{dayjs(n.publishDate).format('DD-MM-YYYY')})
										</Text>
									</Row>
								</Col>
							</Row>
						</div>
					),
				};
			});
			setOptions(searchVisualization);
		} else setOptions([]);
	};

	const handleDeleteNewsItem = async (record: News) => {
		var response = await deleteNewsItem(record);
		if (response) {
			store.dispatch(newsItemsReducer.actions.newsItemCreateUpdate({ ...record, isDeleted: true }));
			setNewsItems(newsItemsSelectors.selectAll(state).filter(ni => !ni.isDeleted));
		} else message.error(t('message.unableToDelete'));
	};

	const columns: ColumnProps<News>[] = [
		{
			width: 35,
			render: (_text: string, item: News) => {
				if (item.avatar !== '') return <Avatar size="large" src={`${item.avatar}?${Date.now()}`} />;
				else return <Avatar size="large" icon={<ReadOutlined />} />;
			},
		},
		{
			title: t('news.title'),
			dataIndex: 'title',
			key: 'title',
			sorter: {
				compare: (a: News, b) => {
					if (a.title < b.title) return -1;
					if (a.title > b.title) return 1;
					return 0;
				},
				multiple: 1,
			},
		},
		{
			title: t('news.newsDate'),
			dataIndex: 'newsItemDate',
			key: 'newsItemDate',
			width: 130,
			render: (_text: string, item: News): ReactNode => <Text>{dayjs(item.newsItemDate).format('DD-MM-YYYY')}</Text>,
		},
		{
			title: t('news.publishDate'),
			dataIndex: 'publishDate',
			key: 'publishDate',
			width: 130,
			render: (_text: string, item: News): ReactNode => <Text>{dayjs(item.publishDate).format('DD-MM-YYYY')}</Text>,
		},
		{
			title: t('news.isPublished'),
			dataIndex: 'isPublished',
			key: 'isPublished',
			width: 100,
			render: (_text: string, item: News): ReactNode => <CheckUncheck isChecked={item.isPublished} />,
		},
		{
			title: t('general.professionalType'),
			dataIndex: 'professionalTypeId',
			key: 'professionalTypeId',
			width: 150,
			filterIcon: <FilterFilled style={styles.filter}></FilterFilled>,
			filters: professionalTypes.map(t => {
				return {
					text: t.description,
					value: t.id,
				};
			}),
			onFilter: (value, item) => item.professionalTypeId === value,
			render: (_text: string, item: News): ReactNode => (
				<Tag style={styles.professionalType}>
					{item.professionalTypeId ? professionalTypes.find(p => p.id === item.professionalTypeId)?.description.toUpperCase() : t('general.all').toUpperCase()}
				</Tag>
			),
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: News): ReactNode => {
				return (
					<Space size="middle">
						<Link to={AuthRoutes.news + `/details`} state={{ id: item.id }}>
							<Tooltip title={t('general.edit')}>
								<Button type="primary" shape="round" icon={<EditOutlined />} />
							</Tooltip>
						</Link>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteNewsItem(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<div>
			<Col>
				<Row>
					<Col>
						<Breadcrumb
							separator=">"
							style={{ height: 31, margin: '16px 24px 0 0' }}
							items={[{ title: <BookTwoTone twoToneColor={colors.brandColor1} /> }, { title: t('general.content') }, { title: t('general.news') }]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Link to={AuthRoutes.news + `/details`} state={{ id: 0 }}>
							<Button key="news-add" type="primary" shape="round" icon={<PlusOutlined />}>
								{t('general.add')}
							</Button>
						</Link>
					</Col>
				</Row>
				<Content className="site-layout-background">
					<Row>
						<Col flex={3}>
							<Space direction="horizontal">
								<Text strong>{t('general.searchEngine')}</Text>
								<AutoComplete
									popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
									style={styles.search}
									placeholder={t('general.search')}
									allowClear
									options={options}
									onSelect={handleSelect}
									onSearch={handleSearch}
								/>
							</Space>
						</Col>
						<Col flex={4}></Col>
						<Col flex={3}></Col>
					</Row>
					<Row style={{ paddingTop: 10 }}>
						<FloatButton.BackTop key="back-top" />
						<Table<News>
							rowKey={record => record.id}
							loading={isloading}
							key={`news-table`}
							tableLayout="auto"
							size="small"
							dataSource={newsItems}
							columns={columns}
							pagination={false}
							style={{ width: '98vw' }}
						/>
					</Row>
				</Content>
			</Col>
		</div>
	);
};
export default PageNewsIndex;
