import { ReactNode, useCallback, useEffect, useState } from 'react';
import { AutoComplete, Breadcrumb, Button, Col, Layout, message, Popconfirm, Row, Space, Table, Tooltip, Typography } from 'antd';
import { useStore } from 'react-redux';
import { AuthRoutes } from '../../components';
import { useTranslation } from 'react-i18next';
import { persons as personsReducer } from '../../store/reducers';
import { ApartmentOutlined, DeleteOutlined, EditOutlined, FilterFilled, PlusOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { Person } from '../../store/types';
import { personsSelectors } from '../../store/selectors/management';
import { ColumnProps } from 'antd/lib/table';
import { Gender } from '../../store/types/domains';
import { gendersSelectors } from '../../store/selectors';
import { deletePerson } from '../../store/api';
import { baseStyles, colors } from '../../assets/styles';
import { includes } from '../../utils/util-custom-validators';
import { dateFormat } from '../../assets/formats';
import debounce from 'lodash.debounce';
import dayjs from 'dayjs';

const { Text } = Typography;
const { Content } = Layout;

const PagePersonIndex = () => {
	const store = useStore();
	const styles = baseStyles;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [genders, setGender] = useState<Gender[]>([]);
	const [persons, setPersons] = useState<Person[]>([]);
	const [isloading, setIsLoading] = useState(true);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		setGender(gendersSelectors.selectAll(store.getState() as any));
		setPersons(personsSelectors.selectAll(store.getState() as any).filter(p => !p.isDeleted));
		setIsLoading(false);
	}, [store]);

	const handleDeletePerson = async (item: Person) => {
		var response = await deletePerson(item);
		if (response) {
			var temp = { ...item, isDeleted: true };
			store.dispatch(personsReducer.actions.personsCreateUpdate(temp));
			setPersons(personsSelectors.selectAll(store.getState() as any).filter(p => !p.isDeleted));
		} else message.info(t('message.unableToDelete'));
	};

	const handleSelect = option => {
		navigate(AuthRoutes.persons + `/details`, { state: { id: option } });
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounceSearch = useCallback(
		debounce(value => {
			var searchResult = persons.filter(
				p => (includes(p.names, value) || includes(p.lastName, value) || includes(p.socialId, value) || includes(p.secondLastName, value)) && !p.isDeleted,
			);
			var searchVisualization = searchResult.map(p => {
				return {
					value: p.id,
					label: (
						<div>
							<Text>
								{p.lastName} {p.secondLastName}, {p.names} - {p.socialId}
							</Text>
						</div>
					),
				};
			});
			setOptions(searchVisualization);
		}, 500),
		[options],
	);

	const handleSearch = (value: string) => {
		if (!value) {
			setOptions([]);
		}
		debounceSearch(value);
	};

	const columns: ColumnProps<Person>[] = [
		{
			title: t('general.name'),
			dataIndex: 'patientName',
			key: 'patientName',
			render: (_text: string, item: Person): ReactNode => {
				return <Text>{`${item.lastName} ${item.secondLastName}, ${item.names}`}</Text>;
			},
		},
		{
			title: t('general.socialId'),
			dataIndex: 'socialId',
			key: 'socialId',
			render: (_text: string, item: Person): ReactNode => <Text>{item.socialId}</Text>,
		},
		{
			title: t('general.gender'),
			dataIndex: 'gender',
			key: 'gender',
			filterIcon: <FilterFilled style={styles.filter}></FilterFilled>,
			filters: genders.map(t => {
				return {
					text: t.description,
					value: t.id,
				};
			}),
			onFilter: (value, item) => item.genderId === value,
			render: (_text: string, item: Person): ReactNode => <Text>{genders.find(g => g.id === item.genderId)?.description}</Text>,
		},
		{
			title: t('general.birthdate'),
			dataIndex: 'birthDate',
			key: 'birthDate',
			render: (_text: string, item: Person): ReactNode => <Text>{dayjs(item.birthDate).format(dateFormat)}</Text>,
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: Person): ReactNode => {
				return (
					<Space size="middle">
						<Link to={AuthRoutes.persons + `/details`} state={{ id: item.id }}>
							<Tooltip title={t('general.edit')}>
								<Button type="primary" shape="round" icon={<EditOutlined />} />
							</Tooltip>
						</Link>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeletePerson(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<div>
			<Col>
				<Row>
					<Col>
						<Breadcrumb
							separator=">"
							style={{ height: 31, margin: '16px 24px 0 0' }}
							items={[
								{ title: <ApartmentOutlined twoToneColor={colors.brandColor1} /> },
								{ title: t('management.management') },
								{ title: t('management.persons') },
							]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Space>
							<Link key="link-add-person" to={AuthRoutes.persons + `/details`} state={{ id: 0 }}>
								<Button key="link-add-person" type="primary" shape="round" icon={<PlusOutlined />}>
									{t('general.add')}
								</Button>
							</Link>
						</Space>
					</Col>
				</Row>
				<Content className="site-layout-background">
					<Row>
						<Space direction="horizontal">
							<Text strong>{t('general.searchEngine')}</Text>
							<AutoComplete
								popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
								style={styles.search}
								placeholder={t('general.search')}
								allowClear
								options={options}
								onSelect={handleSelect}
								onSearch={handleSearch}
							/>
						</Space>
						<Col flex={4}></Col>
						<Col flex={3}></Col>
					</Row>
					<Row style={{ paddingTop: 10 }}>
						<Table<Person>
							rowKey={item => item.id}
							loading={isloading}
							key={`activity-table`}
							tableLayout="auto"
							size="small"
							dataSource={persons}
							columns={columns}
							pagination={{ position: ['bottomRight'], showTotal: total => `${t('general.total')} ${total} ${t('general.items')}` }}
							style={{ width: '96vw' }}
						/>
					</Row>
				</Content>
			</Col>
		</div>
	);
};

export default PagePersonIndex;
