import dayjs from 'dayjs';

export interface Accomplishment {
	[key: string]: any;
	id: number;
	professionalId: number;
	description: string;
	details: string;
	startDate: string;
	endDate: string;
	institutionId: number;
	isDeleted: boolean;
	createdBy?: string;
	modifiedBy?: string;
}

export const initialAccomplishmentValue: Accomplishment = {
	id: 0,
	professionalId: 0,
	description: '',
	details: '',
	startDate: dayjs(Date.now()).format('YYYY/MM/DD'),
	endDate: dayjs(Date.now()).format('YYYY/MM/DD'),
	institutionId: 1,
	isDeleted: false,
};

export interface Category {
	[key: string]: any;
	id: number;
	comments: string;
	professionalId: number;
	categoryTypeId: number;
	startDate: string;
	endDate: string;
	isDeleted: boolean;
	createdBy?: string;
	modifiedBy?: string;
}

export const initialCategoryValue: Category = {
	id: 0,
	professionalId: 0,
	comments: '',
	startDate: dayjs(Date.now()).format('YYYY/MM/DD'),
	endDate: dayjs(new Date(2099, 11, 31)).format('YYYY/MM/DD'),
	isActive: true,
	categoryTypeId: 1,
	isDeleted: false,
};

export interface Specialty {
	[key: string]: any;
	id: number;
	professionalId: number;
	description: string;
	details: string;
	startDate: string;
	endDate: string;
	isActive: boolean;
	specialtyTypeId: number;
	institutionId: number;
	isDeleted: boolean;
	createdBy?: string;
	modifiedBy?: string;
}

export const initialSpecialtyValue: Specialty = {
	id: 0,
	professionalId: 0,
	description: '',
	details: '',
	startDate: dayjs(Date.now()).format('YYYY/MM/DD'),
	endDate: dayjs(Date.now()).format('YYYY/MM/DD'),
	isActive: true,
	specialtyTypeId: 1,
	institutionId: 1,
	isDeleted: false,
};

export interface Privilege {
	[key: string]: any;
	id: number;
	privilegeTypeId: number;
	privilegeStatusId: number;
	comments: string;
	privilegeDateTime: string;
	professionalId: number;
	requestedHours?: number;
	realHours?: number;
	isDeleted: boolean;
	createdBy?: string;
	modifiedBy?: string;
}

export const initialPrivilegeValue: Privilege = {
	id: 0,
	privilegeTypeId: 1,
	privilegeStatusId: 1,
	comments: '',
	privilegeDateTime: dayjs().format('YYYY/MM/DD HH:mm'),
	professionalId: 0,
	isDeleted: false,
};

export interface ProfessionalMilestone {
	[key: string]: any;
	id: number;
	professionalMilestoneTypeId: number;
	startDateTime: string;
	endDateTime: string;
	comments: string;
	professionalId: number;
	isDeleted: boolean;
	createdBy?: string;
	modifiedBy?: string;
}

export const initialProfessionalMilestoneValue: ProfessionalMilestone = {
	id: 0,
	professionalMilestoneTypeId: 1,
	startDateTime: dayjs(Date.now()).format('YYYY/MM/DD'),
	endDateTime: dayjs(Date.now()).format('YYYY/MM/DD'),
	comments: '',
	professionalId: 1,
	isDeleted: false,
};

export interface Accreditation {
	[key: string]: any;
	id: number;
	accreditationStatusId: number;
	startDate: string;
	endDate: string;
	details: string;
	professionalId: number;
	isDeleted: boolean;
	createdBy?: string;
	modifiedBy?: string;
}

export const initialAccreditationValue: Accreditation = {
	id: 0,
	accreditationStatusId: 1,
	startDate: dayjs(Date.now()).format('YYYY/MM/DD'),
	endDate: dayjs(new Date(2099, 11, 31)).format('YYYY/MM/DD'),
	details: '',
	professionalId: 1,
	isDeleted: false,
};

export interface AccreditationComment {
	[key: string]: any;
	id: number;
	professionalId: number;
	externalCode: string;
	comment: string;
	commentDateTime: string;
	isPrivate: boolean;
	isDeleted: boolean;
	createdBy?: string;
	modifiedBy?: string;
}

export const initialAccreditationCommentValue: AccreditationComment = {
	id: 0,
	professionalId: 0,
	externalCode: '',
	comment: '',
	commentDateTime: dayjs().format('YYYY/MM/DD HH:mm'),
	isPrivate: false,
	isDeleted: false,
};

export interface AccreditationDocument {
	[key: string]: any;
	id: number;
	documentTypeId: number;
	documentStatusId: number;
	documentDate: string;
	documentUploadDateTime?: string;
	documentExpirationDate?: string;
	url: string;
	localUrl?: '';
	professionalId: number;
	isDeleted: boolean;
	modified?: boolean;
	createdBy?: string;
	modifiedBy?: string;
}

export const initialAccreditationDocumentValue: AccreditationDocument = {
	id: 0,
	documentTypeId: 0,
	documentStatusId: 0,
	documentDate: '',
	documentExpirationDate: dayjs(new Date(2099, 11, 31)).format('YYYY/MM/DD'),
	url: '',
	professionalId: 0,
	isDeleted: false,
	modified: false,
};

export interface ProfessionalDocument {
	[key: string]: any;
	details: string;
	id: number;
	documentTypeId: number;
	documentDate: string;
	documentUploadDateTime: string;
	url: string;
	localUrl?: '';
	professionalId: number;
	isPrivate: boolean;
	isDeleted: boolean;
	modified?: boolean;
	createdBy?: string;
	modifiedBy?: string;
}

export const initialProfessionalDocumentValue: ProfessionalDocument = {
	id: 0,
	details: '',
	documentTypeId: 1,
	documentDate: dayjs().format('YYYY/MM/DD'),
	url: '',
	professionalId: 0,
	documentUploadDateTime: dayjs().format('YYYY/MM/DD'),
	isPrivate: false,
	isDeleted: false,
};

export interface Professional {
	[key: string]: any;
	id: number;
	avatar: string;
	names: string;
	lastName: string;
	secondLastName: string;
	professionalTypeId: number;
	birthDate: string;
	genderId: number;
	socialId: string;
	nationalityId?: number;
	sisNumber?: string;
	eunacomResult?: string;
	hours?: number;
	centers: number[];
	categoryId: number;
	relationTypeId: number;
	isDeleted: boolean;
	isActive: boolean;
	review: string;
	createdBy?: string;
	modifiedBy?: string;
}

export const initialProfessionalValue: Professional = {
	id: 0,
	avatar: '',
	names: '',
	lastName: '',
	secondLastName: '',
	professionalTypeId: 1,
	birthDate: dayjs().format('YYYY/MM/DD'),
	genderId: 1,
	socialId: '',
	sisNumber: '',
	hours: 0,
	centers: [],
	categoryId: 1,
	relationTypeId: 1,
	isDeleted: true,
	isActive: true,
	review: '',
};
