import { useState, ReactNode, useEffect } from 'react';
import { CategoryType, Category, initialCategoryValue } from '../../store/types';
import { useStore } from 'react-redux';
import { Table, Space, Tooltip, Button, Typography, Popconfirm, message, Card, Popover } from 'antd';
import { categoryTypesSelectors } from '../../store/selectors';
import { categories as categoriesReducer } from '../../store/reducers';
import { ColumnProps } from 'antd/lib/table';
import { DeleteOutlined, EditOutlined, InfoCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { categorySelectors } from '../../store/selectors/professional';
import { deleteCategory, fetchCategoriesById } from '../../store/api';
import dayjs from 'dayjs';
import FormProfessionalCategoryEdit from './form-professional-category-edit';
import { dateFormat } from '../../assets/formats';
import { isAuthorized } from '../../utils/util-authentication';

const { Text } = Typography;
const key = 'saving';

const FormProfessionalCategories = ({ professionalId, professionalType, disabled = false }) => {
	const store = useStore();
	const styles = baseStyles;
	const { t } = useTranslation();
	const [categoryTypes, setCategoryTypes] = useState<CategoryType[]>([]);
	const [categories, setCategories] = useState<Category[]>([]);
	const [category, setCategory] = useState<Category>({ ...initialCategoryValue, professionalId: professionalId });
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setCategoryTypes(
			categoryTypesSelectors
				.selectAll(store.getState() as any)
				.filter(s => !s.isDeleted && (s.professionalTypeId === professionalType || s.professionalTypeId === null)),
		);
		setCategories(categorySelectors.selectAll(store.getState() as any).filter(p => p.professionalId === professionalId && !p.isDeleted));
		setIsLoading(true);
		fetchCategoriesById(professionalId).then(items => {
			store.dispatch(categoriesReducer.actions.categoryLoading());
			store.dispatch(categoriesReducer.actions.categoryReceived(items));
			setCategories(items.filter(s => !s.isDeleted));
			setIsLoading(false);
		});
	}, [store, professionalId, professionalType]);

	const handleEdit = (item: Category) => {
		setCategory(item);
		setModalTitle(t('professionals.editCategory'));
		setIsModalVisible(true);
	};

	const handleAddCategpry = () => {
		setCategory({ ...initialCategoryValue, professionalId: professionalId });
		setModalTitle(t('professionals.addCategory'));
		setIsModalVisible(true);
	};

	const handleDelete = async (item: Category) => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var response = await deleteCategory(item);
		if (response) {
			setIsLoading(true);
			fetchCategoriesById(professionalId).then(items => {
				store.dispatch(categoriesReducer.actions.categoryLoading());
				store.dispatch(categoriesReducer.actions.categoryReceived(items));
				setCategories(items.filter(s => !s.isDeleted));
				setIsLoading(false);
				message.success({ content: t('messages.itemDeleted'), key, duration: 2 });
			});
		} else message.info({ content: t('message.unableToDelete'), key, duration: 2 });
	};

	const handleClose = () => {
		setIsModalVisible(false);
		setCategories(categorySelectors.selectAll(store.getState() as any).filter(a => a.professionalId === professionalId && !a.isDeleted));
	};

	const columns: ColumnProps<Category>[] = [
		{
			dataIndex: 'modifiedBy',
			key: 'modifiedBy',
			width: 20,
			render: (_text: string, item: Category): ReactNode => (
				<Popover
					content={
						<div>
							<Text>{t('general.modifiedBy')} </Text>
							<Text type="warning">{item.modifiedBy}</Text>
						</div>
					}
				>
					<InfoCircleTwoTone />
				</Popover>
			),
		},
		{
			title: t('professionals.categoryType'),
			dataIndex: 'categoryType',
			key: 'categoryType',
			width: 250,
			render: (_text: string, item: Category): ReactNode => <Text>{categoryTypes.find(a => a.id === item.categoryTypeId)?.description}</Text>,
		},
		{
			title: t('professionals.startDate'),
			dataIndex: 'startDate',
			key: 'startDate',
			width: 150,
			render: (_text: string, item: Category): ReactNode => <Text>{dayjs(item.startDate).format(dateFormat)}</Text>,
		},
		{
			title: t('professionals.endDate'),
			dataIndex: 'endDate',
			key: 'endDate',
			width: 150,
			render: (_text: string, item: Category): ReactNode => <Text>{dayjs(item.endDate).format(dateFormat)}</Text>,
		},
		{
			title: t('professionals.comments'),
			dataIndex: 'comments',
			key: 'comments',
			render: (_text: string, item: Category): ReactNode => <Text>{item.comments}</Text>,
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: Category): ReactNode => {
				return disabled || !isAuthorized('Component_Categories') ? null : (
					<Space size="small">
						<Tooltip title={t('general.edit')}>
							<Button
								style={styles.button}
								shape="round"
								size="small"
								type="primary"
								onClick={() => {
									handleEdit(item);
								}}
							>
								<EditOutlined />
							</Button>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDelete(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button size="small" shape="round" danger onClick={() => {}}>
									<DeleteOutlined />
								</Button>
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<Card
			size="small"
			title={t('professionals.categoryDetails')}
			style={styles.card}
			extra={[
				disabled || !isAuthorized('Component_Categories') ? null : (
					<Button type="primary" shape="round" icon={<PlusOutlined />} style={styles.buttonCard} onClick={handleAddCategpry}></Button>
				),
			]}
		>
			<Table<Category>
				key={`categories`}
				rowKey={record => record.id}
				tableLayout="auto"
				size="small"
				dataSource={categories}
				columns={columns}
				pagination={false}
				loading={isLoading}
			/>
			<FormProfessionalCategoryEdit
				title={modalTitle}
				open={isModalVisible}
				categoryId={category.id}
				professionalId={professionalId}
				professionalType={professionalType}
				onClose={handleClose}
			/>
		</Card>
	);
};
export default FormProfessionalCategories;
