import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { Permission, Profile, User } from '../types/users';

//#region User Profile
export const userAdapter = createEntityAdapter({
	selectId: (item: User) => item.id,
});

export const users = createSlice({
	name: 'users',
	initialState: userAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		userAdded: userAdapter.addOne,
		userLoading: state => {
			if (!state.loading) state.loading = true;
		},
		userReceived: (state, action) => {
			if (state.loading) {
				userAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		userCreateUpdate: userAdapter.upsertOne,
	},
});
//#endregion

//#region Profile
export const profileAdapter = createEntityAdapter({
	selectId: (item: Profile) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const profiles = createSlice({
	name: 'profiles',
	initialState: profileAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		profileAdded: profileAdapter.addOne,
		profileLoading: state => {
			if (!state.loading) state.loading = true;
		},
		profileReceived: (state, action) => {
			if (state.loading) {
				profileAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		profileCreateUpdate: profileAdapter.upsertOne,
	},
});
//#endregion

//#region Permissions
export const permissionAdapter = createEntityAdapter({
	selectId: (item: Permission) => item.id,
	sortComparer: (a, b) => a.description.localeCompare(b.description),
});

export const permissions = createSlice({
	name: 'permissions',
	initialState: permissionAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		permissionAdded: permissionAdapter.addOne,
		permissionLoading: state => {
			if (!state.loading) state.loading = true;
		},
		permissionReceived: (state, action) => {
			if (state.loading) {
				permissionAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		permissionCreateUpdate: permissionAdapter.upsertOne,
	},
});
//#endregion
