import { useEffect, useState } from 'react';
import { Select, DatePicker, Form, Input, message } from 'antd';
import { useStore } from 'react-redux';
import { Accreditation, AccreditationStatus, initialAccreditationValue } from '../../store/types';
import { accreditationStatusesSelectors } from '../../store/selectors/domains';
import { dateFormat } from '../../assets/formats';
import { baseStyles } from '../../assets/styles';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/es_ES';
import { useTranslation } from 'react-i18next';
import { accreditationsSelectors } from '../../store/selectors';
import { SizedModal } from '..';
import { createUpdateAccreditation } from '../../store/api';
import { accreditations } from '../../store/reducers';

const { TextArea } = Input;
const { Option } = Select;

const key = 'saving';
/**
 * Form to display a form for an email
 */
const FormProfessionalAccreditationEdit = ({ accreditationId, professionalId, open, title, onClose }) => {
	const store = useStore();
	const [formProfessionalAccreditationEdit] = Form.useForm();
	const { t } = useTranslation();
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [accreditationStatuses, setAccreditationStatuses] = useState<AccreditationStatus[]>([]);
	const [accreditation, setAccreditation] = useState<Accreditation>(initialAccreditationValue);

	useEffect(() => {
		var itemsAccreditationStatuses = accreditationStatusesSelectors.selectAll(store.getState() as any).filter(s => !s.isDeleted);
		setAccreditationStatuses(itemsAccreditationStatuses);
		var itemsAccreditations = accreditationsSelectors.selectAll(store.getState() as any).sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate)));
		//if (itemsAccreditations.length > 0) setPreviousAccreditation(itemsAccreditations[0]);
		var item = itemsAccreditations.find(a => a.id === accreditationId) || {
			...initialAccreditationValue,
			professionalId: professionalId,
		};
		setAccreditation(item);
		setIsVisible(open);
		formProfessionalAccreditationEdit.setFieldsValue({
			accreditationStatus: item.accreditationStatusId,
			startDate: dayjs(item.startDate),
			endDate: dayjs(item.endDate),
			details: item.details,
		});
	}, [accreditationId, formProfessionalAccreditationEdit, open, professionalId, store]);

	const handleSave = async () => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		try {
			var values = await formProfessionalAccreditationEdit.validateFields();
			var temp: Accreditation = {
				...accreditation,
				startDate: values['startDate'].toDate(),
				endDate: values['endDate'].toDate() || new Date(2099, 0, 1),
				details: values['details'],
				accreditationStatusId: values['accreditationStatus'] || 0,
			};
			var result = await createUpdateAccreditation(temp, professionalId);
			if (result !== undefined) {
				store.dispatch(accreditations.actions.accreditationCreateUpdate(temp));
				message.success({ content: t('messages.dataSaved'), key, duration: 2 });
			} else message.info({ content: t('message.errorOnSave'), key, duration: 2 });
		} catch (errors) {
			message.info({ content: t('message.errorOnSave'), key, duration: 2 });
		}
		onClose();
	};

	const handleCancel = () => {
		onClose();
	};

	return (
		<SizedModal size="small" title={title} open={isVisible} onOk={handleSave} onCancel={handleCancel}>
			<Form {...baseStyles.formLayout} form={formProfessionalAccreditationEdit} name="accreditation-details">
				<Form.Item label={t('general.type')} name="accreditationStatus" rules={[{ required: true, message: t('messages.enterStatus') }]}>
					<Select>
						{accreditationStatuses.map(p => {
							return (
								<Option key={`accreditationStatus-${p.id}`} value={p.id}>
									{p.description}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item label={t('professionals.startDate')} name="startDate" rules={[{ required: true, message: t('messages.enterStartDate') }]}>
					<DatePicker locale={locale} format={dateFormat} />
				</Form.Item>
				<Form.Item label={t('professionals.endDate')} name="endDate" rules={[{ required: true, message: t('messages.enterEndDate') }]}>
					<DatePicker locale={locale} format={dateFormat} />
				</Form.Item>
				<Form.Item label={t('professionals.details')} name="details" rules={[{ required: false }]}>
					<TextArea />
				</Form.Item>
			</Form>
		</SizedModal>
	);
};
export default FormProfessionalAccreditationEdit;
