import { useEffect, useState } from 'react';
import { initialNewsValue, News, ProfessionalType } from '../../store/types';
import { Input, Form, DatePicker, Switch, Row, Col, Card, Select } from 'antd';
import { ParentType } from '../../store/types';
import { baseStyles } from '../../assets/styles';
import { UploadDragPaste } from '..';
import { useTranslation } from 'react-i18next';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertHtmlToDraft, convertDraftToHtml } from '../../utils/util-drafts';
import { newsItemsSelectors, professionalTypeSelectors } from '../../store/selectors';
import { useStore } from 'react-redux';
import { dateTimeFormat, exportDateTimeFormat } from '../../assets/formats';
import { objectsAreEquals } from '../../utils/util-custom-validators';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import dayjs from 'dayjs';

const { TextArea } = Input;
const { Option } = Select;

const FormNewsItem = ({ newsItemId, setNewsItemData, setHasErrors, setChanged }) => {
	const { t } = useTranslation();
	const store = useStore();
	const [formNewsItem] = Form.useForm();
	const styles = baseStyles;
	const [newsItem, setNewsItem] = useState<News>(initialNewsValue);
	const [editorState, setEditorState] = useState<EditorState>(convertHtmlToDraft(''));
	const [imageUrl, setImageUrl] = useState<string>('');
	const [professionalTypes, setProfessionalTypes] = useState<ProfessionalType[]>([]);
	const [originalNews, setOriginalNews] = useState<News>(initialNewsValue);

	useEffect(() => {
		setProfessionalTypes([{ id: 0, code: 'All', description: 'Todos', isDeleted: false }, ...professionalTypeSelectors.selectAll(store.getState() as any)]);
		var item = newsItemsSelectors.selectById(store.getState() as any, newsItemId) || initialNewsValue;
		setNewsItem(item);
		setOriginalNews(item);
		setEditorState(convertHtmlToDraft(item.details));
		if (item.avatar !== '') setImageUrl(item.avatar);
		formNewsItem.setFieldsValue({
			professionalType: item.professionalTypeId || 0,
			title: item.title,
			author: item.author,
			newsItemDate: dayjs(item.newsItemDate || new Date()),
			publishDate: dayjs(item.publishDate || new Date()),
			isPublished: item.isPublished,
			summary: item.summary,
		});
	}, [formNewsItem, newsItemId, store]);

	const setAvatarUrl = (url: string) => {
		setImageUrl(url);
		setNewsItemData({ ...newsItem, avatar: url });
		setChanged(!objectsAreEquals(originalNews, { ...newsItem, avatar: url }));
	};

	const handleEditorChange = (editorState: EditorState) => {
		setEditorState(editorState);
		setNewsItem({ ...newsItem, details: convertDraftToHtml(editorState) });
		setNewsItemData({ ...newsItem, details: convertDraftToHtml(editorState) });
		setChanged(!objectsAreEquals(originalNews, { ...newsItem, details: convertDraftToHtml(editorState) }));
	};

	const handleValuesChange = (changedValues: any) => {
		var item: News = newsItem;
		if (changedValues['professionalType'] !== undefined) item = { ...newsItem, professionalTypeId: changedValues['professionalType'] };
		if (changedValues['title'] !== undefined) item = { ...newsItem, title: changedValues['title'] };
		if (changedValues['author'] !== undefined) item = { ...newsItem, author: changedValues['author'] };
		if (changedValues['newsItemDate'] !== undefined) item = { ...newsItem, newsItemDate: changedValues['newsItemDate'].format(exportDateTimeFormat) };
		if (changedValues['publishDate'] !== undefined) item = { ...newsItem, publishDate: changedValues['publishDate'].format(exportDateTimeFormat) };
		if (changedValues['isPublished'] !== undefined) item = { ...newsItem, isPublished: changedValues['isPublished'] };
		if (changedValues['summary'] !== undefined) item = { ...newsItem, summary: changedValues['summary'] };
		setChanged(!objectsAreEquals(originalNews, item));
		setNewsItem(item);
		setNewsItemData(item);
		setTimeout(() => {
			formNewsItem
				.validateFields()
				.then(() => {
					setHasErrors(false);
				})
				.catch(errors => {
					if (errors.errorFields.length > 0) setHasErrors(true);
				});
		});
	};

	return (
		<div>
			<Row gutter={[16, 16]}>
				<Col lg={24} xl={24} xxl={12}>
					<Card size="small" title={t('news.parameters')} style={{ ...styles.card, width: '95%' }}>
						<Form {...baseStyles.formLayout} form={formNewsItem} name="news-details" onValuesChange={handleValuesChange}>
							<Form.Item label={t('general.professionalType')} name="professionalType" rules={[{ required: true }]}>
								<Select>
									{professionalTypes.map(p => {
										return (
											<Option key={`options-${p.id}`} value={p.id}>
												{p.description}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
							<Form.Item label={t('news.title')} name="title" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('news.author')} name="author" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('news.newsDate')} name="newsItemDate" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<DatePicker format={dateTimeFormat} showTime />
							</Form.Item>
							<Form.Item label={t('news.publishDate')} name="publishDate" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<DatePicker format={dateTimeFormat} showTime />
							</Form.Item>
							<Form.Item label={t('news.isPublished')} name="isPublished" valuePropName="checked">
								<Switch />
							</Form.Item>
							<Form.Item label={t('news.summary')} name="summary">
								<TextArea rows={5} />
							</Form.Item>
							<Form.Item label={t('news.avatar')} valuePropName="fileList">
								<UploadDragPaste size="medium" id={newsItemId} parentType={ParentType.newsItem} url={imageUrl} setUrl={setAvatarUrl} />
							</Form.Item>
						</Form>
					</Card>
				</Col>
				<Col lg={24} xl={24} xxl={12}>
					<Card size="small" title={t('news.newsDetails')} style={{ ...styles.card, width: '95%' }}>
						<Editor
							editorStyle={{ height: 500 }}
							editorState={editorState}
							toolbarClassName="toolbarClassName"
							wrapperClassName="wrapperClassName"
							editorClassName="editorClassName"
							onEditorStateChange={handleEditorChange}
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
export default FormNewsItem;
