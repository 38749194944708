import { useState, useEffect } from 'react';
import { Progress, Typography, Space } from 'antd';
import { useStore } from 'react-redux';
import { selectFetchingProgress } from '../../store/selectors';
import { useNavigate } from 'react-router-dom';
import { AuthRoutes } from '../../components';
import { useTranslation } from 'react-i18next';
import watch from 'redux-watch';

const { Text } = Typography;

const ProgressBarLoadingData = () => {
	const navigate = useNavigate();
	const store = useStore();
	const { t } = useTranslation();
	const [progress, setProgress] = useState<{ progress: number; lastDataSourceLoaded: string }>({ progress: 0, lastDataSourceLoaded: '' });
	var w = watch(store.getState, 'dataSourceStatuses.loaded');

	useEffect(() => {
		var unsubscribe = store.subscribe(
			w(() => {
				var value = selectFetchingProgress(store.getState() as any);
				setProgress(value);
				if (value.progress === 100) setTimeout(() => navigate(AuthRoutes.home), 500);
				return () => {
					unsubscribe();
				};
			}),
		);
	});

	return (
		<Space direction="vertical" style={{ width: '100%' }}>
			<Text>{`${t('general.loading')} ${progress.lastDataSourceLoaded} ...`}</Text>
			<Progress style={{ width: '100%' }} strokeColor={{ from: '#108ee9', to: '#87d068' }} size="small" percent={progress.progress} />
		</Space>
	);
};
export default ProgressBarLoadingData;
