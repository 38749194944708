import { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Button, Col, message, Row, Spin } from 'antd';
import { NonAuthRoutes, useAuth } from './auth-route';
import { validateUser } from '../../utils/util-authentication';
import { User } from '../../store/types';
import { useTranslation } from 'react-i18next';
import { GoogleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const AuthenticationGoogle = ({ route, noHeaders = false, text = '' }) => {
	const auth = useAuth();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [isSpinning, setIsSpinning] = useState<boolean>(false);

	const login = useGoogleLogin({
		onSuccess: async tokenResponse => {
			setIsSpinning(true);
			const response: any = await validateUser(tokenResponse.access_token);
			const requestOptions = {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${tokenResponse.access_token}`,
				},
			};
			const responseUserInfo: any = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', requestOptions);
			const userInfo = await responseUserInfo.json();
			if (response) {
				const decoded: any = jwtDecode(response.token);
				var user: User = {
					id: parseInt(decoded?.id),
					username: decoded?.username || '',
					familyName: userInfo.family_name || '',
					givenName: userInfo?.given_name || '',
					externalId: userInfo?.email || '',
					imageUrl: userInfo?.picture || '',
					name: userInfo?.name || '',
					accessToken: response.token,
					professionalId: parseInt(decoded?.professionalId),
					networkId: parseInt(decoded?.networkId),
					permissionIds: JSON.parse(decoded?.permissionIds),
					profileIds: JSON.parse(decoded?.profileIds),
					centerIds: JSON.parse(decoded?.centerIds),
					mobileLogins: 0,
					webLogins: 0,
					isDeleted: false,
				};
				globalThis.user = user;
				auth.signin(user, response.token, () => {
					setIsSpinning(false);
					navigate(route);
				});
			} else {
				message.error(t('messages.unableToLogin'));
				navigate(NonAuthRoutes.login);
				setIsSpinning(false);
			}
		},
		onError: async errorResponse => {
			message.error(t('messages.unableToLogin') + '/n' + errorResponse.error_description);
			navigate(NonAuthRoutes.login);
			setIsSpinning(false);
		},
	});

	if (text !== '')
		return (
			<Row key="auth-google-row" align="middle" gutter={10}>
				<Col flex={3}>
					<Button
						type="primary"
						shape="round"
						style={{ width: 300, height: 40, fontSize: 16 }}
						onClick={() => {
							login();
						}}
						block
					>
						{text}
					</Button>
				</Col>
				<Col style={{ width: '20px' }}>
					<Spin indicator={antIcon} spinning={isSpinning} />
				</Col>
			</Row>
		);
	if (noHeaders)
		return (
			<Button
				type="primary"
				shape="round"
				style={{ width: 300, height: 40, fontSize: 16 }}
				icon={<GoogleOutlined />}
				onClick={() => {
					login();
				}}
				block
			>
				{t('messages.loginWithGoogle')}
			</Button>
		);
	return (
		<Row key="auth-google-row" align="middle" gutter={10}>
			<Col flex={3}>
				<Button
					type="primary"
					shape="round"
					style={{ width: 300, height: 40, fontSize: 16 }}
					icon={<GoogleOutlined />}
					onClick={() => {
						login();
					}}
					block
				>
					{t('messages.loginWithGoogle')}
				</Button>
			</Col>
			<Col style={{ width: '20px' }}>
				<Spin indicator={antIcon} spinning={isSpinning} />
			</Col>
		</Row>
	);
};

export default AuthenticationGoogle;
