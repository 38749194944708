import {
	Country,
	Region,
	Province,
	Commune,
	Gender,
	EmailType,
	AddressType,
	PhoneNumberType,
	AccreditationStatus,
	AccreditationDocumentType,
	RelationType,
	ProfessionalType,
	SpecialtyType,
	EventType,
	CenterType,
	InfrastructureType,
	EquipmentType,
	EquipmentStatus,
	PrivilegeStatus,
	CategoryType,
	Institution,
	ProfessionalDocumentType,
	SimpleDomain,
	SimpleProfessionalDomain,
	AccreditationDocumentStatus,
	CaseType,
	ProfessionalMilestoneType,
} from '../types/domains';
import { baseUrl, urls } from './urls';
import { ActivityType, Priority, Tag, TaskStatus, TaskType } from '../../store/types';

export const fetchGenders = async (): Promise<Gender[]> => {
	return fetchDomain<Gender>(urls.gender);
};

export const fetchEmailTypes = async (): Promise<EmailType[]> => {
	return fetchDomain<EmailType>(urls.emailTypes);
};

export const fetchAddressTypes = async (): Promise<AddressType[]> => {
	return fetchDomain<AddressType>(urls.addressTypes);
};

export const fetchPhoneNumberTypes = async (): Promise<PhoneNumberType[]> => {
	return fetchDomain<PhoneNumberType>(urls.phoneNumberTypes);
};

export const fetchAccreditationStatuses = async (): Promise<AccreditationStatus[]> => {
	return fetchDomain<AccreditationStatus>(urls.accreditationStatuses);
};

export const fetchAccreditationDocumentTypes = async (): Promise<AccreditationDocumentType[]> => {
	return fetchProfessionalDomain<AccreditationDocumentType>(urls.accreditationDocumentTypes);
};

export const fetchAccreditationDocumentStatuses = async (): Promise<AccreditationDocumentStatus[]> => {
	return fetchDomain<AccreditationDocumentStatus>(urls.accreditationDocumentStatuses);
};

export const fetchProfessionalDocumentTypes = async (): Promise<ProfessionalDocumentType[]> => {
	return fetchDomain<ProfessionalDocumentType>(urls.professionalDocumentTypes);
};

export const fetchRelationTypes = async (): Promise<RelationType[]> => {
	return fetchDomain<RelationType>(urls.relationTypes);
};

export const fetchProfessionalTypes = async (): Promise<ProfessionalType[]> => {
	return fetchDomain<ProfessionalType>(urls.professionalTypes);
};

export const fetchCenterType = async (): Promise<CenterType[]> => {
	return fetchDomain<CenterType>(urls.centerTypes);
};

export const fetchInfrastructureType = async (): Promise<InfrastructureType[]> => {
	return fetchDomain<InfrastructureType>(urls.infrastructureTypes);
};

export const fetchEquipmentType = async (): Promise<EquipmentType[]> => {
	return fetchDomain<EquipmentType>(urls.equipmentTypes);
};

export const fetchEquipmentStatus = async (): Promise<EquipmentStatus[]> => {
	return fetchDomain<EquipmentStatus>(urls.equipmentStatuses);
};

export const fetchPrivilegeStatuses = async (): Promise<PrivilegeStatus[]> => {
	return fetchDomain<PrivilegeStatus>(urls.privilegeStatuses);
};

export const fetchPrivilegeTypes = async (): Promise<CategoryType[]> => {
	return fetchDomain<CategoryType>(urls.privilegeTypes);
};

export const fetchProfessionalMilestoneTypes = async (): Promise<ProfessionalMilestoneType[]> => {
	return fetchDomain<ProfessionalMilestoneType>(urls.professionalMilestoneTypes);
};

export const fetchEventTypes = async (): Promise<EventType[]> => {
	return fetchDomain<EventType>(urls.eventTypes);
};

export const fetchTaskStatus = async (): Promise<TaskStatus[]> => {
	return fetchDomain<TaskStatus>(urls.taskStatuses);
};

export const fetchTaskTypes = async (): Promise<TaskType[]> => {
	return fetchDomain<TaskType>(urls.taskTypes);
};

export const fetchActivityTypes = async (): Promise<ActivityType[]> => {
	return fetchDomain<ActivityType>(urls.activityTypes);
};

export const fetchCaseTypes = async (): Promise<CaseType[]> => {
	return fetchDomain<CaseType>(urls.caseTypes);
};

export const fetchPriorities = async (): Promise<Priority[]> => {
	return fetchDomain<Priority>(urls.priorities);
};

export const fetchAllCountries = async (): Promise<Country[]> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.countries, requestOptions);
	var countries = (await response.json()) as Promise<Country[]>;
	return countries;
};

export const fetchSpecialtyTypes = async (): Promise<SpecialtyType[]> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.specialtyTypes, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	var result = [] as SpecialtyType[];
	for (var i = 0; i < jsonObject.length; i++) {
		var c: SpecialtyType = {
			id: jsonObject[i].id,
			isSIS: jsonObject[i].isSIS,
			professionalTypeId: jsonObject[i].professionalType.id,
			description: jsonObject[i].description,
			isDeleted: jsonObject[i].isDeleted,
		};
		result.push(c);
	}
	return result;
};

export const fetchCategoryTypes = async (): Promise<CategoryType[]> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.categoryTypes, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	var result = [] as CategoryType[];
	for (var i = 0; i < jsonObject.length; i++) {
		var c: CategoryType = {
			id: jsonObject[i].id,
			code: jsonObject[i].code,
			professionalTypeId: jsonObject[i].professionalType ? jsonObject[i].professionalTypeid : null,
			description: jsonObject[i].description,
			isDeleted: jsonObject[i].isDeleted,
		};
		result.push(c);
	}
	return result;
};

/**
 * Fetch a generic domain
 */
const fetchDomain = async <T extends {}>(url: string): Promise<T[]> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(url, requestOptions);
	var result = (await response.json()) as Promise<T[]>;
	return result;
};

const fetchProfessionalDomain = async <T extends {}>(url: string): Promise<T[]> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(url, requestOptions);
	var items = await response.json();
	var result = [];
	items.forEach(i => {
		var item: SimpleProfessionalDomain = {
			id: i.id,
			code: i.code,
			description: i.description,
			professionalTypeId: i.profesionalType ? i.profesionalType.id : undefined,
			isDeleted: i.isDeleted,
		};
		result.push(item);
	});
	return result;
};

/**
 * Fetch all regions
 */
export const fetchRegions = async (): Promise<{ regions: Region[]; provinces: Province[]; communes: Commune[] }> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.regions, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	var result = {
		regions: [] as Region[],
		provinces: [] as Province[],
		communes: [] as Commune[],
	};
	for (var i = 0; i < jsonObject.length; i++) {
		var r: Region = {
			id: jsonObject[i].id,
			number: jsonObject[i].number,
			code: jsonObject[i].code,
			description: jsonObject[i].description,
			isDeleted: jsonObject[i].isDeleted,
		};
		result.regions.push(r);
		for (var j = 0; j < jsonObject[i].provinces.length; j++) {
			var p: Province = {
				id: jsonObject[i].provinces[j].id,
				code: jsonObject[i].provinces[j].code,
				description: jsonObject[i].provinces[j].description,
				regionId: jsonObject[i].id,
				isDeleted: jsonObject[i].isDeleted,
			};
			result.provinces.push(p);
			for (var k = 0; k < jsonObject[i].provinces[j].communes.length; k++) {
				var c: Commune = {
					id: jsonObject[i].provinces[j].communes[k].id,
					code: jsonObject[i].provinces[j].communes[k].code,
					description: jsonObject[i].provinces[j].communes[k].description,
					provinceId: jsonObject[i].provinces[j].id,
					isDeleted: jsonObject[i].isDeleted,
				};
				result.communes.push(c);
			}
		}
	}
	return result;
};

/**
 * Fetch tags
 */
export const fetchTags = async () => {
	var tags: Tag[] = [];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.tags, requestOptions);
	var data = await response.json();
	for (var i = 0; i < data.length; i++) {
		var tag: Tag = parseTag(data[i]);
		tags.push(tag);
	}
	return tags;
};

/**
 * Fetch Institution
 */
export const fetchInstitutions = async () => {
	var institutions: Institution[] = [];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.institutions, requestOptions);
	var data = await response.json();
	for (var i = 0; i < data.length; i++) {
		var institution: Institution = parseInstitution(data[i]);
		if (!institution.isDeleted) institutions.push(institution);
	}
	return institutions;
};

/**
 * Post a new News Subject
 */
export const createUpdateTag = async (tag: Tag): Promise<Tag | undefined> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(tag),
	};
	var response = await fetch(urls.createUpdateTag, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		var result: Tag = parseTag(data);
		return result;
	}
	return undefined;
};

export const parseTag = (data: any) => {
	var tag: Tag = {
		id: data.id,
		code: data.description.toUpperCase(),
		description: data.description,
		isDeleted: data.isDeleted,
	};
	return tag;
};

export const parseInstitution = (data: any) => {
	var institution: Institution = {
		id: data.id,
		code: data.code,
		avatar: data.avatar,
		name: data.name,
		description: data.description,
		countryId: data.country.id,
		isDeleted: data.isDeleted,
	};
	return institution;
};

/**
 * Post a new News Subject
 */
export const createUpdateInstitution = async (institution: Institution): Promise<Institution | undefined> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(institution),
	};
	var response = await fetch(urls.createUpdateInstitution, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		var result: Institution = parseInstitution(data);
		return result;
	}
	return undefined;
};

export const createUpdateSimpleDomainValue = async (value: SimpleDomain, domainType: string, url: string): Promise<SimpleDomain | undefined> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(value),
	};
	var response = await fetch(`${baseUrl}${url}?domain=${domainType}`, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		var result: SimpleDomain = data;
		return result;
	}
	return undefined;
};

export const createUpdateSimpleProfessionalDomainValue = async (
	value: SimpleProfessionalDomain,
	domainType: string,
	url: string,
): Promise<SimpleProfessionalDomain | undefined> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(value),
	};
	var response = await fetch(`${baseUrl}${url}?domain=${domainType}`, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		var item: SimpleProfessionalDomain = {
			id: data.id,
			code: data.code,
			description: data.description,
			professionalTypeId: data.professionalType !== undefined ? data.professionalType.id : undefined,
			isDeleted: data.isDeleted,
		};
		return item;
	}
	return undefined;
};

export const createUpdateGenericDomain = async (value: any, domainType: string, url: string): Promise<any> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(value),
	};
	var response = await fetch(`${baseUrl}${url}?domain=${domainType}`, requestOptions);
	if (response.status === 200) {
		var item = await response.json();
		return item;
	}
	return undefined;
};

export const deleteInstitution = async (institution: Institution): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	const response = await fetch(urls.deleteInstitution + `?institutionId=${institution.id}`, requestOptions);
	if (response.status === 200) {
		return true;
	}
	return false;
};

export const deleteCountry = async (country: Country): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	const response = await fetch(urls.deleteCountry + `?countryId=${country.id}`, requestOptions);
	if (response.status === 200) {
		return true;
	}
	return false;
};

export const fetchDomainDefinitions = async (): Promise<any | undefined> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
		},
	};
	var response = await fetch(urls.domainDefinitions, requestOptions);
	if (response.status === 200) {
		var result = await response.json();
		return result;
	} else return undefined;
};
