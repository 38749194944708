import dayjs from 'dayjs';
import { Activity, Address, Agreement, Case, Comment, DocumentAttachment, Email, ParentType, Person, Phone, Task, User } from '../types';
import { parseDocumentAttachment } from './attachments';
import { parseAddress, parseEmail, parsePhone } from './professional';
import { urls } from './urls';

export const personExists = async (socialId: string, id: number): Promise<boolean> => {
	var exists = false;
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.externalId,
		},
	};
	var response = await fetch(urls.professionalExists + '?socialId=' + socialId + '&id=' + id, requestOptions);
	var data = await response.text();
	if (data === 'true') exists = true;
	return exists;
};

export const parseActivity = (data: any): Activity => {
	var a: Activity = {
		id: data.id,
		activityNumber: data.activityNumber,
		activityTypeId: data.activityType.id,
		activityDate: dayjs.utc(data.activityDate).tz('America/Santiago').toDate().toISOString(),
		priorityId: data.priority.id,
		objectives: data.objectives,
		description: data.description,
		originId: data.origin.id,
		userIds: data.users.map((u: User) => u.id),
		tasksIds: data.tasks.map((t: Task) => t.id),
		agreementIds: [],
		commentIds: [],
		isDeleted: data.isDeleted,
	};
	return a;
};

export const parseTask = (data: any): Task => {
	var a: Task = {
		id: data.id,
		description: data.description,
		completionDate: data.completionDate,
		expirationDate: data.expirationDate,
		taskOwnerId: data.taskOwnerId,
		taskStatusId: data.taskStatus?.id || 1,
		taskTypeId: data.taskType?.id || 1,
		participantIds: data.participants.map(p => p.id),
		documentAttachmentIds: [],
		commentIds: [],
		creationDate: data.dateOfCreation,
		isDeleted: data.isDeleted,
	};
	return a;
};

export const parseCase = (data: any): Case => {
	var c: Case = {
		id: data.id,
		patientId: data.patient?.id,
		contactId: data.contact?.id,
		caseNumber: data.caseNumber,
		caseTypeId: data.caseType?.id,
		priorityId: data.priority?.id,
		reason: data.reason,
		subject: data.subject,
		description: data.description,
		originId: data.origin?.id,
		participantsId: data.participants.map(p => p.id),
		incidentsId: [],
		solutionsId: [],
		commentIds: [],
		tasksIds: data.tasks.map(t => t.id),
		isClosed: data.isClosed,
		closedDate: data.closedDate,
		creationDate: data.dateOfCreation,
		isDeleted: data.isDeleted,
	};
	return c;
};

export const parsePerson = (data: any): Person => {
	var p: Person = {
		id: data.id,
		avatar: data.avatar,
		names: data.names,
		genderId: data.gender === null ? 1 : data.gender.id,
		lastName: data.lastName,
		secondLastName: data.secondLastName,
		nationalityId: data.nationality === null ? 1 : data.nationality.id,
		birthDate: data.birthDate,
		socialId: data.socialId,
		isDeleted: data.isDeleted,
	};
	return p;
};

export const parseComment = (data: any, activityId: number, caseId: number, taskId: number): Comment => {
	var c: Comment = {
		id: data.id,
		description: data.description || '',
		activityId: activityId,
		caseId: caseId,
		taskId: taskId,
		dateOfCreation: data.dateOfCreation,
		dateOfModification: data.dateOfModification,
		createdBy: data.createdBy,
		modifiedBy: data.modifiedBy,
		isDeleted: data.isDeleted,
	};
	return c;
};

export const parseAgreement = (data: any, activityId: number): Agreement => {
	var a: Agreement = {
		id: data.id,
		description: data.description || '',
		activityId: activityId,
		dateOfCreation: data.dateOfCreation,
		dateOfModification: data.dateOfModification,
		createdBy: data.createdBy,
		modifiedBy: data.modifiedBy,
		isDeleted: data.isDeleted,
	};
	return a;
};

export const fetchTasks = async (): Promise<{ tasks: Task[]; attachments: DocumentAttachment[]; comments: Comment[] }> => {
	var result = { tasks: [] as Task[], attachments: [] as DocumentAttachment[], comments: [] as Comment[] };
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.tasks, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var t: Task = parseTask(jsonObject[i]);
		if (jsonObject[i].attachments && jsonObject[i].attachments.length > 0) {
			for (var j = 0; j < jsonObject[i].attachments.length; j++) {
				result.attachments.push(parseDocumentAttachment(jsonObject[i].attachments[j], t.id, ParentType.task));
			}
		}
		if (jsonObject[i].comments && jsonObject[i].comments.length > 0) {
			for (var k = 0; k < jsonObject[i].comments.length; k++) {
				result.comments.push(parseComment(jsonObject[i].comments[k], 0, 0, t.id));
			}
		}
		result.tasks.push(t);
	}
	return result;
};

export const fetchMyTasks = async (userId: number): Promise<Task[]> => {
	var result = [] as Task[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.myTasks + '?userId=' + userId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var t: Task = parseTask(jsonObject[i]);
		result.push(t);
	}
	return result;
};

export const fetchTaskComments = async (taskId: number): Promise<Comment[]> => {
	var result = [] as Comment[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.taskComments + '?taskId=' + taskId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var t: Comment = parseComment(jsonObject[i], 0, 0, taskId);
		result.push(t);
	}
	return result;
};

export const fetchTaskDocuments = async (taskId: number): Promise<DocumentAttachment[]> => {
	var result = [] as DocumentAttachment[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.taskDocumentAttachments + '?taskId=' + taskId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var t: DocumentAttachment = parseDocumentAttachment(jsonObject[i], taskId, ParentType.task);
		result.push(t);
	}
	return result;
};

export const fetchPersons = async (): Promise<{
	persons: Person[];
	emails: Email[];
	phones: Phone[];
	addresses: Address[];
}> => {
	var result = {
		persons: [] as Person[],
		emails: [] as Email[],
		phones: [] as Phone[],
		addresses: [] as Address[],
	};
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.persons, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var p = parsePerson(jsonObject[i]);
		result.persons.push(p);
		var personId = jsonObject[i].id;
		if (jsonObject[i].address !== undefined)
			for (var j = 0; j < jsonObject[i].address.length; j++) {
				var address = parseAddress(jsonObject[i].address[j], 0, personId);
				result.addresses.push(address);
			}
		if (jsonObject[i].emails !== undefined)
			for (j = 0; j < jsonObject[i].emails.length; j++) {
				var email = parseEmail(jsonObject[i].emails[j], 0, personId);
				result.emails.push(email);
			}
		if (jsonObject[i].phones !== undefined)
			for (j = 0; j < jsonObject[i].phones.length; j++) {
				var phone = parsePhone(jsonObject[i].phones[j], 0, personId);
				result.phones.push(phone);
			}
	}
	return result;
};

export const fetchActivity = async (id: number): Promise<Activity> => {
	var result: Activity;
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(`${urls.activity}?id=${id}`, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	result = parseActivity(jsonObject);
	return result;
};

export const fetchActivities = async (): Promise<{ activities: Activity[]; agreements: Agreement[]; comments: Comment[] }> => {
	var result = {
		activities: [] as Activity[],
		agreements: [] as Agreement[],
		comments: [] as Comment[],
	};
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(`${urls.activities}`, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	if (jsonObject[0] === undefined) return result;
	for (var i = 0; i < jsonObject.length; i++) {
		var n: Activity = parseActivity(jsonObject[i]);
		result.activities.push(n);
		if (jsonObject[i].agreements && jsonObject[i].agreements.length > 0) {
			for (var j = 0; j < jsonObject[i].agreements.length; j++) {
				result.agreements.push(parseAgreement(jsonObject[i].agreements[j], n.id));
			}
		}
		if (jsonObject[i].comments && jsonObject[i].comments.length > 0) {
			for (var k = 0; k < jsonObject[i].comments.length; k++) {
				result.comments.push(parseComment(jsonObject[i].comments[k], n.id, 0, 0));
			}
		}
	}
	return result;
};

export const fetchActivityComments = async (activityId: number): Promise<Comment[]> => {
	var result = [] as Comment[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.activityComments + '?activityId=' + activityId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var t: Comment = parseComment(jsonObject[i], activityId, 0, 0);
		result.push(t);
	}
	return result;
};

export const fetchActivityAgreements = async (activityId: number): Promise<Agreement[]> => {
	var result = [] as Agreement[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.activityAgreements + '?activityId=' + activityId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var t: Agreement = parseAgreement(jsonObject[i], activityId);
		result.push(t);
	}
	return result;
};

export const fetchCase = async (id: number): Promise<Case> => {
	var result: Case;
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(`${urls.case}?id=${id}`, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	result = parseCase(jsonObject);
	return result;
};

export const fetchCases = async (): Promise<{ cases: Case[]; persons: Person[]; comments: Comment[] }> => {
	var result = {
		cases: [] as Case[],
		persons: [] as Person[],
		comments: [] as Comment[],
	};
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(`${urls.cases}`, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	if (jsonObject[0] === undefined) return result;
	for (var i = 0; i < jsonObject.length; i++) {
		var c: Case = parseCase(jsonObject[i]);
		result.cases.push(c);
		if (jsonObject[i].comments && jsonObject[i].comments.length > 0) {
			for (var k = 0; k < jsonObject[i].comments.length; k++) {
				result.comments.push(parseComment(jsonObject[i].comments[k], 0, c.id, 0));
			}
		}
	}
	return result;
};

export const fetchCaseComments = async (caseId: number): Promise<Comment[]> => {
	var result = [] as Comment[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.caseComments + '?caseId=' + caseId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var t: Comment = parseComment(jsonObject[i], 0, caseId, 0);
		result.push(t);
	}
	return result;
};

export const fetchCaseTasks = async (caseId: number): Promise<Task[]> => {
	var result = [] as Task[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.caseTasks + '?caseId=' + caseId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var t: Task = parseTask(jsonObject[i]);
		result.push(t);
	}
	return result;
};

export const createUpdatePerson = async (person: Person): Promise<Person | undefined> => {
	var temp: Person;
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(person),
	};
	const request = `${urls.createUpdatePerson}`;
	var response = await fetch(request, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		temp = parsePerson(data);
		return temp;
	}
	return undefined;
};

export const createUpdateTask = async (item: Task, activityId: number = 0, caseId: number = 0): Promise<Task | undefined> => {
	try {
		var bearer = 'Bearer ' + globalThis.token;
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${bearer}`,
				Username: `${globalThis.user?.username}`,
			},
			body: JSON.stringify(item),
		};
		var url = urls.createUpdateTask;
		if (activityId !== 0) url = `${urls.createUpdateTask}?activityId=${activityId}`;
		else if (caseId !== 0) url = `${urls.createUpdateTask}?caseId=${caseId}`;
		var response = await fetch(url, requestOptions);
		if (response.status === 200) {
			var data = await response.json();
			var result: Task = parseTask(data);
			return result;
		}
	} catch (e) {
		return undefined;
	}
	return undefined;
};

export const createUpdateActivity = async (item: Activity): Promise<Activity | undefined> => {
	try {
		var bearer = 'Bearer ' + globalThis.token;
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${bearer}`,
				Username: `${globalThis.user?.username}`,
			},
			body: JSON.stringify(item),
		};
		var response = await fetch(urls.createUpdateActivity, requestOptions);
		if (response.status === 200) {
			var data = await response.json();
			var result: Activity = parseActivity(data);
			return result;
		}
	} catch (e) {
		return undefined;
	}
	return undefined;
};

export const createUpdateCase = async (item: Case): Promise<Case | undefined> => {
	try {
		var bearer = 'Bearer ' + globalThis.token;
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${bearer}`,
				Username: `${globalThis.user?.username}`,
			},
			body: JSON.stringify(item),
		};
		var response = await fetch(urls.createUpdateCase, requestOptions);
		if (response.status === 200) {
			var data = await response.json();
			var result: Case = parseCase(data);
			return result;
		}
	} catch (e) {
		return undefined;
	}
	return undefined;
};

export const createUpdateAgreement = async (item: Agreement, activityId: number): Promise<Agreement | undefined> => {
	try {
		var bearer = 'Bearer ' + globalThis.token;
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${bearer}`,
				Username: `${globalThis.user?.username}`,
			},
			body: JSON.stringify(item),
		};
		var response = await fetch(urls.createUpdateAgreement, requestOptions);
		if (response.status === 200) {
			var data = await response.json();
			var result: Agreement = parseAgreement(data, activityId);
			return result;
		}
	} catch (e) {
		return undefined;
	}
	return undefined;
};

export const deleteAgreement = async (item: Agreement): Promise<boolean> => {
	try {
		var bearer = 'Bearer ' + globalThis.token;
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${bearer}`,
				Username: `${globalThis.user?.username}`,
			},
		};
		var response = await fetch(`${urls.deleteActivityAgreement}?activityId=${item.activityId}&id=${item.id}`, requestOptions);
		if (response.status === 200) return true;
	} catch (e) {
		return false;
	}
	return false;
};

export const deleteActivityComment = async (item: Comment) => {
	try {
		var bearer = 'Bearer ' + globalThis.token;
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${bearer}`,
				Username: `${globalThis.user?.username}`,
			},
		};
		var response = await fetch(`${urls.deleteActivityComment}?activityId=${item.activityId}&id=${item.id}`, requestOptions);
		if (response.status === 200) return true;
	} catch (e) {
		return false;
	}
	return false;
};

export const deleteCase = async (item: Case) => {
	try {
		var bearer = 'Bearer ' + globalThis.token;
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${bearer}`,
				Username: `${globalThis.user?.username}`,
			},
		};
		var response = await fetch(`${urls.deleteCase}?id=${item.id}`, requestOptions);
		if (response.status === 200) return true;
	} catch (e) {
		return false;
	}
	return false;
};

export const deleteActivity = async (item: Activity) => {
	try {
		var bearer = 'Bearer ' + globalThis.token;
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${bearer}`,
				Username: `${globalThis.user?.username}`,
			},
		};
		var response = await fetch(`${urls.deleteActivity}?id=${item.id}`, requestOptions);
		if (response.status === 200) return true;
	} catch (e) {
		return false;
	}
	return false;
};

export const deleteTask = async (item: Task) => {
	try {
		var bearer = 'Bearer ' + globalThis.token;
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${bearer}`,
				Username: `${globalThis.user?.username}`,
			},
		};
		var response = await fetch(`${urls.deleteTask}?id=${item.id}`, requestOptions);
		if (response.status === 200) return true;
	} catch (e) {
		return false;
	}
	return false;
};

export const deleteTaskComment = async (item: Comment) => {
	try {
		var bearer = 'Bearer ' + globalThis.token;
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${bearer}`,
				Username: `${globalThis.user?.username}`,
			},
		};
		var response = await fetch(`${urls.deleteTaskComment}?taskId=${item.taskId}&id=${item.id}`, requestOptions);
		if (response.status === 200) return true;
	} catch (e) {
		return false;
	}
	return false;
};

export const createUpdateActivityComment = async (item: Comment, activityId: number): Promise<Comment | undefined> => {
	try {
		var bearer = 'Bearer ' + globalThis.token;
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${bearer}`,
				Username: `${globalThis.user?.username}`,
			},
			body: JSON.stringify(item),
		};
		var response = await fetch(urls.createUpdateActivityComment, requestOptions);
		if (response.status === 200) {
			var data = await response.json();
			var result: Comment = parseComment(data, activityId, 0, 0);
			return result;
		}
	} catch (e) {
		return undefined;
	}
	return undefined;
};

export const createUpdateTaskComment = async (item: Comment, taskId: number): Promise<Comment | undefined> => {
	try {
		var bearer = 'Bearer ' + globalThis.token;
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${bearer}`,
				Username: `${globalThis.user?.username}`,
			},
			body: JSON.stringify(item),
		};
		var response = await fetch(urls.createUpdateTaskComment, requestOptions);
		if (response.status === 200) {
			var data = await response.json();
			var result: Comment = parseComment(data, 0, 0, taskId);
			return result;
		}
	} catch (e) {
		return undefined;
	}
	return undefined;
};

export const createUpdateCaseComment = async (item: Comment, caseId: number): Promise<Comment | undefined> => {
	try {
		var bearer = 'Bearer ' + globalThis.token;
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${bearer}`,
				Username: `${globalThis.user?.username}`,
			},
			body: JSON.stringify(item),
		};
		var response = await fetch(urls.createUpdateCaseComment, requestOptions);
		if (response.status === 200) {
			var data = await response.json();
			var result: Comment = parseComment(data, 0, caseId, 0);
			return result;
		}
	} catch (e) {
		return undefined;
	}
	return undefined;
};

export const deletePerson = async (person: Person): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	const response = await fetch(urls.deletePerson + `?id=${person.id}`, requestOptions);
	if (response.status === 200) {
		return true;
	}
	return false;
};
