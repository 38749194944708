import { useState, ReactNode, useEffect } from 'react';
import { initialAccreditationValue, AccreditationStatus, Accreditation } from '../../store/types';
import { useStore } from 'react-redux';
import { Table, Space, Tooltip, Button, Typography, Popconfirm, message, Card, Row, Popover } from 'antd';
import { accreditationStatusesSelectors, accreditationsSelectors } from '../../store/selectors';
import { accreditations as accreditationsReducer } from '../../store/reducers';
import { ColumnProps } from 'antd/lib/table';
import { DeleteOutlined, EditOutlined, InfoCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { deleteAccreditation, fetchAccreditationsById } from '../../store/api';
import { baseStyles } from '../../assets/styles';
import dayjs from 'dayjs';
import FormProfessionalAccreditationEdit from './form-professional-accreditation-edit';
import { useTranslation } from 'react-i18next';
import { dateFormat } from '../../assets/formats';
import { isAuthorized } from '../../utils/util-authentication';

const { Text } = Typography;
const key = 'saving';
/**
 * Form to display a table of specialties
 */
const FormProfessionalAccreditations = ({ professionalId, professionalType, disabled = false }) => {
	var id = professionalId;
	const store = useStore();
	const styles = baseStyles;
	const { t } = useTranslation();
	const [accreditationStatuses, setAccreditationStatuses] = useState<AccreditationStatus[]>([]);
	const [accreditations, setAccreditations] = useState<Accreditation[]>([]);
	const [accreditation, setAccreditation] = useState<Accreditation>({ ...initialAccreditationValue, professionalId: id });
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [modalTitle, setModalTitle] = useState('');

	useEffect(() => {
		setAccreditationStatuses(accreditationStatusesSelectors.selectAll(store.getState() as any).filter(s => !s.isDeleted));
		fetchAccreditationsById(professionalId).then(items => {
			items.forEach(i => store.dispatch(accreditationsReducer.actions.accreditationCreateUpdate(i)));
			setAccreditations(items.filter(s => !s.isDeleted).sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate))));
			setIsLoading(false);
		});
		setAccreditations(
			accreditationsSelectors
				.selectAll(store.getState() as any)
				.filter(p => p.professionalId === id && !p.isDeleted)
				.sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate))),
		);
	}, [store, id, professionalId]);

	const handleEditAccreditation = (item: Accreditation) => {
		setModalTitle(t('professionals.editAccreditationStatus'));
		setAccreditation(item);
		setIsModalVisible(true);
	};

	const handleAddAccreditation = () => {
		setModalTitle(t('professionals.addAccreditationStatus'));
		setAccreditation({ ...initialAccreditationValue, professionalId: id });
		setIsModalVisible(true);
	};

	const handleDeleteAccreditation = async (item: Accreditation) => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var response = await deleteAccreditation(item);
		if (response) {
			fetchAccreditationsById(professionalId).then(items => {
				store.dispatch(accreditationsReducer.actions.accreditationsLoading());
				store.dispatch(accreditationsReducer.actions.accreditationsReceived(items));
				setAccreditations(items.filter(s => !s.isDeleted).sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate))));
				setIsLoading(false);
			});
			message.success({ content: t('messages.itemDeleted'), key, duration: 2 });
		} else message.info({ content: t('message.unableToDelete'), key, duration: 2 });
	};

	const handleClose = () => {
		setIsModalVisible(false);
		fetchAccreditationsById(professionalId).then(items => {
			store.dispatch(accreditationsReducer.actions.accreditationsLoading());
			store.dispatch(accreditationsReducer.actions.accreditationsReceived(items));
			setAccreditations(items.filter(s => !s.isDeleted).sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate))));
			setIsLoading(false);
		});
	};

	const columns: ColumnProps<Accreditation>[] = [
		{
			dataIndex: 'modifiedBy',
			key: 'modifiedBy',
			width: 20,
			render: (_text: string, item: Accreditation): ReactNode => (
				<Popover
					content={
						<div>
							<Text>{t('general.modifiedBy')} </Text>
							<Text type="warning">{item.modifiedBy}</Text>
						</div>
					}
				>
					<InfoCircleTwoTone />
				</Popover>
			),
		},
		{
			title: t('general.status'),
			dataIndex: 'accreditationStatus',
			key: 'accreditationStatus',
			width: 250,
			render: (_text: string, item: Accreditation): ReactNode => (
				<Text>{accreditationStatuses?.find(a => a.id === item.accreditationStatusId)?.description}</Text>
			),
		},
		{
			title: t('professionals.startDate'),
			dataIndex: 'startDate',
			key: 'startDate',
			width: 150,
			render: (_text: string, item: Accreditation): ReactNode => <Text>{dayjs(item.startDate).format(dateFormat)}</Text>,
		},
		{
			title: t('professionals.endDate'),
			dataIndex: 'endDate',
			key: 'endDate',
			width: 150,
			render: (_text: string, item: Accreditation): ReactNode => <Text>{dayjs(item.endDate).format(dateFormat)}</Text>,
		},
		{
			title: t('professionals.comments'),
			dataIndex: 'details',
			key: 'details',
			render: (_text: string, item: Accreditation): ReactNode => <Text>{item.details}</Text>,
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: Accreditation): ReactNode => {
				return disabled || !isAuthorized('Component_AcreditationStatus') ? null : (
					<Space size="small">
						<Tooltip title={t('general.edit')}>
							<Button
								style={styles.button}
								shape="round"
								size="small"
								type="primary"
								onClick={() => {
									handleEditAccreditation(item);
								}}
							>
								<EditOutlined />
							</Button>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteAccreditation(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button size="small" shape="round" danger onClick={() => {}}>
									<DeleteOutlined />
								</Button>
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<Card
			size="small"
			title={t('professionals.accreditationStatus')}
			style={styles.card}
			extra={[
				disabled || !isAuthorized('Component_AcreditationStatus') ? null : (
					<Button type="primary" shape="round" icon={<PlusOutlined />} style={styles.buttonCard} onClick={handleAddAccreditation}></Button>
				),
			]}
		>
			{!disabled ? (
				<Row style={styles.toolbar} justify="end">
					<Space></Space>
				</Row>
			) : null}
			<Table<Accreditation>
				key={`accreditations-${Date.now()}`}
				loading={isLoading}
				rowKey={record => record.id}
				tableLayout="auto"
				size="small"
				dataSource={accreditations}
				columns={columns}
				pagination={false}
			/>
			<FormProfessionalAccreditationEdit
				title={modalTitle}
				open={isModalVisible}
				accreditationId={accreditation.id}
				professionalId={id}
				onClose={handleClose}
			/>
		</Card>
	);
};
export default FormProfessionalAccreditations;
