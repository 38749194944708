import { useState, ReactNode, useEffect } from 'react';
import { Address, AddressType, Commune, initialAddressValue } from '../../store/types';
import { useStore } from 'react-redux';
import { ColumnProps } from 'antd/lib/table';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Table, Typography, Space, Tooltip, Button, message, Popconfirm, Card } from 'antd';
import { addressTypesSelectors, addressesSelectors, communesSelectors } from '../../store/selectors';
import { addresses as addressesReducer } from '../../store/reducers';
import { deleteAddress, fetchProfessionalAddressesById } from '../../store/api';
import { useTranslation } from 'react-i18next';
import { baseStyles } from '../../assets/styles';
import FormProfessionalAddressEdit from './form-professional-address-edit';

const { Text } = Typography;
const key = 'saving';
/**
 * Form to display a table of address
 */
const FormProfessionalAddresses = ({ professionalId }) => {
	const store = useStore();
	const { t } = useTranslation();
	const styles = baseStyles;
	const id = professionalId;
	const [addressTypes, setAddressTypes] = useState<AddressType[]>([]);
	const [addresses, setAddresses] = useState<Address[]>([]);
	const [address, setAddress] = useState<Address>({ ...initialAddressValue, ownerId: id });
	const [communes, setCommunes] = useState<Commune[]>([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setCommunes(communesSelectors.selectAll(store.getState() as any));
		setAddressTypes(addressTypesSelectors.selectAll(store.getState() as any));
		setIsLoading(true);
		fetchProfessionalAddressesById(professionalId).then(items => {
			store.dispatch(addressesReducer.actions.addressesLoading());
			store.dispatch(addressesReducer.actions.addressesReceived(items));
			setAddresses(items.filter(s => !s.isDeleted));
			setIsLoading(false);
		});
	}, [professionalId, store]);

	const handleEdit = (item: Address) => {
		setModalTitle(t('professionals.editAddress'));
		setAddress({ ...item });
		setIsModalVisible(true);
	};

	const handleAdd = () => {
		setModalTitle(t('professionals.addAddress'));
		setAddress({ ...initialAddressValue, ownerId: id });
		setIsModalVisible(true);
	};

	const handleDelete = async (item: Address) => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var response = await deleteAddress(item);
		if (response) {
			setIsLoading(true);
			fetchProfessionalAddressesById(professionalId).then(items => {
				store.dispatch(addressesReducer.actions.addressesLoading());
				store.dispatch(addressesReducer.actions.addressesReceived(items));
				setAddresses(items.filter(s => !s.isDeleted));
				setIsLoading(false);
				message.success({ content: t('messages.itemDeleted'), key, duration: 2 });
			});
		} else message.info({ content: t('message.unableToDelete'), key, duration: 2 });
	};

	const handleClose = () => {
		setIsModalVisible(false);
		setAddresses(addressesSelectors.selectAll(store.getState() as any).filter(a => a.ownerId === id && !a.isDeleted));
	};

	const columns: ColumnProps<Address>[] = [
		{
			title: t('professionals.address'),
			dataIndex: 'street',
			key: 'street',
			render: (_text: string, item: Address): ReactNode => <Text>{`${item.street} ${item.number} ${item.other ? item.other : ''}`}</Text>,
		},
		{
			title: t('professionals.commune'),
			dataIndex: 'other',
			key: 'other',
			ellipsis: true,
			render: (_text: string, item: Address): ReactNode => <Text>{communes.find(c => c.id === item.communeId)?.description}</Text>,
		},
		{
			title: t('professionals.addressType'),
			dataIndex: 'addressTypeId',
			key: 'addressTypeId',
			ellipsis: true,
			width: 120,
			render: (_text: string, item: Address): ReactNode => <Text>{addressTypes.find(a => a.id === item?.addressTypeId)?.description}</Text>,
			responsive: ['md'],
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: Address): ReactNode => {
				return (
					<Space size="small">
						<Tooltip title={t('general.edit')}>
							<Button
								type="primary"
								shape="round"
								onClick={() => {
									handleEdit(item);
								}}
							>
								<EditOutlined />
							</Button>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDelete(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button danger shape="round">
									<DeleteOutlined />
								</Button>
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<Card
			size="small"
			title={t('professionals.addresses')}
			style={styles.card}
			extra={<Button type="primary" shape="round" icon={<PlusOutlined />} style={styles.buttonCard} onClick={handleAdd}></Button>}
		>
			<Table<Address>
				rowKey={item => item.id}
				tableLayout="auto"
				size="small"
				dataSource={addresses}
				columns={columns}
				pagination={false}
				loading={isLoading}
			/>
			<FormProfessionalAddressEdit title={modalTitle} open={isModalVisible} addressId={address.id} professionalId={id} onClose={handleClose} />
		</Card>
	);
};
export default FormProfessionalAddresses;
