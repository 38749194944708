import { Row, Space, Typography, Divider, Descriptions, Input, Tag } from 'antd';
import { colors } from '../../assets/styles';
import { dateTimeFormat } from '../../assets/formats';
import { useTranslation } from 'react-i18next';
import { LaptopOutlined } from '@ant-design/icons';
import { useThemeContext } from '../../utils/useThemeContext';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const { Text, Title } = Typography;
const { TextArea } = Input;

const NodeNote = ({ encounter, guid }) => {
	const { t } = useTranslation();
	const { userTheme } = useThemeContext();
	const [theme, setTheme] = useState('');
	const encounter_notes = encounter.registros ? encounter.registros : [];
	const encounter_date = encounter.fecha ? encounter.fecha : '';
	const encounter_place = encounter.establecimiento ? encounter.establecimiento.descripcion : '';
	const encounter_system = encounter.sistema;
	const encounter_scope = encounter.ambito;
	const encounter_professional = encounter.profesional
		? encounter.profesional
		: {
				nombre_completo: '',
				especialidad: '',
		  };
	var items = [];
	var all_notes = [];

	useEffect(() => {
		setTheme(userTheme === 'black' ? 'white' : 'black');
	}, [userTheme]);

	switch (encounter.tipo) {
		case 'Epicrisis':
			const ingress_diagnosis = encounter.diagnostico_ingreso ? encounter.diagnostico_ingreso : '';
			const discharge_diagnosis = encounter.diagnostico_egreso ? encounter.diagnostico_egreso : '';
			const discharge_condition = encounter.condicion_egreso ? encounter.condicion_egreso : '';
			const days_hospitalized = encounter.dias_hospitalizado ? encounter.dias_hospitalizado : '';
			const discharge_date = encounter.fecha_egreso ? encounter.fecha_egreso : '';
			const admission_date = encounter.fecha_ingreso ? encounter.fecha_ingreso : '';
			const discharge_instructions = encounter.indicaciones_alta ? encounter.indicaciones_alta : '';
			const surgical_procedures = encounter.procedimientos_cirugia ? encounter.procedimientos_cirugia : '';
			const next_appointment = encounter.proximo_control ? encounter.proximo_control : '';
			const next_appointment_professional = encounter.proximo_control_profesional ? encounter.proximo_control_profesional : '';
			const complications_during_stay = encounter.complicaciones_estadia ? encounter.complicaciones_estadia : '';
			items.push({
				key: encounter.guid + '-01',
				label: t('management.admission_date'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{dayjs(admission_date).format(dateTimeFormat)}</Text>,
			});
			items.push({
				key: encounter.guid + '-02',
				label: t('management.ingress_diagnose'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{ingress_diagnosis}</Text>,
			});
			items.push({
				key: encounter.guid + '-03',
				label: t('management.surgical_procedures'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{surgical_procedures}</Text>,
			});
			items.push({
				key: encounter.guid + '-04',
				label: t('management.complications_during_stay'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{complications_during_stay}</Text>,
			});
			items.push({
				key: encounter.guid + '-05',
				label: t('management.discharge_diagnose'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{discharge_diagnosis}</Text>,
			});
			items.push({
				key: encounter.guid + '-06',
				label: t('management.discharge_date'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{dayjs(discharge_date).format(dateTimeFormat)}</Text>,
			});
			items.push({
				key: encounter.guid + '-07',
				label: t('management.days_hospitalized'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{days_hospitalized}</Text>,
			});
			items.push({
				key: encounter.guid + '-08',
				label: t('management.discharge_instructions'),
				children: (
					<TextArea
						id={encounter.guid + '-08ta'}
						style={{ color: colors.backgroundColor, backgroundColor: 'transparent', borderColor: 'transparent' }}
						autoSize={true}
						value={discharge_instructions}
						disabled
					></TextArea>
				),
			});
			items.push({
				key: encounter.guid + '-09',
				label: t('management.discharge_condition'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{discharge_condition}</Text>,
			});
			items.push({
				key: encounter.guid + '-10',
				label: t('management.next_appointment'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{next_appointment ? dayjs(next_appointment).format(dateTimeFormat) : ''}</Text>,
			});
			items.push({
				key: encounter.guid + '-11',
				label: t('management.next_appointment_professional'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{next_appointment_professional}</Text>,
			});
			items.push({
				key: encounter.guid + '-12',
				label: t('management.notes'),
				children: (
					<TextArea
						id={encounter.guid + '-12ta'}
						style={{ color: colors.backgroundColor, backgroundColor: 'transparent', borderColor: 'transparent' }}
						value={encounter_notes}
						autoSize={true}
						disabled
					/>
				),
			});
			items.push({
				key: encounter.guid + '-2',
				label: t('management.professional'),
				children: (
					<Title level={5} style={{ margin: 0, paddingBottom: 4 }}>
						{encounter_professional.nombre_completo + ' - ' + encounter_professional.especialidad}
					</Title>
				),
			});
			return (
				<div>
					<Descriptions
						bordered
						labelStyle={{ width: 200 }}
						column={1}
						title={
							<Space direction="horizontal">
								<Title id={guid} level={5} style={{ margin: 0, paddingBottom: 4 }}>
									{dayjs(encounter_date).format(dateTimeFormat) + ' - ' + encounter_place}
								</Title>
								<Tag color="success" icon={<LaptopOutlined />}>
									{encounter_system}
								</Tag>
								<Tag color="success" icon={<LaptopOutlined />}>
									{encounter_scope}
								</Tag>
							</Space>
						}
						items={items}
					></Descriptions>
					<Divider style={{ borderColor: colors.brandColor1 }}></Divider>
				</div>
			);
		case 'Protocolo operatorio':
			const preoperative_diagnosis = encounter.diagnostico_preoperatorio ? encounter.diagnostico_preoperatorio : '';
			const postoperative_diagnosis = encounter.diagnostico_postoperatorio ? encounter.diagnostico_postoperatorio : '';
			const biopsy = encounter.biopsia ? encounter.biopsia : '';
			const code = encounter.codigo ? encounter.codigo : '';
			const endDate_anesthesia = encounter.fecha_fin_anestesia ? encounter.fecha_fin_anestesia : '';
			const startDate_anesthesia = encounter.fecha_inicio_anestesia ? encounter.fecha_inicio_anestesia : '';
			const date_surgery = encounter.fecha_operacion ? encounter.fecha_operacion : '';
			const start_time = encounter.hora_inicio ? encounter.hora_inicio : '';
			const end_time = encounter.hora_termino ? encounter.hora_termino : '';
			const operating_room_name = encounter.nombre_pabellon ? encounter.nombre_pabellon : '';
			const emergency_operating_room = encounter.pabellon_urgencia ? encounter.pabellon_urgencia : '';
			all_notes = [];
			encounter_notes.forEach(r => {
				if (r) {
					var temp = r.split('\n');
					temp.forEach(e => {
						all_notes.push(e);
					});
				}
			});
			items.push({
				key: encounter.guid + '-01',
				label: t('management.date_surgery'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{dayjs(date_surgery).format(dateTimeFormat)}</Text>,
			});
			items.push({
				key: encounter.guid + '-02',
				label: t('management.preoperative_diagnosis'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{preoperative_diagnosis}</Text>,
			});
			items.push({
				key: encounter.guid + '-03',
				label: t('management.startDate_anesthesia'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{dayjs(startDate_anesthesia).format(dateTimeFormat)}</Text>,
			});
			items.push({
				key: encounter.guid + '-04',
				label: t('management.start_time'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{dayjs(start_time).format(dateTimeFormat)}</Text>,
			});
			items.push({
				key: encounter.guid + '-05',
				label: t('management.end_time'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{dayjs(end_time).format(dateTimeFormat)}</Text>,
			});
			items.push({
				key: encounter.guid + '-06',
				label: t('management.endDate_anesthesia'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{dayjs(endDate_anesthesia).format(dateTimeFormat)}</Text>,
			});
			items.push({
				key: encounter.guid + '-07',
				label: t('management.code'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{code}</Text>,
			});
			items.push({
				key: encounter.guid + '-08',
				label: t('management.postoperative_diagnosis'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{postoperative_diagnosis}</Text>,
			});
			items.push({
				key: encounter.guid + '-09',
				label: t('management.biopsy'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{biopsy}</Text>,
			});
			items.push({
				key: encounter.guid + '-10',
				label: t('management.operating_room_name'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{operating_room_name}</Text>,
			});
			items.push({
				key: encounter.guid + '-11',
				label: t('management.emergency_operating_room'),
				children: <Text style={{ margin: 0, paddingBottom: 4 }}>{emergency_operating_room}</Text>,
			});
			items.push({
				key: encounter.guid + '-12',
				label: t('management.notes'),
				children: (
					<TextArea
						id={encounter.guid + '-12ta'}
						style={{ color: colors.backgroundColor, backgroundColor: 'transparent', borderColor: 'transparent' }}
						value={encounter_notes}
						autoSize={true}
						disabled
					/>
				),
			});
			items.push({
				key: encounter.guid + '-13',
				label: t('management.professional'),
				children: (
					<Title level={5} style={{ margin: 0, paddingBottom: 4 }}>
						{encounter_professional.nombre_completo + ' - ' + encounter_professional.especialidad}
					</Title>
				),
			});
			return (
				<div>
					<Descriptions
						bordered
						labelStyle={{ width: 200 }}
						column={1}
						title={
							<Space direction="horizontal">
								<Title id={guid} level={5} style={{ margin: 0, paddingBottom: 4 }}>
									{dayjs(encounter_date).format(dateTimeFormat) + ' - ' + encounter_place}
								</Title>
								<Tag color="success" icon={<LaptopOutlined />}>
									{encounter_system}
								</Tag>
								<Tag color="success" icon={<LaptopOutlined />}>
									{encounter_scope}
								</Tag>
							</Space>
						}
						items={items}
					></Descriptions>
					<Divider style={{ borderColor: colors.brandColor1 }}></Divider>
				</div>
			);
		case 'Consulta urgencia':
			var encounter_diagnoses = encounter.diagnosticos ? encounter.diagnosticos : [];
			var encounter_indications = encounter.indicaciones ? encounter.indicaciones : [];
			var encounter_prescriptions = encounter.prescripciones ? encounter.prescripciones : [];
			encounter_notes.forEach(r => {
				if (r.registro) {
					var temp = r.registro.split('\n');
					temp.forEach(e => {
						all_notes.push(e);
					});
				}
			});
			items = [];
			if (all_notes.length > 0)
				items.push({
					key: encounter.guid + '-01',
					label: t('management.notes'),
					children: encounter_notes.map(e => (
						<Row>
							<TextArea
								id={encounter.guid + '-01ta'}
								style={{ color: colors.backgroundColor, backgroundColor: 'transparent', borderColor: 'transparent' }}
								value={e.registro}
								autoSize={true}
								disabled
							></TextArea>
						</Row>
					)),
				});
			if (encounter.diagnosticos?.length > 0)
				items.push({
					key: encounter.guid + '-02',
					label: t('management.diagnoses'),
					children: <Space direction="vertical">{[encounter_diagnoses?.map(e => <Text>- {e.registro}</Text>)]}</Space>,
				});
			if (encounter_indications.length > 0 || encounter_prescriptions.length > 0)
				items.push({
					key: encounter.guid + '-03',
					label: t('management.indications'),
					children: (
						<Space direction="vertical">
							{[encounter_indications.map(e => <Text>- {e.registro}</Text>), encounter_prescriptions.map(e => <Text>- {e.registro}</Text>)]}
						</Space>
					),
				});
			items.push({
				key: encounter.guid + '-04',
				label: t('management.professional'),
				children: (
					<Title level={5} style={{ margin: 0, paddingBottom: 4 }}>
						{encounter_professional.nombre_completo + ' - ' + encounter_professional.especialidad}
					</Title>
				),
			});
			return (
				<div>
					<Descriptions
						bordered
						labelStyle={{ width: 200 }}
						column={1}
						title={
							<Space direction="horizontal">
								<Title id={guid} level={5} style={{ margin: 0, paddingBottom: 4 }}>
									{dayjs(encounter_date).format(dateTimeFormat) + ' - ' + encounter_place}
								</Title>
								<Tag color="success" icon={<LaptopOutlined />}>
									{encounter_system}
								</Tag>
								<Tag color="success" icon={<LaptopOutlined />}>
									{encounter_scope}
								</Tag>
							</Space>
						}
						items={items}
					></Descriptions>
					<Divider style={{ borderColor: colors.brandColor1 }}></Divider>
				</div>
			);
		case 'Consulta ambulatoria':
			encounter_diagnoses = encounter.diagnosticos ? encounter.diagnosticos : [];
			encounter_indications = encounter.indicaciones ? encounter.indicaciones : [];
			encounter_prescriptions = encounter.prescripciones ? encounter.prescripciones : [];
			encounter_notes.forEach(r => {
				if (r.registro) {
					var temp = r.registro.split('\n');
					temp.forEach(e => {
						all_notes.push(e);
					});
				}
			});
			items = [];
			if (all_notes.length > 0)
				items.push({
					key: encounter.guid + '-01',
					label: t('management.notes'),
					children: encounter_notes.map(e => (
						<Row>
							<TextArea
								id={encounter.guid + '-01ta'}
								style={{ color: theme, backgroundColor: 'transparent', borderColor: 'transparent' }}
								value={e.registro}
								autoSize={true}
								disabled
							></TextArea>
						</Row>
					)),
				});
			if (encounter.diagnosticos?.length > 0)
				items.push({
					key: encounter.guid + '-02',
					label: t('management.diagnoses'),
					children: <Space direction="vertical">{[encounter_diagnoses?.map(e => <Text>- {e.registro}</Text>)]}</Space>,
				});
			if (encounter_indications.length > 0 || encounter_prescriptions.length > 0)
				items.push({
					key: encounter.guid + '-03',
					label: t('management.indications'),
					children: (
						<Space direction="vertical">
							{[encounter_indications.map(e => <Text>- {e.registro}</Text>), encounter_prescriptions.map(e => <Text>- {e.registro}</Text>)]}
						</Space>
					),
				});
			items.push({
				key: encounter.guid + '-04',
				label: t('management.professional'),
				children: (
					<Title level={5} style={{ margin: 0, paddingBottom: 4 }}>
						{encounter_professional.nombre_completo + ' - ' + encounter_professional.especialidad}
					</Title>
				),
			});
			return (
				<div>
					<Descriptions
						bordered
						labelStyle={{ width: 200 }}
						column={1}
						title={
							<Space direction="horizontal">
								<Title id={guid} level={5} style={{ margin: 0, paddingBottom: 4 }}>
									{dayjs(encounter_date).format(dateTimeFormat) + ' - ' + encounter_place}
								</Title>
								<Tag color={colors.brandColor1} icon={<LaptopOutlined />}>
									{encounter_system}
								</Tag>
								<Tag color={colors.brandColor1} icon={<LaptopOutlined />}>
									{encounter_scope}
								</Tag>
							</Space>
						}
						items={items}
					></Descriptions>
					<Divider style={{ borderColor: colors.brandColor1 }}></Divider>
				</div>
			);
	}
};

export default NodeNote;
