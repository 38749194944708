import { useEffect, useState } from 'react';
import { Input, Row, Col, Form, Select, Alert, message } from 'antd';
import { addresses as addressesReducer } from '../../store/reducers';
import { useStore } from 'react-redux';
import { addressTypesSelectors, communesSelectors, provincesSelectors, regionsSelectors, countriesSelectors, addressesSelectors } from '../../store/selectors';
import { AddressType, Region, Province, Commune, Address, Country, initialAddressValue } from '../../store/types';
import { SizedModal } from '../../components';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { createUpdateProfessionalAddress } from '../../store/api';
import { includes } from '../../utils/util-custom-validators';

const { Option } = Select;

const key = 'saving';

const FormProfessionalAddressEdit = (props: { professionalId: number; addressId: number; open: boolean; title: string; onClose: () => void }) => {
	const store = useStore();
	const [formProfessionalAddressEdit] = Form.useForm();
	const { t } = useTranslation();
	const [isVisible, setIsVisible] = useState<boolean>(props.open);
	const [addressTypes, setAddressTypes] = useState<AddressType[]>([]);
	const [communes, setCommunes] = useState<Commune[]>([]);
	const [provinces, setProvinces] = useState<Province[]>([]);
	const [regions, setRegions] = useState<Region[]>([]);
	const [countries, setCountries] = useState<Country[]>([]);
	const [address, setAddress] = useState<Address>(initialAddressValue);
	const [isValidData, setIsValidData] = useState<boolean>(true);

	useEffect(() => {
		setIsVisible(props.open);
		var itemsAddressTypes = addressTypesSelectors.selectAll(store.getState() as any).filter(t => !t.isDeleted);
		setAddressTypes(itemsAddressTypes);
		var itemsCommunes = communesSelectors.selectAll(store.getState() as any).filter(t => !t.isDeleted);
		setCommunes(itemsCommunes);
		var itemsProvinces = provincesSelectors.selectAll(store.getState() as any).filter(t => !t.isDeleted);
		setProvinces(itemsProvinces);
		var itemsRegions = regionsSelectors.selectAll(store.getState() as any).filter(t => !t.isDeleted);
		setRegions(itemsRegions);
		setCountries(countriesSelectors.selectAll(store.getState() as any).filter(t => !t.isDeleted));
		var item = addressesSelectors.selectById(store.getState() as any, props.addressId) || { ...initialAddressValue };
		setAddress(item);
		var tCommune = itemsCommunes.find(c => c.id === item.communeId);
		var tProvince = itemsProvinces.find(p => p.id === tCommune?.provinceId);
		var tRegion = itemsRegions.find(r => r.id === tProvince?.regionId);
		formProfessionalAddressEdit.setFieldsValue({
			street: item.street,
			number: item.number,
			other: item.other,
			addressType: item.addressTypeId,
			commune: item.communeId,
			province: tProvince?.description,
			region: tRegion?.description,
			country: item.countryId || countriesSelectors.selectAll(store.getState() as any).find(c => c.code === 'CL').id,
			latitude: item.latitude,
			longitude: item.longitude,
		});
	}, [formProfessionalAddressEdit, props.addressId, props.open, store]);

	const handleCancel = () => {
		setAddress(initialAddressValue);
		setIsValidData(true);
		props.onClose();
	};

	const handleOk = async () => {
		if (!isValidData) return;
		try {
			var values = await formProfessionalAddressEdit.validateFields();
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			var temp: Address = {
				...address,
				professionalId: props.professionalId,
				id: props.addressId,
				ownerId: props.professionalId,
				addressTypeId: values['addressType'],
				street: values['street'],
				number: values['number'],
				other: values['other'],
				communeId: values['commune'],
				countryId: values['country'],
				latitude: values['latitude'],
				longitude: values['longitude'],
			};
			var response = await createUpdateProfessionalAddress(temp);
			if (response !== undefined) {
				store.dispatch(addressesReducer.actions.addressCreateUpdate(response));
				message.success({ content: t('messages.dataSaved'), key, duration: 2 });
			} else message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
			props.onClose();
		} catch (errors) {
			setIsValidData(false);
		}
	};

	const handleValuesChange = (changedValues: any) => {
		setIsValidData(true);
		if (changedValues['commune']) {
			var tCommune = communes.find(c => c.id === changedValues['commune']);
			var tProvince = provinces.find(p => p.id === tCommune?.provinceId);
			var tRegion = regions.find(r => r.id === tProvince?.regionId);
			formProfessionalAddressEdit.setFieldsValue({
				province: provinces.find(p => p.id === tProvince.id)?.description,
				region: regions.find(r => r.id === tRegion.id)?.description,
			});
		}
	};

	return (
		<SizedModal title={props.title} size="small" open={isVisible} onOk={handleOk} onCancel={handleCancel}>
			<div>
				<Row gutter={[12, 12]}>
					<Col span={24}>
						<Form {...baseStyles.formLayout} form={formProfessionalAddressEdit} name="address-details" onValuesChange={handleValuesChange}>
							<Form.Item label={t('general.type')} name="addressType" rules={[{ required: true, message: t('messages.enterType') }]}>
								<Select>
									{addressTypes.map(item => {
										return (
											<Option key={`addressType-${item.id}`} value={item.id}>
												{item.description}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
							<Form.Item label={t('general.street')} name="street" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('general.number')} name="number" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('general.other')} name="other" rules={[{ required: false }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('general.commune')} name="commune" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Select
									showSearch
									allowClear
									optionFilterProp="children"
									filterOption={(input, option) => includes((option!.children as unknown as string).toLowerCase(), input.toLowerCase())}
								>
									{communes.map(item => (
										<Option key={`commune-${item.id}`} value={item.id}>
											{item.description}
										</Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item label={t('general.province')} name="province">
								<Input disabled />
							</Form.Item>
							<Form.Item label={t('general.region')} name="region">
								<Input disabled />
							</Form.Item>
							<Form.Item label={t('general.country')} name="country" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Select
									showSearch
									allowClear
									optionFilterProp="children"
									filterOption={(input, option) => includes((option!.children as unknown as string).toLowerCase(), input.toLowerCase())}
								>
									{countries.map(item => (
										<Option key={`country-${item.id}`} value={item.id}>
											{item.description}
										</Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item label={t('general.latitude')} name="latitude">
								<Input disabled />
							</Form.Item>
							<Form.Item label={t('general.longitude')} name="longitude">
								<Input disabled />
							</Form.Item>
						</Form>
					</Col>
				</Row>
				{!isValidData ? <Alert message={t('messages.correctErrorsToContinue')} type="error" /> : null}
			</div>
		</SizedModal>
	);
};
export default FormProfessionalAddressEdit;
