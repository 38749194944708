export interface Country {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface Commune {
	id: number;
	code: string;
	description: string;
	provinceId: number;
	isDeleted: boolean;
}

export interface Province {
	id: number;
	code: string;
	description: string;
	regionId: number;
	isDeleted: boolean;
}

export interface Region {
	id: number;
	number: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface Gender {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface PhoneNumberType {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface EmailType {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface AddressType {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface MeasurementType {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface AccreditationStatus {
	id: number;
	code: string;
	description: string;
	professionalTypeId: number | null;
	isDeleted: boolean;
}

export interface AccreditationDocumentType {
	id: number;
	code: string;
	description: string;
	professionalTypeId: number | null;
	isDeleted: boolean;
}

export interface ProfessionalDocumentType {
	id: number;
	code: string;
	description: string;
	professionalTypeId: number | null;
	isDeleted: boolean;
}

export interface AccreditationDocumentStatus {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface RelationType {
	id: number;
	code: string;
	description: string;
	professionalTypeId: number | null;
	isDeleted: boolean;
}

export interface ProfessionalType {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface SpecialtyType {
	id: number;
	description: string;
	professionalTypeId: number | null;
	isSIS: boolean;
	isDeleted: boolean;
}

export interface ProfessionalMilestoneType {
	id: number;
	description: string;
	professionalTypeId: number | null;
	isDeleted: boolean;
}

export interface CategoryTypes {
	id: number;
	code: string;
	description: string;
	professionalTypeId: number | null;
	isDeleted: boolean;
}
export interface CaseType {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface EventType {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface CenterType {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface InfrastructureType {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface EquipmentStatus {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface EquipmentType {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface CategoryType {
	id: number;
	code: string;
	description: string;
	professionalTypeId: number | null;
	isDeleted: boolean;
}
export interface PrivilegeType {
	id: number;
	code: string;
	description: string;
	professionalTypeId: number | null;
	isDeleted: boolean;
}

export interface PrivilegeStatus {
	id: number;
	code: string;
	description: string;
	professionalTypeId: number | null;
	isDeleted: boolean;
}

export interface Institution {
	id: number;
	code: string;
	name: string;
	description: string;
	avatar: string;
	countryId: number;
	isDeleted: boolean;
}

export interface Tag {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface SimpleDomain {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface SimpleProfessionalDomain {
	id: number;
	code: string;
	description: string;
	professionalTypeId?: number;
	isDeleted: boolean;
}

export const initialInstitutionValue: Institution = {
	id: 0,
	code: '',
	name: '',
	description: '',
	avatar: '',
	countryId: 0,
	isDeleted: false,
};

export const initialSimpleDomainValue: SimpleDomain = {
	id: 0,
	code: '',
	description: '',
	isDeleted: false,
};

export const initialSimpleProfessionalDomain: SimpleProfessionalDomain = {
	id: 0,
	code: '',
	description: '',
	isDeleted: false,
};
