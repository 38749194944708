import { Page, Text, View, Document, Image, Font, StyleSheet } from '@react-pdf/renderer';
import { colors } from '../assets/styles';
import logoImage from '../assets/images/logo-redsalud-light.png';
import dayjs from 'dayjs';
import { dateFormat, dateTimeFormat } from '../assets/formats';

Font.register({
	family: 'Oswald',
	src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingBottom: 35,
		paddingHorizontal: 20,
	},
	header: {
		fontSize: 12,
		marginBottom: 20,
		textAlign: 'center',
		color: 'grey',
	},
	pageNumber: {
		position: 'absolute',
		fontSize: 12,
		top: 770,
		left: 0,
		right: 0,
		textAlign: 'center',
		color: 'grey',
	},
	title: {
		fontSize: 18,
		margin: 12,
		fontFamily: 'Oswald',
		textAlign: 'center',
		color: colors.brandColor1,
	},
	section: { fontSize: 18, margin: 12, fontFamily: 'Oswald' },
	text: {
		fontSize: 14,
		textAlign: 'justify',
		fontFamily: 'Times-Roman',
	},
	table_text_left: { textAlign: 'left', fontSize: 14, width: 150 },
	table_text_center: { textAlign: 'center', fontSize: 14, width: 40 },
});

// Create COMPIN document
const COMPINDocument = ({ patient, encounter }) => {
	var patientName = patient.nombre;
	var patientLastName = patient.apellido_paterno;
	var patientSecondLastName = patient.apellido_materno;
	var patientId = patient.numero_identificacion;
	var patientBirthDate = patient.fecha_nacimiento;
	var patientSex = patient.sexo;
	var patientAddress = patient.direccion;
	var patientPhone = patient.telefono;
	var profesionalName = encounter.profesional?.nombre_completo;
	var profesionalSpecialty = encounter.profesional?.especialidad;
	var profesionalId = encounter.profesional?.rut;
	var date = encounter?.fecha;
	var age = dayjs(encounter?.fecha).diff(dayjs(patientBirthDate), 'years');
	var location = encounter.establecimiento?.descripcion;
	var allNotes = [];
	encounter.registros?.forEach(r => {
		var notes = r.registro.split('\n');
		notes.forEach(n => allNotes.push(n));
	});
	var diagnoses = encounter.diagnosticos || [];
	var indications = encounter.indicaciones || [];
	var prescriptions = encounter.medicamentos || [];
	var usuario = globalThis.user ? globalThis.user.givenName + ' ' + globalThis.user.familyName : '';

	const header = showPatientData => {
		return (
			<>
				<Text style={styles.header} fixed>
					{'Generado por ' + usuario + ' el ' + dayjs(new Date()).format(dateTimeFormat)}
				</Text>
				{showPatientData ? (
					<View fixed style={{ display: 'flex' }}>
						<View style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
							<View style={{ padding: '10 10 10 10', border: 1 }}>
								<View style={{ flexDirection: 'row', display: 'flex' }}>
									<Text style={styles.table_text_left}>Nombre del paciente</Text>
									<Text style={styles.table_text_center}>:</Text>
									<Text style={{ textAlign: 'center', fontSize: 14 }}>{patientLastName + ' ' + patientSecondLastName + ', ' + patientName}</Text>
								</View>
								<View style={{ flexDirection: 'row', display: 'flex' }}>
									<Text style={styles.table_text_left}>RUT del paciente</Text>
									<Text style={styles.table_text_center}>:</Text>
									<Text style={{ textAlign: 'center', fontSize: 14 }}>{patientId}</Text>
								</View>
							</View>
						</View>
					</View>
				) : (
					<View fixed style={{ flexDirection: 'row', display: 'flex' }}>
						<View style={{ display: 'flex', height: 50, width: '50%', justifyContent: 'flex-start' }}>
							<Image src={logoImage} style={{ height: 30, width: 100 }}></Image>
						</View>
						<View style={{ display: 'flex', height: 50, width: '50%', justifyContent: 'flex-start' }}>
							<Text style={{ textAlign: 'right', fontSize: 16, color: colors.brandColor1 }}>Dirección Médica Corporativa</Text>
							<Text style={{ textAlign: 'right', fontSize: 16, color: colors.brandColor1 }}>Dirección de Informática Médica</Text>
						</View>
					</View>
				)}
			</>
		);
	};

	const footer = () => {
		return <Text render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`} style={styles.pageNumber}></Text>;
	};

	return (
		<Document pageLayout="singlePage">
			<Page style={styles.body}>
				<View style={{ flexDirection: 'column' }}>
					{header(false)}
					<Text style={styles.title}>Carátula</Text>
					<Text style={styles.section}>Datos del paciente</Text>
					<View style={{ padding: '10 10 10 10', border: 1 }}>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Primer apellido</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{patientLastName}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Segundo apellido</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{patientSecondLastName}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Nombres</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{patientName}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>RUT</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{patientId}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Fecha de Nacimiento</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{dayjs(patientBirthDate, 'YYYY-MM-DD hh:mm:ss').format(dateFormat)}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Sexo</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{patientSex}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Edad</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{age + 'años'}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Domicilio</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{patientAddress}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Teléfono</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{patientPhone}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Representante legal o Apoderado</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}></Text>
						</View>
					</View>
					<Text style={styles.section}>Datos de la atención</Text>
					<View style={{ padding: '10 10 10 10', border: 1 }}>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Nombre del profesional</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{profesionalName}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>RUT del profesional</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{profesionalId}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Especialidad</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{profesionalSpecialty}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Fecha de atención</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{dayjs(date, 'YYYY-MM-DD hh:mm:ss').format(dateFormat)}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Lugar de la atención</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{location}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Nombre del prestador</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>MEGASALUD SPA</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>RUT del prestador</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>96942400 - 2</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Software</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>Software TrakCare de InterSystems</Text>
						</View>
					</View>
					{footer()}
				</View>
			</Page>
			<Page wrap style={styles.body}>
				<View style={{ flexDirection: 'column' }}>
					{header(true)}
					<Text style={styles.title}>Registro Clínico</Text>
					<Text style={styles.section}>Registro</Text>
					{allNotes.map(n => (
						<Text style={styles.text}>{n}</Text>
					))}
					{diagnoses.length > 0 ? <Text style={styles.section}>Diagnósticos</Text> : null}
					{diagnoses.map(d => (
						<Text style={styles.text}>{' - ' + d.registro}</Text>
					))}
					{indications.length > 0 && prescriptions.length > 0 ? <Text style={styles.section}>Indicaciones</Text> : null}
					{indications.map(i => (
						<Text style={styles.text}>{' - ' + i.registro}</Text>
					))}
					{prescriptions.map(p => (
						<Text style={styles.text}>{' - ' + p.registro}</Text>
					))}
					{footer()}
				</View>
			</Page>
		</Document>
	);
};
export default COMPINDocument;
