const urlDev = process.env.REACT_APP_MICROSERVICES || '';

export const baseUrl = urlDev;

export const urls = {
	//Domains
	networkDomainDefinitions: `${baseUrl}/Domains/NetworkDomainDefinitions`,
	domainDefinitions: `${baseUrl}/Domains/DomainDefinitions`,
	gender: `${baseUrl}/Domains/Genders`,
	countries: `${baseUrl}/Domains/Countries`,
	regions: `${baseUrl}/Domains/Regions`,
	phoneNumberTypes: `${baseUrl}/Domains/PhoneNumberTypes`,
	emailTypes: `${baseUrl}/Domains/EmailTypes`,
	addressTypes: `${baseUrl}/Domains/EmailTypes`,
	accreditationStatuses: `${baseUrl}/Domains/AccreditationStatuses`,
	accreditationDocumentTypes: `${baseUrl}/Domains/AccreditationDocumentTypes`,
	accreditationDocumentStatuses: `${baseUrl}/Domains/AccreditationDocumentStatuses`,
	professionalDocumentTypes: `${baseUrl}/Domains/ProfessionalDocumentTypes`,
	relationTypes: `${baseUrl}/Domains/RelationTypes`,
	professionalTypes: `${baseUrl}/Domains/ProfessionalTypes`,
	categoryTypes: `${baseUrl}/Domains/CategoryTypes`,
	specialtyTypes: `${baseUrl}/Domains/SpecialtyTypes`,
	eventTypes: `${baseUrl}/Domains/EventTypes`,
	centerTypes: `${baseUrl}/Domains/CenterTypes`,
	infrastructureTypes: `${baseUrl}/Domains/InfrastructureTypes`,
	equipmentTypes: `${baseUrl}/Domains/EquipmentTypes`,
	equipmentStatuses: `${baseUrl}/Domains/EquipmentStatuses`,
	privilegeStatuses: `${baseUrl}/Domains/PrivilegeStatuses`,
	privilegeTypes: `${baseUrl}/Domains/PrivilegeTypes`,
	professionalMilestoneTypes: `${baseUrl}/Domains/ProfessionalMilestoneTypes`,
	taskStatuses: `${baseUrl}/Domains/TaskStatuses`,
	taskTypes: `${baseUrl}/Domains/TaskTypes`,
	activityTypes: `${baseUrl}/Domains/ActivityTypes`,
	caseTypes: `${baseUrl}/Domains/CaseTypes`,
	priorities: `${baseUrl}/Domains/Priorities`,
	tags: `${baseUrl}/Domains/Tags`,
	createUpdateTag: `${baseUrl}/Domains/CreateUpdateTag`,
	institutions: `${baseUrl}/Domains/Institutions`,
	createUpdateInstitution: `${baseUrl}/Domains/CreateUpdateInstitution`,
	deleteInstitution: `${baseUrl}/Domains/DeleteInstitution`,
	deleteCountry: `${baseUrl}/Domains/DeleteCountry`,
	// News
	news: `${baseUrl}/News/News`,
	createUpdateNews: `${baseUrl}/News/CreateUpdateNews`,
	deleteNews: `${baseUrl}/News/DeleteNews`,
	// Documents
	documents: `${baseUrl}/Documents/Documents`,
	createUpdateDocument: `${baseUrl}/Documents/CreateUpdateDocument`,
	deleteDocument: `${baseUrl}/Documents/DeleteDocument`,
	// Professionales
	professionalExists: `${baseUrl}/Professionals/ProfessionalExists`,
	activeProfessionals: `${baseUrl}/Professionals/ActiveProfessionalsPartial`,
	professionals: `${baseUrl}/Professionals/ProfessionalsPartial`,
	professional: `${baseUrl}/Professionals/Professional`,
	categories: `${baseUrl}/Professionals/Categories`,
	categoriesById: `${baseUrl}/Professionals/CategoriesById`,
	accreditations: `${baseUrl}/Professionals/Accreditations`,
	accreditationsById: `${baseUrl}/Professionals/AccreditationsById`,
	accreditationComments: `${baseUrl}/Professionals/AccreditationComments`,
	accreditationCommentsById: `${baseUrl}/Professionals/AccreditationCommentsById`,
	accreditationDocuments: `${baseUrl}/Professionals/AccreditationDocuments`,
	accreditationDocumentsById: `${baseUrl}/Professionals/AccreditationDocumentsById`,
	accomplishments: `${baseUrl}/Professionals/Accomplishments`,
	accomplishmentsById: `${baseUrl}/Professionals/AccomplishmentsById`,
	specialties: `${baseUrl}/Professionals/Specialties`,
	specialtiesById: `${baseUrl}/Professionals/SpecialtiesById`,
	professionalMilestones: `${baseUrl}/Professionals/ProfessionalMilestones`,
	professionalMilestonesById: `${baseUrl}/Professionals/ProfessionalMilestonesById`,
	privileges: `${baseUrl}/Professionals/Privileges`,
	privilegesById: `${baseUrl}/Professionals/PrivilegesById`,
	createUpdateProfessionalAddress: `${baseUrl}/Professionals/CreateUpdateAddress`,
	professionalDocuments: `${baseUrl}/Professionals/ProfessionalDocuments`,
	professionalDocumentsById: `${baseUrl}/Professionals/ProfessionalDocumentsById`,
	createUpdatePhone: `${baseUrl}/Professionals/CreateUpdatePhone`,
	createUpdateEmail: `${baseUrl}/Professionals/CreateUpdateEmail`,
	createUpdateRut: `${baseUrl}/Professionals/CreateUpdateRut`,
	createUpdateProfessional: `${baseUrl}/Professionals/CreateUpdateProfessional`,
	createUpdateAccreditation: `${baseUrl}/Professionals/CreateUpdateAccreditation`,
	createUpdateAccreditationComment: `${baseUrl}/Professionals/CreateUpdateAccreditationcomment`,
	createUpdateAccomplishment: `${baseUrl}/Professionals/CreateUpdateAccomplishment`,
	createUpdateSpecialty: `${baseUrl}/Professionals/CreateUpdateSpecialty`,
	createUpdatePrivilege: `${baseUrl}/Professionals/CreateUpdatePrivilege`,
	createUpdateCategory: `${baseUrl}/Professionals/CreateUpdateCategory`,
	createUpdateProfessionalMilestone: `${baseUrl}/Professionals/CreateUpdateProfessionalMilestone`,
	deleteAddress: `${baseUrl}/Professionals/DeleteAddress`,
	deletePhone: `${baseUrl}/Professionals/DeletePhone`,
	deleteEmail: `${baseUrl}/Professionals/DeleteEmail`,
	deleteAccreditation: `${baseUrl}/Professionals/DeleteAccreditation`,
	deleteAccreditationComment: `${baseUrl}/Professionals/DeleteAccreditationComment`,
	deletePrivilege: `${baseUrl}/Professionals/DeletePrivilege`,
	deleteProfessional: `${baseUrl}/Professionals/DeleteProfessional`,
	deleteAccomplishment: `${baseUrl}/Professionals/DeleteAccomplishment`,
	deleteSpecialty: `${baseUrl}/Professionals/DeleteSpecialty`,
	deleteCategory: `${baseUrl}/Professionals/DeleteCategory`,
	deleteProfessionalDocument: `${baseUrl}/Professionals/DeleteProfessionalDocument`,
	deleteProfessionalMilestone: `${baseUrl}/Professionals/DeleteProfessionalMilestone`,
	deleteAccreditationDocument: `${baseUrl}/Professionals/DeleteAccreditationDocument`,
	uploadAccreditationDocument: `${baseUrl}/Professionals/UploadAccreditationDocument`,
	uploadProfessionalDocument: `${baseUrl}/Professionals/UploadProfessionalDocument`,
	//Training
	events: `${baseUrl}/Training/Events`,
	createUpdateEvent: `${baseUrl}/Training/CreateUpdateEvent`,
	deleteEvent: `${baseUrl}/Training/DeleteEvent`,
	links: `${baseUrl}/Training/Links`,
	createUpdateLink: `${baseUrl}/Training/CreateUpdateLink`,
	deleteLink: `${baseUrl}/Training/DeleteLink`,
	getEnrolledProfessionals: `${baseUrl}/Training/GetEnrolledProfessionals`,
	//Attachments
	createUpdateAvatar: `${baseUrl}/Attachments/CreateUpdateAvatar`,
	createUpdateDocumentAttachment: `${baseUrl}/Attachments/CreateUpdateDocumentAttachment`,
	deleteDocumentAttachment: `${baseUrl}/Attachments/DeleteDocumentAttachment`,
	createUpdateImageAttachment: `${baseUrl}/Attachments/CreateUpdateImageAttachment`,
	deleteImageAttachment: `${baseUrl}/Attachments/DeleteImageAttachment`,
	//Centers
	networks: `${baseUrl}/Network/Networks`,
	centers: `${baseUrl}/Network/Centers`,
	services: `${baseUrl}/Network/Services`,
	infrastructures: `${baseUrl}/Network/Infrastructures`,
	equipments: `${baseUrl}/Network/Equipments`,
	createUpdateCenter: `${baseUrl}/Network/CreateUpdateCenter`,
	createUpdateInfrastructure: `${baseUrl}/Network/CreateUpdateInfrastructure`,
	createUpdateEquipment: `${baseUrl}/Network/CreateUpdateEquipment`,
	createUpdateService: `${baseUrl}/Network/CreateUpdateService`,
	createUpdateCenterAddress: `${baseUrl}/Network/CreateUpdateAddress`,
	deleteCenter: `${baseUrl}/Network/DeleteCenter`,
	deleteService: `${baseUrl}/Network/DeleteService`,
	//Authentication
	userExists: `${baseUrl}/Authentication/UserExists`,
	validateUser: `${baseUrl}/Authentication/ValidateUser`,
	permissions: `${baseUrl}/Authentication/Permissions`,
	profiles: `${baseUrl}/Authentication/Profiles`,
	users: `${baseUrl}/Authentication/Users`,
	createUpdateUser: `${baseUrl}/Authentication/CreateUpdateUser`,
	createUpdateProfile: `${baseUrl}/Authentication/CreateUpdateProfile`,
	deleteUser: `${baseUrl}/Authentication/DeleteUser`,
	//System
	googleWebClientId: `${baseUrl}/System/GetGoogleWebClientID`,
	translations: `${baseUrl}/System/Translations`,
	createUpdateTranslations: `${baseUrl}/System/CreateUpdateTranslation`,
	getContentLog: `${baseUrl}/System/GetContentLog`,
	//Management
	getEHRData: `${baseUrl}/Management/GetEHRData`,
	personExists: `${baseUrl}/Management/PersonExists`,
	getPersonDataFromAgenda: `${baseUrl}/Agenda/GetPersonDataFromAgenda`,
	persons: `${baseUrl}/Management/Persons`,
	activities: `${baseUrl}/Management/Activities`,
	activity: `${baseUrl}/Management/Activity`,
	activityComments: `${baseUrl}/Management/ActivityComments`,
	activityAgreements: `${baseUrl}/Management/ActivityAgreements`,
	activityObjectives: `${baseUrl}/Management/ActivityObjectives`,
	cases: `${baseUrl}/Management/Cases`,
	case: `${baseUrl}/Management/Case`,
	caseComments: `${baseUrl}/Management/CaseComments`,
	caseTasks: `${baseUrl}/Management/CaseTasks`,
	tasks: `${baseUrl}/Management/Tasks`,
	taskComments: `${baseUrl}/Management/TaskComments`,
	taskDocumentAttachments: `${baseUrl}/Management/TaskDocumentAttachments`,
	myTasks: `${baseUrl}/Management/MyTasks`,
	agreements: `${baseUrl}/Management/Agreements`,
	comments: `${baseUrl}/Management/Comments`,
	createUpdateTask: `${baseUrl}/Management/CreateUpdateTask`,
	createUpdateActivity: `${baseUrl}/Management/CreateUpdateActivity`,
	createUpdateCase: `${baseUrl}/Management/CreateUpdateCase`,
	createUpdatePerson: `${baseUrl}/Management/CreateUpdatePerson`,
	createUpdateAgreement: `${baseUrl}/Management/CreateUpdateAgreement`,
	createUpdateActivityComment: `${baseUrl}/Management/CreateUpdateActivityComment`,
	createUpdateCaseComment: `${baseUrl}/Management/CreateUpdateCaseComment`,
	createUpdateTaskComment: `${baseUrl}/Management/createUpdateTaskComment`,
	deleteActivity: `${baseUrl}/Management/DeleteActivity`,
	deleteCase: `${baseUrl}/Management/DeleteCase`,
	deleteTask: `${baseUrl}/Management/DeleteTask`,
	deletePerson: `${baseUrl}/Management/DeletePerson`,
	deleteActivityAgreement: `${baseUrl}/Management/DeleteActivityAgreement`,
	deleteActivityComment: `${baseUrl}/Management/DeleteActivityComment`,
	deleteTaskComment: `${baseUrl}/Management/DeleteTaskComment`,
	// General
	emails: `${baseUrl}/General/Emails`,
	professionalEmailsById: `${baseUrl}/General/ProfessionalEmailsById`,
	personEmailsById: `${baseUrl}/General/PersonEmailsById`,
	phones: `${baseUrl}/General/Phones`,
	professionalPhonesById: `${baseUrl}/General/ProfessionalPhonesById`,
	personPhonesById: `${baseUrl}/General/PersonPhonesById`,
	addresses: `${baseUrl}/General/Addresses`,
	professionalAddressesById: `${baseUrl}/General/ProfessionalAddressesById`,
	personAddressesById: `${baseUrl}/General/PersonAddressesById`,
	getProfessionalData: `${baseUrl}/General/GetProfessionalData`,
	getProfessionalCertificate: `${baseUrl}/General/GetProfessionalCertificate`,
	getDocument: `${baseUrl}/General/GetDocument`,
	getAndroidApp: `${baseUrl}/General/GetAndroidApp`,
	getIOsRedeemCode: `${baseUrl}/General/GetIOsRedeemCode`,
};
