import { Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { ReactNode, useEffect, useState } from 'react';
import { urls } from '../../store/api';
import dayjs from 'dayjs';

const { Text } = Typography;

const FormContentLog = (props: { parentType: number; parentId: number }) => {
	const { t } = useTranslation();
	const [logs, setLogs] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	useEffect(() => {
		const getContentLog = async () => {
			var bearer = 'Bearer ' + globalThis.token;
			const requestOptions = {
				method: 'GET',
				headers: {
					Authorization: `${bearer}`,
				},
			};
			var response = await fetch(`${urls.getContentLog}?parentType=${props.parentType}&parentId=${props.parentId}`, requestOptions);
			var result = await response.json();
			setLogs(result);
			setIsLoading(false);
		};
		getContentLog();
	}, [props.parentId, props.parentType]);

	const columns: ColumnProps<any>[] = [
		{
			title: t('general.date'),
			dataIndex: 'logDateTime',
			key: 'logDateTime',
			render: (_text: string, item: any): ReactNode => <Text>{dayjs(item.logDateTime).format('DD/MM/YYYY HH:mm')}</Text>,
		},
		{
			title: t('general.user'),
			dataIndex: 'user',
			key: 'user',
		},
	];

	return (
		<div>
			<Table<any> rowKey={record => record.id} tableLayout="auto" size="middle" dataSource={logs} columns={columns} pagination={false} loading={isLoading} />
		</div>
	);
};

export default FormContentLog;
